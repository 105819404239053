import React, { useState, useRef, useEffect } from "react";
import Header from "@components/common/Header/Header";
import AppHeader from "@components/common/AppHeader/AppHeader";
import { selectType, setExpiration } from "@store/modules/typeSlice";
import { useSelector, useDispatch } from "react-redux";
import { checkJwt, checkRefreshCookie, checkTime } from "@src/lib/util/Util";
import {
  selectUserInfo,
  setLoggedIn,
  setLoggedInApp,
  setLoggedOffApp,
} from "@store/modules/userSlice";
import { requestTokenByRefresh } from "@src/lib/util/AuthUtil";
import { Modal } from "react-bootstrap";
import { BASE_URL, comfnApiSvcCall, METHOD } from "@src/lib/common/comfn";
import { LoadingSpinner } from "@src/components/common/LoadingSpinner";
import { selectPayerNo } from "@src/store/modules/payerNoSlice";

const SELF_MVOT_02 = ({ history }) => {
  const dispatch = useDispatch();
  const userInfo = useSelector(selectUserInfo);
  const payerInfo = useSelector(selectPayerNo);
  const token = userInfo.accessToken;
  const type = useSelector(selectType);
  const vrsc = type.vrsc;
  const [show, setShow] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [refreshJson, setRefreshJson] = useState("");

  useEffect(() => {
    document.title = "삼천리 | 전출 안내";
    // 현재시간이 22시~6시 사이면 이용불가 페이지
    if (!checkTime()) {
      history.push("/ImpossiblePage");
      return;
    }
    //토큰이 ""가 아닐 때
    if (token !== "") {
      //토큰이 유효하지 않을 때
      if (!checkJwt(userInfo.accessToken)) {
        //3자 전출일 때 token refresh
        if (type.type === "out" && vrsc) {
          if (checkRefreshCookie()) {
            requestTokenByRefresh(({ status, data }) => {
              if (status === 200) {
                //console.log(data);
                dispatch(setLoggedInApp(data));
                setRefreshJson(data);
              }
            });
          } else {
            dispatch(setExpiration(true));
            dispatch(setLoggedOffApp());
            sessionStorage.clear();
            history.push("/VrscMvot/APP_CM_02");
            return;
          }
        }
        //3자 전출이 아닐 때 accessTimeCheck
        else {
          history.push("/AccessTimeCheck");
          return;
        }
      }
      //토큰이 유효할 때
      else {
        //리덕스 값 체크 (없으면 404)
        if (
          payerInfo.iplNo === "" ||
          payerInfo.payerId === "" ||
          payerInfo.iplNo === undefined ||
          payerInfo.payerId === undefined
        ) {
          history.push("/Error404");
          return;
        } else {
          fnCheckPossible();
        }
      }
    }
    //토큰이 ""일 때
    else {
      if (
        navigator.userAgent.indexOf("wv") > -1 ||
        (navigator.userAgent.indexOf("iPhone") > -1 &&
          navigator.userAgent.indexOf("Safari") === -1)
      ) {
        dispatch(setExpiration(true));
        dispatch(setLoggedOffApp());
        sessionStorage.clear();
        history.push("/VrscMvot/APP_CM_02");
        return;
      } else {
        history.push("/Error404");
        return;
      }
    }

    // window.history.pushState(null, document.title, window.location.href);
    // window.addEventListener("popstate", () => {
    //   window.history.pushState(null, document.title, window.location.href);
    // });
  }, []);

  useEffect(() => {
    if (refreshJson !== "") {
      fnCheckPossible();
    }
  }, [refreshJson]);

  const onClickOk = () => {
    vrsc
      ? history.push("/VrscMvot/MVOT_07")
      : history.push("/SelfMvot/MVOT_07");
  };

  const fnCheckPossible = () => {
    let data = {
      iplNo: payerInfo.iplNo,
      paypNo: payerInfo.payerId,
    };
    const option = {
      serviceId: "pg45",
      baseUrl: BASE_URL.COMMON,
      url: "/mvot/pg45",
      accessToken: userInfo.accessToken,
      method: METHOD.POST,
      data: data,
      callback: fnCallback,
    };
    setLoading(true);
    comfnApiSvcCall(option);
  };

  const fnCallback = (res) => {
    setLoading(false);
    if (res.data.hasOwnProperty("rtnCode")) {
      if (res.data.rtnCode === "-999") {
        return;
      }
    }
    if (res.serviceId === "pg45") {
      if (res.data.rtnCode !== "0") {
        setShow(true);
        setErrorMsg(res.data.rtnMsg);
      }
    }
  };

  return (
    <div style={{ width: "100%", height: "100%", backgroundColor: "#f5f5f5" }}>
      {vrsc ? (
        <AppHeader title="전출 안내" />
      ) : (
        <Header title="셀프 전출 안내" />
      )}
      <div id="container" className="container">
        <div className="tit_small">고객님, 셀프 전출은 이렇게 진행합니다.</div>
        <div className="section_radio">
          <div className="stepMvotImage" />
        </div>
      </div>

      <button
        type="button"
        className="btn btn_blue btn_big btn_bottom mt-3"
        disabled={loading}
        onClick={onClickOk}
      >
        {loading ? <LoadingSpinner /> : "확인"}
      </button>

      <Modal show={show} centered backdrop="static">
        <Modal.Header>
          <Modal.Title>
            <h5 className="modal-title shot">
              조회 결과 셀프 전출이 불가하며, <br /> 사유는 아래와 같습니다.
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: 0 }}>
          <div className="info_box">
            <>
              <p className="info">{errorMsg}</p>
            </>
          </div>

          <div className="cut_line" />

          <div className="bottom_box">
            <p className="txt_bottom">
              고객센터 <a href="tel:1544-3002">1544-3002</a>
            </p>
            <p className="txt_bottom">(상담가능시간 : 평일 09:00~18:00) 로</p>
            <p className="txt_bottom">연락 부탁드립니다.</p>
          </div>
        </Modal.Body>
      </Modal>

      <div className="bottom_txt mt-1 pb-5">
        <div>
          <span className="gray">고객센터</span>{" "}
          <span className="space">
            <a href="tel:1544-3002">1544-3002</a>
          </span>
        </div>
        <div style={{ paddingBottom: "11.5rem" }}>
          <span className="gray">상담가능시간</span>{" "}
          <span>평일 09:00~18:00</span>
        </div>
      </div>
    </div>
  );
};

export default SELF_MVOT_02;
