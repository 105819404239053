import { Header } from "@src/components/common/Header";
import React, { useEffect } from "react";

const AccessError = () => {
  useEffect(() => {
    document.title = "삼천리 | 이용 불가 안내";
    // window.history.pushState(null, document.title, window.location.href);
    // window.addEventListener("popstate", () => {
    //   window.history.pushState(null, document.title, window.location.href);
    // });
  }, []);
  return (
    <>
      <Header title="이용 불가 안내" />

      <div id="container" className="container">
        <div className="section_error">
          <div className="icon_area3" />
          <p className="txt">안녕하세요, 삼천리입니다.</p>

          <p className="error_txt center">본 서비스는 모바일로만</p>
          <p className="error_txt center">이용 가능합니다.</p>
          <p className="error_txt center">이점 양해 부탁 드립니다.</p>
        </div>
      </div>
    </>
  );
};

export default AccessError;
