import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  createdDt: "",
  usrId: "",
};

export const niceSlice = createSlice({
  name: "niceInfo",
  initialState,
  reducers: {
    setNice: (state, action) => {
      state.createdDt = action.payload.createdDt;
      state.usrId = action.payload.usrId;
    },
    setInitalNice: (state) => {
      state.createdDt = "";
      state.usrId = "";
    },
  },
});

export const { setNice, setInitalNice } = niceSlice.actions;

export const selectNiceInfo = (state) => ({
  createdDt: state.niceInfo.createdDt,
  usrId: state.niceInfo.usrId,
});

export default niceSlice.reducer;
