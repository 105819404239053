import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import storageSession from "redux-persist/lib/storage/session";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";

import { typeSlice } from "./modules/typeSlice";
import { payerNoSlice } from "./modules/payerNoSlice";
import { userSlice } from "./modules/userSlice";
import { meterSlice } from "./modules/meterSlice";
import { aiResultSlice } from "./modules/aiResultSlice";
import { bankCardSlice } from "./modules/bankCardSlice";
import { mvio04Slice } from "./modules/mvio04Slice";
import { mvot02Slice } from "./modules/mvot02Slice";
import { niceSlice } from "./modules/niceSlice";
import { selfMvin07 } from "./modules/selfMvin07Slice";

const reducers = combineReducers({
  type: typeSlice.reducer,
  userInfo: userSlice.reducer,
  payerNoInfo: payerNoSlice.reducer,
  meterInfo: meterSlice.reducer,
  aiResult: aiResultSlice.reducer,
  mvio04Info: mvio04Slice.reducer,
  bankCardInfo: bankCardSlice.reducer,
  mvot02Info: mvot02Slice.reducer,
  niceInfo: niceSlice.reducer,
  selfMvin07Info: selfMvin07.reducer,
});

const persistConfig = {
  key: "samchully",
  storage: storageSession,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});

// export const store = configureStore({
//     reducer: {
//         type: typeSlice.reducer,
//         userInfo: userSlice.reducer,
//         payerNoInfo: payerNoSlice.reducer,
//         meterInfo: meterSlice.reducer,
//     }
// })

// export type AppState = typeof store.dispatch;
// export type RootState = ReturnType<typeof store.getState>;
