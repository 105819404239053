import React, { useEffect, useState } from "react";
import { Header } from "@components/common/Header";
import AppHeader from "@components/common/AppHeader/AppHeader";
import { selectType } from "@store/modules/typeSlice";
import { useDispatch, useSelector } from "react-redux";
import examShot02 from "../../images/exam_shot_02.png";
import { checkTime } from "@src/lib/util/Util";
import {
  selectPayerNo,
  setAddress,
  setIsClickedBack,
  setIsReCal,
  setMvio_07IplNo,
} from "@store/modules/payerNoSlice";
import { setMeterPicture } from "@store/modules/meterSlice";
import { setImg } from "@store/modules/mvio04Slice";
import UAParser from "ua-parser-js";
import { setAiResult } from "@src/store/modules/aiResultSlice";

const MVIO_04 = ({ history }) => {
  const type = useSelector(selectType);
  const dispatch = useDispatch();
  const vrsc = type.vrsc;
  const [imgBase64, setImgBase64] = useState("");
  const [capImage, setCapImage] = useState("");
  const [meterImg, setMeterImg] = useState("");
  const payerInfo = useSelector(selectPayerNo);
  const [camer1Result, setCamer1Result] = useState("1");

  useEffect(() => {
    document.title = "삼천리 | 계량기 촬영 안내";
    // 현재시간이 22시~6시 사이면 이용불가 페이지
    if (!checkTime()) {
      history.push("/ImpossiblePage");
      return;
    }

    if (payerInfo.payerId === "" || payerInfo.payerId === undefined) {
      history.push("/Error404");
      return;
    }

    // window.history.pushState(null, document.title, window.location.href);
    // window.addEventListener("popstate", () => {
    //   window.history.pushState(null, document.title, window.location.href);
    // });

    const pop = () => {
      window.removeEventListener("popstate", pop);
      window.history.pushState(null, document.title, window.location.href);
      vrsc
        ? history.push("/VrscMvot/MVOT_01")
        : history.push("/SelfMvot/MVOT_01");
    };

    if (payerInfo.isReCal || payerInfo.isClickedBack) {
      window.addEventListener("popstate", pop);
    }

    window.addEventListener("CameraReturn", async (e) => {
      setCamer1Result(e.detail);

      const jsonResult = JSON.parse(e.detail);
      const pg26Result = jsonResult.pg26;
      const pg15Result = jsonResult.pg15;

      dispatch(
        setMeterPicture({
          meterNum: pg26Result.wareNum,
          Indicator: pg26Result.meterageNum,
          filePathMet: pg26Result.path,
        })
      );
      dispatch(
        setAddress({
          addr1: pg15Result.addr1,
          addr2: pg15Result.addr2,
        })
      );
      dispatch(setAiResult(pg26Result));
      dispatch(setMvio_07IplNo(pg15Result.iplNo));
      dispatch(setIsClickedBack(false));
      dispatch(setIsReCal(false));
      history.push("/VrscMvot/MVOT_01");
      // history.push({
      //   pathname: "/VrscMvot/MVOT_01",
      //   state: { iplNo: pg15Result.iplNo },
      // });

      // if (jsonResult["rtnCode"] === "0") {
      //   dispatch((jsonResult["filePathGas"]));
      //   history.push("/VrscMvot/MVIO_04");
      // } else {
      //   alert("오류가 발생하였습니다.");
      // }
    });

    window.addEventListener("CameraReturnIOS", async (e) => {
      alert(e.detail);
      setCamer1Result(e.detail);

      const jsonResult = JSON.parse(e.detail);
      const pg26Result = jsonResult.pg26;
      const pg15Result = jsonResult.pg15;

      dispatch(
        setMeterPicture({
          meterNum: pg26Result.wareNum,
          Indicator: pg26Result.meterageNum,
          filePathMet: pg26Result.path,
        })
      );
      dispatch(
        setAddress({
          addr1: pg15Result.addr1,
          addr2: pg15Result.addr2,
        })
      );
      dispatch(setAiResult(pg26Result));
      dispatch(setMvio_07IplNo(pg15Result.iplNo));
      dispatch(setIsClickedBack(false));
      dispatch(setIsReCal(false));
      history.push("/VrscMvot/MVOT_01");
    });

    window.addEventListener("InputReturn", async (e) => {
      history.push("/VrscMvot/MVIO_07");
    });

    window.addEventListener("InputReturnIOS", async (e) => {
      history.push("/VrscMvot/MVIO_07");
    });
  }, []);

  useEffect(() => {
    if (capImage === "") {
      return;
    }
    //console.log(meterImg);
    dispatch(setImg({ img: capImage, meterImg: meterImg }));
    type.type === "in"
      ? history.push("/SelfMvin/MVIO_06")
      : // history.push({
        //     pathname: "/SelfMvin/MVIO_06",
        //     state: { img: capImage, meterImg: meterImg },
        //   })
        history.push("/SelfMvot/MVIO_06");
    // history.push({
    //     pathname: "/SelfMvot/MVIO_06",
    //     state: { img: capImage, meterImg: meterImg },
    //   });
  }, [capImage]);

  function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setCapImage(reader.result);
    };
    reader.onerror = function (error) {
      //console.log("Error: ", error);
    };
  }

  const handleCamera = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    setMeterImg(file);
    reader.onload = () => {
      const base64 = reader.result;
      if (base64) {
        setImgBase64(base64.toString());
      }
    };
    if (e.target.files[0]) {
      reader.readAsDataURL(e.target.files[0]);
      getBase64(e.target.files[0]);
    }
  };

  //계량기
  const onClickOk = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month =
      today.getMonth() + 1 < 10
        ? "0" + (today.getMonth() + 1)
        : today.getMonth() + 1;
    const day = today.getDate() < 10 ? "0" + today.getDate() : today.getDate();

    const payerJson = {
      paypNo: payerInfo.payerId,
      userId: "CUSTOM",
      acckey: "000000",
      path: "imgphoto/AS/AS06/CUSTOM/" + year + month + "/" + day + "/",
      imageFilename:
        "AS06_MO_" + year + month + day + "_" + payerInfo.payerId + ".JPG",
    };

    if (navigator.userAgent.indexOf("wv") > -1) {
      window.set.openCamera(JSON.stringify(payerJson));
    } else if (
      navigator.userAgent.indexOf("iPhone") > -1 &&
      navigator.userAgent.indexOf("Safari") === -1
    ) {
      window.webkit.messageHandlers.openCamera.postMessage(
        JSON.stringify(payerJson)
      );
    }
  };
  return (
    <>
      {vrsc ? (
        <AppHeader title="계량기 촬영 안내" className="pb-0" />
      ) : (
        <Header title="계량기 촬영 안내" className="pb-0" />
      )}
      <div id="container" className="container shot">
        <div className="wrap_body shot">
          <div className="txt_area gray_circlebox">
            <p>
              <span>계량기 번호</span>와 <span>지침</span>이 잘 보이도록
            </p>
            <p>계량기를 촬영해 주세요!</p>
          </div>
          <div className="shot_exam">
            <img src={examShot02} alt="" />
          </div>
        </div>
      </div>

      <label className="btn btn_blue btn_bottom mt-5" for="cameraFileInput">
        확인
        <input
          id="cameraFileInput"
          type="file"
          accept="image/*"
          capture="environment"
          onChange={handleCamera}
          onClick={onClickOk}
          style={{ display: "none" }}
        />
      </label>
    </>
  );
};

export default MVIO_04;
