import React, { useEffect } from "react";
import AppHeader from "@components/common/AppHeader/AppHeader";
import { checkTime } from "@src/lib/util/Util";

const MBR_07 = ({ history }) => {
  useEffect(() => {
    document.title = "삼천리 | 회원가입";
    // 현재시간이 22시~6시 사이면 이용불가 페이지
    if (!checkTime()) {
      history.push("/ImpossiblePage");
      return;
    }
  }, []);
  return (
    <>
      <AppHeader title="회원가입" />

      <div id="container" className="container">
        <div className="section_finished">
          <div className="icon_area margintop" />
          <p className="txt nomargin">환영합니다!</p>
          <p className="txt">회원가입이 완료되었습니다.</p>

          <p className="service_txt center">
            로그인 후 셀프 전출 대행 서비스를
          </p>
          <p className="service_txt center">이용할 수 있습니다.</p>
        </div>
      </div>

      <button
        type="button"
        className="btn btn_blue btn_big btn_bottom mt-5"
        onClick={() =>
          history.push({
            pathname: "/VrscMvot/MBR_08",
            state: { page: "" },
          })
        }
      >
        로그인
      </button>
    </>
  );
};

export default MBR_07;
