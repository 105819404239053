import React, { useEffect, useState } from "react";
import { Header } from "@components/common/Header";
import { useForm } from "react-hook-form";

import { NewAgreementModal } from "@components/common/NewAgreementModal";
import {
  selectPayerNo,
  setAgree,
  setENotiTyCd,
} from "@store/modules/payerNoSlice";
import { selectUserInfo } from "@store/modules/userSlice";
import { useSelector, useDispatch } from "react-redux";
import { inputHyphenMobNum } from "../../lib/util/Util";
import { BASE_URL, comfnApiSvcCall, METHOD } from "@src/lib/common/comfn";
import { todayDate } from "@src/lib/util/Util";
import { checkTime, checkJwt } from "@src/lib/util/Util";
import { LoadingSpinner } from "@src/components/common/LoadingSpinner";
import { ToggleSwitch } from "@src/components/common/ToggleSwitch";
import { setSelfMvin07 } from "@src/store/modules/selfMvin07Slice";

const iNumber = ["010", "011", "016", "017", "018", "019"];

const SELF_MVIN_05 = ({ history }) => {
  const userInfo = useSelector(selectUserInfo);
  const token = userInfo.accessToken;
  const payerInfo = useSelector(selectPayerNo);
  const noticeMethods = [
    {
      type: "0006",
      typeName: "카카오 알림톡",
    },
    {
      type: "0005",
      typeName: "휴대폰 문자(LMS)",
    },
  ];
  const [selectBefore, setSelectBefore] = useState({
    type: "",
    typeName: "",
  });

  useEffect(() => {
    document.title = "도시가스 사용계약";
    // 현재시간이 22시~6시 사이면 이용불가 페이지
    if (!checkTime()) {
      history.push("/ImpossiblePage");
      return;
    }

    //토큰이 있을 때
    if (token !== "") {
      //토큰이 유효하지 않을 때
      if (!checkJwt(token)) {
        history.push("/AccessTimeCheck");
        return;
      }
      //토큰이 유효할 때
      else {
        //리덕스 값 체크 (없으면 404)
        if (
          payerInfo.delivDate === "" ||
          payerInfo.delivDate === undefined ||
          payerInfo.iplNo === "" ||
          payerInfo.iplNo === undefined ||
          payerInfo.rolGb === "" ||
          payerInfo.rolGb === undefined ||
          payerInfo.payerId === "" ||
          userInfo.mobNo === "" ||
          userInfo.birth === "" ||
          payerInfo.name === ""
        ) {
          history.push("/Error404");
          return;
        }
      }
    } else {
      history.push("/Error404");
      return;
    }

    getPrevInfo({ paypNo: payerInfo.payerId });
  }, []);
  const date = todayDate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setFocus,
  } = useForm();
  const selectNow = watch("eNotiTyCd");
  const [agreement, setAgreement] = useState({
    show: false,
    setShow: false,
  });
  const [modalCheckBox, setModalCheckBox] = useState([]);
  const [petCheck, setPetCheck] = useState(false);
  const [disCheck, setDisCheck] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allAgree, setAllAgree] = useState(false);
  const [isExceptAgree6, setIsExceptAgree6] = useState(false);
  const [allAgreeError, setAllAgreeError] = useState(false);
  const dispatch = useDispatch();

  //납부자 번호로 이전 전자고지, 자동이체 정보 조회
  const getPrevInfo = (data) => {
    const option = {
      serviceId: "getPrevInfo",
      baseUrl: BASE_URL.COMMON,
      header: "accessToken",
      url: "/mvin/pg19",
      method: METHOD.POST,
      data: data,
      callback: fnCallback,
    };
    setLoading(true);
    comfnApiSvcCall(option);
  };

  const sendContractData = (data) => {
    //console.log(data);
    // if (data.havePet) {
    //   //console.log("havePet true");
    //   setPetCheck(true);
    // }
    // if (data.rateReduction) setDisCheck(true);
    const option = {
      serviceId: "sendContractData",
      baseUrl: BASE_URL.COMMON,
      url: "/mvin/pg35",
      header: "accessToken",
      method: METHOD.POST,
      data: data,
      callback: fnCallback,
    };
    setLoading(true);
    comfnApiSvcCall(option);
  };

  const signUpContract = (data) => {
    const option = {
      serviceId: "signUpContract",
      baseUrl: BASE_URL.COMMON,
      url: "/mvin/pg21",
      header: "accessToken",
      method: METHOD.POST,
      data: data,
      callback: fnCallback,
    };
    setLoading(true);
    comfnApiSvcCall(option);
  };

  //callback 함수
  const fnCallback = (res) => {
    setLoading(false);
    if (res.data.hasOwnProperty("rtnCode")) {
      if (res.data.rtnCode === "-999") {
        return;
      }
    }
    if (res.errorCode < 0) {
      //console.log("ERROR");
      //console.log(res.data.rtnMsg);
      alert(res.data.rtnMsg);
    } else if (res.serviceId === "sendContractData") {
      if (selectBefore.type === "") {
        const data = {
          paypNo: payerInfo.payerId,
          iplNo: payerInfo.iplNo,
          notiTyCd:
            selectNow === "0005" || selectNow === "0006"
              ? selectNow
              : selectBefore.type,
        };
        signUpContract(data); //pg21 호출
      } else {
        //로직 작성
        dispatch(
          setAgree({
            discntYn: disCheck ? "Y" : "N",
            warningYn: petCheck ? "Y" : "N",
          })
        );
        history.push("/SelfMvin/SELF_MVIN_08");
      }
    } else if (res.serviceId === "getPrevInfo") {
      if (res.data.notiInfo !== null) {
        // 선택했던 방법이 있으면 정보 저장
        if (res.data.notiInfo.enotiTyCd === "0006") {
          setSelectBefore({
            type: "0006",
            typeName: "카카오 알림톡",
          });
        } else if (res.data.notiInfo.enotiTyCd === "0005") {
          setSelectBefore({
            type: "0005",
            typeName: "휴대폰 문자(LMS)",
          });
        } else if (res.data.notiInfo.enotiTyCd === "0007") {
          setSelectBefore({
            type: "0007",
            typeName: "카카오 Pay",
          });
        }
      }
      if (res.data.payInfo !== null) {
        dispatch(setSelfMvin07(res.data.payInfo));
      }
    } else if (res.serviceId === "signUpContract") {
      dispatch(
        setAgree({
          discntYn: disCheck ? "Y" : "N",
          warningYn: petCheck ? "Y" : "N",
        })
      );
      history.push("/SelfMvin/SELF_MVIN_08");
    }
  };

  const onSubmit = (data) => {
    if (allAgree) {
      // 해당 정보 서버로 전송
      const contractData = {
        paypNo: payerInfo.payerId,
        name: payerInfo.name,
        mobNo: userInfo.mobNo,
        iplNo: payerInfo.iplNo,
        delivDate: payerInfo.delivDate,
        rolGb: payerInfo.rolGb,
        qmNo: payerInfo.qmNo,
        prsnAgrYn: modalCheckBox.includes("agreement1") ? "Y" : "N",
        gasUseAgrYn: modalCheckBox.includes("agreement2") ? "Y" : "N",
        prsnCollectAgrYn: modalCheckBox.includes("agreement3") ? "Y" : "N",
        prsnGiveAgrYn: modalCheckBox.includes("agreement4") ? "Y" : "N",
        prsnViewAgrYn: modalCheckBox.includes("agreement5") ? "Y" : "N",
        thrdAgrYn: modalCheckBox.includes("agreement6") ? "Y" : "N",
        gender: data.gender,
        brthYm: userInfo.birth,
      };

      dispatch(
        setENotiTyCd(
          selectNow === "0005" || selectNow === "0006"
            ? selectNow
            : selectBefore.type
        )
      );

      sendContractData(contractData);
    }
  };

  const watchPhoneCenter = watch("phoneCenter");

  useEffect(() => {
    if (watchPhoneCenter && watchPhoneCenter.length === 4) setFocus("phoneEnd");
  }, [watchPhoneCenter, setFocus]);

  useEffect(() => {
    if (
      modalCheckBox.includes("agreement1") &&
      modalCheckBox.includes("agreement2") &&
      modalCheckBox.includes("agreement3") &&
      modalCheckBox.includes("agreement4") &&
      modalCheckBox.includes("agreement5")
    ) {
      setAllAgree(true);
      if (!modalCheckBox.includes("agreement6")) {
        setIsExceptAgree6(true);
      }

      if (allAgreeError) {
        setAllAgreeError(false);
      }
    } else {
      setAllAgree(false);
    }
  }, [modalCheckBox]);

  const handleTogglePet = () => {
    setPetCheck(!petCheck);
  };

  const handleToggleReduction = () => {
    setDisCheck(!disCheck);
  };

  const handleAllCheckBox = (e) => {
    const { checked } = e.target;
    setAllAgree(checked);
    if (checked) {
      setModalCheckBox([
        "agreement1",
        "agreement2",
        "agreement3",
        "agreement4",
        "agreement5",
        "agreement6",
      ]);
    } else {
      setModalCheckBox([]);
      setIsExceptAgree6(false);
    }
    if (allAgreeError) {
      if (checked) {
        setAllAgreeError(false);
      }
    }
  };

  const onClickOk = () => {
    if (!allAgree) {
      setAllAgreeError(true);
    }
  };

  return (
    <>
      <Header title="도시가스 사용계약" />

      <div id="container" className="container info">
        <form onSubmit={handleSubmit(onSubmit)}>
          <h3>고객 정보</h3>
          <table className="tbl_ver">
            <tbody>
              <tr>
                <th>납부자 번호</th>
                <td>{payerInfo.payerId}</td>
              </tr>
              <tr>
                <th>주소</th>
                <td>{payerInfo.roadAddress}</td>
              </tr>
              <tr>
                <th>고객명</th>
                <td>{payerInfo.name}</td>
              </tr>
              <tr>
                <th>생년월일</th>
                <td>{userInfo.birth}</td>
              </tr>
              <tr>
                <th>성별 (필수)</th>
                <td>
                  <div
                    className="horizon_combo_area"
                    style={{ marginTop: "-5px" }}
                  >
                    <div className="custom-control custom-radio left">
                      <input
                        type="radio"
                        id="male"
                        value="M"
                        className="custom-control-input"
                        {...register("gender", { required: true })}
                      />
                      <label className="custom-control-label" htmlFor="male">
                        <div className="info01">남자</div>
                      </label>
                    </div>
                    <div className="custom-control custom-radio right">
                      <input
                        type="radio"
                        id="female"
                        value="F"
                        className="custom-control-input"
                        {...register("gender", { required: true })}
                      />
                      <label className="custom-control-label" htmlFor="female">
                        <div className="info01">여자</div>
                      </label>
                    </div>
                  </div>
                </td>
              </tr>
              {errors.gender && (
                <small
                  className="form-text text_alert"
                  style={{ marginTop: "-25px" }}
                >
                  성별을 선택해 주세요.
                </small>
              )}
              <tr>
                <th style={{ padding: "6px 0" }}>휴대폰 번호</th>
                <td style={{ padding: "6px 0" }}>
                  {inputHyphenMobNum(userInfo.mobNo)}
                </td>
              </tr>
            </tbody>
          </table>

          {selectBefore.type === "" ? (
            <div style={{ marginTop: "25px", marginBottom: "10px" }}>
              <label
                style={{
                  color: "#3c3c3c",
                  fontWeight: "normal",
                  fontSize: "15px",
                }}
              >
                전자고지 방법 (필수)
              </label>
              <select
                className="form-control"
                id="bank"
                defaultValue="default"
                {...register("eNotiTyCd", {
                  required: true,
                  validate: (value) => value !== "default",
                })}
                style={{ fontSize: "15px" }}
              >
                <option value="default" disabled hidden>
                  선택해주세요
                </option>
                {noticeMethods.map((method) => (
                  <option value={method.type} key={method.type}>
                    {method.typeName}
                  </option>
                ))}
              </select>
            </div>
          ) : (
            <table className="tbl_ver" style={{ marginTop: "14px" }}>
              <tbody>
                <tr>
                  <th>전자고지</th>
                  <td>{selectBefore.typeName}</td>
                </tr>
              </tbody>
            </table>
          )}

          {errors.eNotiTyCd && (
            <small className="form-text text_alert">
              전자고지 방법을 선택해 주세요.
            </small>
          )}
          <div style={{ paddingBottom: "3px" }}></div>

          <hr className="mt-2" />

          <div>
            <div className="info01" style={{ marginTop: "1.5rem" }}>
              <p style={{ fontSize: "0.93rem", width: "90%" }}>
                사회적 배려 대상자이신가요?<br></br>(요금 경감 대상자)
              </p>
              <ToggleSwitch
                item="disCheck"
                isChecked={disCheck}
                handleToggle={handleToggleReduction}
              />
            </div>

            <div
              className="info01"
              style={{
                marginTop: "1.5rem",
                marginBottom: "1.5rem",
              }}
            >
              <p style={{ fontSize: "0.93rem", width: "90%" }}>
                애완동물(강아지 등)이 있으신가요?
              </p>
              <ToggleSwitch
                item="petCheck"
                isChecked={petCheck}
                handleToggle={handleTogglePet}
              />
            </div>
          </div>
          <div className="section_radio outline_checkbox">
            <div className="custom-control custom-radio">
              <input
                type="checkbox"
                id="allAgree"
                className="custom-control-input"
                onChange={handleAllCheckBox}
                checked={allAgree}
              />
              <label
                className="custom-control-label"
                htmlFor="allAgree"
                style={{ width: "80%" }}
              >
                서비스 이용 약관에 동의합니다.
              </label>
            </div>
          </div>

          <div
            className="linked_txt"
            onClick={() =>
              setAgreement({
                show: true,
              })
            }
            style={{
              color: "#9c9c9c",
              fontSize: "12px",
            }}
          >
            개인정보 수집 및 이용 동의(필수), 도시가스 사용약관 동의(필수),
            개인(신용)정보 수집/이용 동의(필수), 개인(신용)정보 제공 동의(필수),
            개인(신용)정보 조회 동의(필수), 요금정보 등의 제3자 제공에 관한 동의
            (선택)
            <span style={{ textDecoration: "underline", marginLeft: "7px" }}>
              자세히보기
            </span>
          </div>
          {allAgreeError && (
            <small
              className="form-text text_alert"
              style={{ marginBottom: "8px" }}
            >
              약관에 동의해 주세요.
            </small>
          )}

          <div className="bottom_txt">
            <span className="gray">신청일자</span>{" "}
            <span className="space">{date}</span>
            <span className="gray">신청인</span> <span>{payerInfo.name}</span>
          </div>

          <button
            type="submit"
            style={{ marginTop: 20 }}
            disabled={loading}
            className="btn btn_blue btn_big mt-4"
            onClick={onClickOk}
          >
            {loading ? <LoadingSpinner /> : "확인"}
          </button>
        </form>
      </div>

      <NewAgreementModal
        show={agreement.show}
        onHide={() =>
          setAgreement({
            show: false,
          })
        }
        setModalCheckBox={setModalCheckBox}
        allAgree={allAgree}
        isExceptAgree6={isExceptAgree6}
      />
    </>
  );
};

export default SELF_MVIN_05;
