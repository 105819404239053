import firebase from "firebase/app";
import "firebase/analytics";

const firebaseConfig = {
  // firebase 설정과 관련된 개인 정보
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

export default firebase.initializeApp(firebaseConfig);

const analyticsFunction = () => {
  try {
    firebase.analytics().setCurrentScreen(window.location.pathname);
    firebase
      .analytics()
      .logEvent("screen_view", { path: window.location.pathname });
  } catch (e) {
    //console.log("firebase 실패");
  }
};

const analyticsEvent = (name, props) => {
  try {
    firebase.analytics().logEvent(name, props);
  } catch (e) {
    //console.log("Unable to tag analytics event:", e);
  }
};

const setAnalyticsUserProperties = (props) => {
  firebase.analytics().setUserProperties(props);
};

const setAnalyticsEnabled = (flag) => {
  firebase.analytics().setAnalyticsCollectionEnabled(flag);
};

export {
  analyticsFunction,
  analyticsEvent,
  setAnalyticsUserProperties,
  setAnalyticsEnabled,
};
