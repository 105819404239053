import React, { useEffect } from "react";
import AppHeader from "@components/common/AppHeader/AppHeader";
import { checkTime } from "@src/lib/util/Util";
import NiceButton from "@components/common/NiceButton/NiceButton";

const MBR_22 = ({ history }) => {
  useEffect(() => {
    document.title = "삼천리 | 아이디 조회 결과-계정 미존재";
    // 현재시간이 22시~6시 사이면 이용불가 페이지
    if (!checkTime()) {
      history.push("/ImpossiblePage");
      return;
    }
  }, []);
  return (
    <>
      <AppHeader title="아이디 찾기" />

      <div id="container" className="container">
        <p className="findid_txt">
          해당하는 계정이 존재하지 않습니다. <br />
          <br />
          다시 확인 후 이용해 주세요.
        </p>
      </div>

      <div className="row btn-area find btn_bottom mt-4">
        <div className="col-6 btn-lt">
          <button
            type="button"
            className="btn btn_white"
            onClick={() => history.push("/VrscMvot/MBR_01")}
          >
            회원가입
          </button>
        </div>
        <div className="col-6 btn-rt">
          <NiceButton
            type="button"
            cName="btn btn_blue"
            name="아이디 찾기"
            agree={false}
          />
        </div>
      </div>
    </>
  );
};

export default MBR_22;
