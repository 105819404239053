import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  returnCd: "",
  returnMsg: "",
  wareNum: "",
  wareNumX0: "",
  wareNumY0: "",
  wareNumX1: "",
  wareNumY1: "",
  wareNumX2: "",
  wareNumY2: "",
  wareNumX3: "",
  wareNumY3: "",
  meterageNum: "",
  meterageNumX0: "",
  meterageNumY0: "",
  meterageNumX1: "",
  meterageNumY1: "",
  meterageNumX2: "",
  meterageNumY2: "",
  meterageNumX3: "",
  meterageNumY3: "",
  meterageScore: "",
  path: "",
};

export const aiResultSlice = createSlice({
  name: "aiResult",
  initialState,
  reducers: {
    setAiResult: (state, action) => {
      state.returnCd = action.payload.returnCd;
      state.returnMsg = action.payload.returnMsg;
      state.wareNum = action.payload.wareNum;
      state.wareNumX0 = action.payload.wareNumX0;
      state.wareNumY0 = action.payload.wareNumY0;
      state.wareNumX1 = action.payload.wareNumX1;
      state.wareNumY1 = action.payload.wareNumY1;
      state.wareNumX2 = action.payload.wareNumX2;
      state.wareNumY2 = action.payload.wareNumY2;
      state.wareNumX3 = action.payload.wareNumX3;
      state.wareNumY3 = action.payload.wareNumY3;
      state.meterageNum = action.payload.meterageNum;
      state.meterageNumX0 = action.payload.meterageNumX0;
      state.meterageNumY0 = action.payload.meterageNumY0;
      state.meterageNumX1 = action.payload.meterageNumX1;
      state.meterageNumY1 = action.payload.meterageNumY1;
      state.meterageNumX2 = action.payload.meterageNumX2;
      state.meterageNumY2 = action.payload.meterageNumY2;
      state.meterageNumX3 = action.payload.meterageNumX3;
      state.meterageNumY3 = action.payload.meterageNumY3;
      state.meterageScore = action.payload.meterageScore;
      state.path = action.payload.path;
    },

    setAiResultSession: (state, action) => {
      state.wareNum = action.payload.wareNum;
      state.meterageNum = action.payload.meterageNum;
    },
  },
});

export const { setAiResult, setAiResultSession } = aiResultSlice.actions;

export const selectAiResult = (state) => ({
  returnCd: state.aiResult.returnCd,
  returnMsg: state.aiResult.returnMsg,
  wareNum: state.aiResult.wareNum,
  wareNumX0: state.aiResult.wareNumX0,
  wareNumY0: state.aiResult.wareNumY0,
  wareNumX1: state.aiResult.wareNumX1,
  wareNumY1: state.aiResult.wareNumY1,
  wareNumX2: state.aiResult.wareNumX2,
  wareNumY2: state.aiResult.wareNumY2,
  wareNumX3: state.aiResult.wareNumX3,
  wareNumY3: state.aiResult.wareNumY3,
  meterageNum: state.aiResult.meterageNum,
  meterageNumX0: state.aiResult.meterageNumX0,
  meterageNumY0: state.aiResult.meterageNumY0,
  meterageNumX1: state.aiResult.meterageNumX1,
  meterageNumY1: state.aiResult.meterageNumY1,
  meterageNumX2: state.aiResult.meterageNumX2,
  meterageNumY2: state.aiResult.meterageNumY2,
  meterageNumX3: state.aiResult.meterageNumX3,
  meterageNumY3: state.aiResult.meterageNumY3,
  meterageScore: state.aiResult.meterageScore,
  path: state.aiResult.path,
});

export default aiResultSlice.reducer;
