import React, { useEffect } from "react";
import Header from "@components/common/Header/Header";
import { todayDate, checkTime, checkJwt } from "@src/lib/util/Util";
import { selectPayerNo } from "@store/modules/payerNoSlice";
import { selectType } from "@src/store/modules/typeSlice";
import { selectUserInfo, setInitalUser } from "@src/store/modules/userSlice";
import { useSelector } from "react-redux";

const SELF_MVIN_09 = ({ history, location }) => {
  const type = useSelector(selectType);
  const payerInfo = useSelector(selectPayerNo);
  const userInfo = useSelector(selectUserInfo);

  useEffect(() => {
    document.title = "삼천리 | 전입 완료";
    // 현재시간이 22시~6시 사이면 이용불가 페이지
    if (!checkTime()) {
      history.push("/ImpossiblePage");
      return;
    }
    // 토큰이 만료되었으면 404에러 페이지
    if (!checkJwt(userInfo.accessToken)) {
      history.push("/Error404");
      return;
    }
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", () => {
      window.history.pushState(null, document.title, window.location.href);
    });
    sessionStorage.removeItem("persist:samchully");
  }, []);
  const date = todayDate();

  return (
    <>
      <Header title="전입 완료" />

      <div id="container" className="container">
        <div className="section_finished">
          <div className="icon_area" />
          <p className="txt">고객님의 전입이 완료되었습니다.</p>

          <div className="section_table">
            <div className="tit_sub">전입 정보</div>
            <table className="tbl_ver type_right">
              <tbody>
                <tr>
                  <th>고객명</th>
                  <td>{payerInfo.name}</td>
                </tr>
                <tr>
                  <th>전입지 주소</th>
                  <td>{payerInfo.address}</td>
                </tr>

                <tr>
                  <th>전입 일자</th>
                  <td>{date}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {type.kakao === "inapp" ? (
          <button
            type="submit"
            className="btn btn_blue btn_big btn_bottom"
            onClick={() => {
              sessionStorage.clear();
              if (navigator.userAgent.includes("iPhone")) {
                // 카카오 인앱 아이폰의 경우 닫기
                // window.location.href = "kakaoweb://closeBrowser";
                window.location.href = process.env.REACT_APP_KAKAO_SELFMOVE;
              } else {
                // 카카오 인앱에서 안드로이드로 접근했던 경우
                // window.location.href = "http://pf.kakao.com/_dDxmzu/chat";
                window.location.href = process.env.REACT_APP_KAKAO_SELFMOVE;
              }
            }}
          >
            확인
          </button>
        ) : // 그 외에 버튼 없음
        null}
      </div>
    </>
  );
};

export default SELF_MVIN_09;
