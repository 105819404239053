import React from "react";
import { useHistory } from "react-router-dom";

const Header = ({ title, button, className }) => {
  const history = useHistory();

  return (
    <div id="header">
      <div className={`wrap_header${className ? ` ${className}` : ""}`}>
        {title !== "본인 인증" &&
        title !== "전출 완료" &&
        title !== "전입 완료" &&
        title !== "결제 오류 안내" &&
        title !== "이용 불가 안내" &&
        title !== "접속 시간 초과" &&
        title !== "전출 완료 실패 안내" ? (
          <div onClick={() => history.goBack()} className="previous" />
        ) : // <div onClick={() => history.goBack()} />
        null}
        {title !== "본인 인증" ? (
          <h1>{title}</h1>
        ) : (
          <h1 style={{ marginLeft: "20px" }}>{title}</h1>
        )}
        {button}
      </div>
    </div>
  );
};

export default Header;
