import React, { useEffect, useState } from "react";
import { Header } from "@components/common/Header";
import { useSelector, useDispatch } from "react-redux";
import {
  selectUserInfo,
  setLoggedIn,
  setAccessToken,
} from "@store/modules/userSlice";
import { selectPayerNo, setPayerNo } from "@store/modules/payerNoSlice";
import { checkTime, checkJwt } from "@src/lib/util/Util";
import { requestWebToken } from "../../lib/util/AuthUtil";

const SELF_MVIN_03 = ({ history }) => {
  const userInfo = useSelector(selectUserInfo);
  const payerInfoAll = useSelector(selectPayerNo);
  const payerInfo = payerInfoAll.self_mvin_03_list;
  useEffect(() => {
    document.title = "삼천리 | 기존 이력 확인";
    // 현재시간이 22시~6시 사이면 이용불가 페이지
    if (!checkTime()) {
      history.push("/ImpossiblePage");
      return;
    }
    // if (location.state === undefined) {
    if (payerInfo.length === 0) {
      history.push("/ErrorRedirect");
      return;
    }
    // window.history.pushState(null, document.title, window.location.href);
    // window.addEventListener("popstate", () => {
    //   window.history.pushState(null, document.title, window.location.href);
    // });
  }, []);

  // const [payerInfo, setPayerInfo] = useState(
  //   location.state === undefined ? null : location.state.payerInfo
  // );

  // console.log(payerInfo);
  const dispatch = useDispatch();
  const [latestPayerInfo, setLatestPayerInfo] = useState({
    paypNo: "",
    name: "",
    addr1: "",
    custno: "",
    iplNo: "",
    addr2: "",
    brthYm: "",
    mobNo: "",
  });

  //토큰 생성 요청, 서버 연동 후 수정 필요
  const fnGetToken = () => {
    let data = {
      req_ty: "MI",
      payp_no: latestPayerInfo.paypNo,
      mob_no: latestPayerInfo.mobNo,
      callback: callbackToken,
    };
    requestWebToken(data);
  };

  const callbackToken = ({ status, data, message }) => {
    if (status === 200) {
      dispatch(setAccessToken(data.access_token));
      history.push("/SelfMvin/SELF_MVIN_02");
    } else {
      alert("실패하였습니다.");
    }
  };

  useEffect(() => {
    if (payerInfo.length !== 0) {
      setLatestPayerInfo({
        paypNo: payerInfo[0].paypNo,
        name: payerInfo[0].name,
        addr1: payerInfo[0].addr1,
        custno: payerInfo[0].custno,
        iplNo: payerInfo[0].iplNo,
        addr2: payerInfo[0].addr2,
        brthYm: payerInfo[0].brthYm,
        mobNo: payerInfo[0].mobNo,
      });
    }
  }, []);
  return (
    <>
      <Header title="기존 이력 확인" className="app_main_toparea" />

      <div id="container" className="container">
        <div className="check_txt_box">
          <p className="history_check_txt">
            삼천리를 이용하신 이력이 있습니다.
          </p>
          <p className="history_check_txt">본인 이력이 맞는지 확인해 주세요.</p>
        </div>
        <div className="custom_info_box2">
          <p className="custom_info_title">고객님 정보</p>
          <table className="tbl_ver">
            <tbody>
              <tr>
                <th>납부자번호</th>
                <td>{latestPayerInfo.paypNo}</td>
              </tr>
              <tr>
                <th>이름</th>
                <td>{latestPayerInfo.name}</td>
              </tr>
              <tr>
                <th>생년월일</th>
                <td>{latestPayerInfo.brthYm}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="row btn-area history_check btn_bottom">
        <div className="col-6 btn-lt">
          <button
            type="button"
            className="btn btn_white"
            onClick={() => {
              alert("본인 인증을 다시해 주세요.");
              history.push("/SelfMvin/MVIO_01");
            }}
            style={{ marginTop: 20 }}
          >
            본인이 아닙니다
          </button>
        </div>
        <div className="col-6 btn-rt">
          <button
            type="button"
            className="btn btn_blue"
            style={{ marginTop: 20 }}
            onClick={() => {
              dispatch(
                setPayerNo({
                  payerId: latestPayerInfo.paypNo,
                  name: latestPayerInfo.name,
                  custNo: latestPayerInfo.custno,
                  iplNo: latestPayerInfo.iplNo,
                  address: latestPayerInfo.addr1,
                  roadAddress: latestPayerInfo.addr2,
                })
              );
              dispatch(
                setLoggedIn({
                  usrNm: latestPayerInfo.name,
                  birth: latestPayerInfo.brthYm,
                  mobNo: latestPayerInfo.mobNo,
                })
              );
              fnGetToken();
            }}
          >
            본인이 맞습니다
          </button>
        </div>
      </div>
    </>
  );
};

export default SELF_MVIN_03;
