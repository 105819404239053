import React, { useCallback, useEffect, useState } from "react";
import { BASE_URL, comfnApiSvcCall, METHOD } from "@src/lib/common/comfn";
import { useDispatch, useSelector } from "react-redux";
import { selectPayerNo, setPayerNo } from "@src/store/modules/payerNoSlice";
import {
  selectMeterInfo,
  setOutMeterSession,
} from "@src/store/modules/meterSlice";
import {
  selectUserInfo,
  setLoggedInApp,
  setLoggedOffApp,
} from "@src/store/modules/userSlice";
import { selectType, setExpiration } from "@src/store/modules/typeSlice";
import { useHistory, useLocation } from "react-router-dom";
import { LoadingSpinner } from "@src/components/common/LoadingSpinner";
import {
  selectAiResult,
  setAiResultSession,
} from "@src/store/modules/aiResultSlice";
import {
  checkJwt,
  checkRefreshCookie,
  checkTime,
  parseJwt,
} from "@src/lib/util/Util";
import { requestTokenByRefresh } from "@src/lib/util/AuthUtil";
import { setMVOT_02 } from "@src/store/modules/mvot02Slice";

const MVOT_10 = () => {
  const history = useHistory();
  const payerInfo = useSelector(selectPayerNo);
  const meterInfo = useSelector(selectMeterInfo);
  const userInfo = useSelector(selectUserInfo);
  const type = useSelector(selectType);
  const aiResInfo = useSelector(selectAiResult);
  const [loading, setLoading] = useState(false);
  const [pg36Request, setPg36Request] = useState({
    msgAuthVal: "",
    pgAuthNo: "",
    authNo: "",
    authId: "",
  });
  const [pg10Data, setpg10Data] = useState("");
  const dispatch = useDispatch();
  const token = userInfo.accessToken;
  const vrsc = type.vrsc;
  const mvotJson = JSON.parse(sessionStorage.getItem("mvotFinish"));

  const fnPgResultCheck = () => {
    let ordNo =
      payerInfo.ordNo === "" ||
      payerInfo.ordNo === undefined ||
      payerInfo.delivDate === "" ||
      payerInfo.delivDate === undefined ||
      payerInfo.rolGb === "" ||
      payerInfo.rolGb === undefined ||
      payerInfo.qmNo === "" ||
      payerInfo.qmNo === undefined ||
      payerInfo.payTyCd === "" ||
      payerInfo.payTyCd === undefined ||
      meterInfo.totalCost === "" ||
      meterInfo.totalCost === undefined
        ? mvotJson.ordNo
        : payerInfo.ordNo;

    const option = {
      serviceId: "pg13",
      baseUrl: BASE_URL.COMMON,
      url: "/pay/pg13?ordNo=" + ordNo,
      // header: session_accessToken,
      header: "accessToken",
      method: METHOD.GET,
      callback: fnCallback,
    };
    setLoading(true);
    comfnApiSvcCall(option);
  };

  const fnOrderFinish = (result) => {
    let data =
      payerInfo.ordNo === "" ||
      payerInfo.ordNo === undefined ||
      payerInfo.delivDate === "" ||
      payerInfo.delivDate === undefined ||
      payerInfo.rolGb === "" ||
      payerInfo.rolGb === undefined ||
      payerInfo.qmNo === "" ||
      payerInfo.qmNo === undefined ||
      payerInfo.payTyCd === "" ||
      payerInfo.payTyCd === undefined ||
      meterInfo.totalCost === "" ||
      meterInfo.totalCost === undefined
        ? {
            delivDate: mvotJson.delivDate,
            rolGb: mvotJson.rolGb,
            qmNo: mvotJson.qmNo,
            paypNo: mvotJson.payerId,
            // signStr: mvotJson.signStr,
            dmdAmt: mvotJson.totalCost, //정산금액
            paidAmt: result.apprvAmt, //수납금액
            payTyCd: mvotJson.payTyCd,
            payNo: result.authNo, //결제 승인 번호
            cardNo: result.cardNo, //카드
            name: mvotJson.name,
            lastMsrNo: mvotJson.outIndicator,
            mobNo: mvotJson.mobNo,
            reqTy: type.vrsc ? 2 : 1,
            readMsrNo: mvotJson.meterageNum, // 이미지 인식 지침
            numEditYn: mvotJson.edditYn, // 이미지 인식 지침 수정여부
            filePathName: mvotJson.filePathMet, // 이미지인식 사진경로
            readMtrNo: mvotJson.wareNum, // 이미지인식 기물번호
          }
        : {
            delivDate: payerInfo.delivDate,
            rolGb: payerInfo.rolGb,
            qmNo: payerInfo.qmNo,
            paypNo: payerInfo.payerId,
            // signStr: payerInfo.custSign,
            dmdAmt: meterInfo.totalCost, //정산금액
            paidAmt: result.apprvAmt, //수납금액
            payTyCd: payerInfo.payTyCd,
            payNo: result.authNo, //결제 승인 번호
            cardNo: result.cardNo, //카드
            name: payerInfo.name,
            lastMsrNo: meterInfo.outIndicator,
            mobNo: userInfo.mobNo,
            reqTy: type.vrsc ? 2 : 1,
            readMsrNo: aiResInfo.meterageNum, // 이미지 인식 지침
            numEditYn: meterInfo.edditYn, // 이미지 인식 지침 수정여부
            filePathName: meterInfo.filePathMet, // 이미지인식 사진경로
            readMtrNo: aiResInfo.wareNum, // 이미지인식 기물번호
          };

    if (type.vrsc) {
      data.thrdNm = parseJwt(userInfo.accessToken).usrNm;
      data.thrdMobNo = parseJwt(userInfo.accessToken).mobNo;
      data.thrdTyVal = parseJwt(userInfo.accessToken).tvVal;
      data.thrdTyCd = parseJwt(userInfo.accessToken).tvCd;
    }

    const option = {
      serviceId: "pg10",
      baseUrl: BASE_URL.COMMON,
      url: "/mvot/pg10",
      header: "accessToken",
      method: METHOD.POST,
      data: data,
      callback: fnCallback,
    };
    setLoading(true);
    comfnApiSvcCall(option);
  };

  const fnCancelPg = () => {
    let data =
      payerInfo.ordNo === "" ||
      payerInfo.ordNo === undefined ||
      payerInfo.delivDate === "" ||
      payerInfo.delivDate === undefined ||
      payerInfo.rolGb === "" ||
      payerInfo.rolGb === undefined ||
      payerInfo.qmNo === "" ||
      payerInfo.qmNo === undefined ||
      payerInfo.payTyCd === "" ||
      payerInfo.payTyCd === undefined ||
      meterInfo.totalCost === "" ||
      meterInfo.totalCost === undefined
        ? {
            payTyCd: mvotJson.payTyCd,
            amt: mvotJson.totalCost,
            ordNo: mvotJson.ordNo,
            paypNo: mvotJson.payerId,
            delivDate: mvotJson.delivDate,
            rolGb: mvotJson.rolGb,
            qmNo: mvotJson.qmNo,
            msgAuthVal: pg36Request.msgAuthVal,
            pgAuthNo: pg36Request.pgAuthNo,
            authorizationId: pg36Request.authId,
          }
        : {
            payTyCd: payerInfo.payTyCd,
            amt: meterInfo.totalCost,
            ordNo: payerInfo.ordNo,
            paypNo: payerInfo.payerId,
            delivDate: payerInfo.delivDate,
            rolGb: payerInfo.rolGb,
            qmNo: payerInfo.qmNo,
            msgAuthVal: pg36Request.msgAuthVal,
            pgAuthNo: pg36Request.pgAuthNo,
            authorizationId: pg36Request.authId,
          };

    const option = {
      serviceId: "pg36",
      baseUrl: BASE_URL.COMMON,
      url: "/pay/pg36",
      // header: session_accessToken,
      header: "accessToken",
      method: METHOD.POST,
      data: data,
      callback: fnCallback,
    };
    setLoading(true);
    comfnApiSvcCall(option);
  };

  const fnCallback = (res) => {
    setLoading(false);
    if (res.data.hasOwnProperty("rtnCode")) {
      if (res.data.rtnCode === "-999") {
        return;
      }
    }
    if (res.serviceId === "pg13") {
      //console.log(res.data);
      if (res.data.list.length === 1) {
        if (res.data.list[0].apprvRslltCd === "0000") {
          //if (res.data.list[0].rslltCd === "0000") {
          //거래 완료
          const pgAuthNo = res.data.list[0].pgAuthNo;
          const msgAuthVal = res.data.list[0].msgAuthVal;
          const authId = res.data.list[0].authId;
          const authNo = res.data.list[0].authNo;
          setPg36Request({
            pgAuthNo: pgAuthNo,
            msgAuthVal: msgAuthVal,
            authId: authId,
            authNo: authNo,
          });
          setpg10Data(res.data.list[0]);
          // fnOrderFinish(res.data.list[0]);
        } else {
          dispatch(
            setPayerNo({
              ...payerInfo,
              name: mvotJson.name,
              address: mvotJson.address,
              payerId: mvotJson.payerId,
              custSign: mvotJson.signStr,
              delivDate: mvotJson.delivDate,
              rolGb: mvotJson.rolGb,
              qmNo: mvotJson.qmNo,
            })
          );

          dispatch(
            setOutMeterSession({
              outIndicator: mvotJson.outIndicator,
              totalCost: mvotJson.totalCost,
              edditYn: mvotJson.edditYn,
              filePathMet: mvotJson.filePathMet,
              lastIndicator: mvotJson.lastIndicator,
            })
          );

          dispatch(
            setAiResultSession({
              wareNum: mvotJson.wareNum,
              meterageNum: mvotJson.meterageNum,
            })
          );

          dispatch(setMVOT_02(mvotJson.moveOutPrice));
          //거래 실패
          type.vrsc
            ? history.replace("/VrscMvot/MVOT_12")
            : history.replace("/SelfMvot/MVOT_12");
        }
      } else {
        dispatch(
          setPayerNo({
            ...payerInfo,
            name: mvotJson.name,
            address: mvotJson.address,
            payerId: mvotJson.payerId,
            custSign: mvotJson.signStr,
            delivDate: mvotJson.delivDate,
            rolGb: mvotJson.rolGb,
            qmNo: mvotJson.qmNo,
          })
        );

        dispatch(
          setOutMeterSession({
            outIndicator: mvotJson.outIndicator,
            totalCost: mvotJson.totalCost,
            edditYn: mvotJson.edditYn,
            filePathMet: mvotJson.filePathMet,
            lastIndicator: mvotJson.lastIndicator,
          })
        );

        dispatch(
          setAiResultSession({
            wareNum: mvotJson.wareNum,
            meterageNum: mvotJson.meterageNum,
          })
        );

        dispatch(setMVOT_02(mvotJson.moveOutPrice));
        //거래 실패
        type.vrsc
          ? history.replace("/VrscMvot/MVOT_12")
          : history.replace("/SelfMvot/MVOT_12");
      }
    } else if (res.serviceId === "pg10") {
      //console.log(res.data);
      if (res.data.rtnCode === "0") {
        if (
          payerInfo.ordNo === "" ||
          payerInfo.ordNo === undefined ||
          payerInfo.delivDate === "" ||
          payerInfo.delivDate === undefined ||
          payerInfo.rolGb === "" ||
          payerInfo.rolGb === undefined ||
          payerInfo.qmNo === "" ||
          payerInfo.qmNo === undefined ||
          payerInfo.payTyCd === "" ||
          payerInfo.payTyCd === undefined ||
          meterInfo.totalCost === "" ||
          meterInfo.totalCost === undefined
        ) {
          //리덕스에서 초기화된 값들 dispatch
          dispatch(
            setPayerNo({
              ...payerInfo,
              name: mvotJson.name,
              address: mvotJson.address,
              payerId: mvotJson.payerId,
            })
          );

          dispatch(
            setOutMeterSession({
              outIndicator: mvotJson.outIndicator,
              totalCost: mvotJson.totalCost,
              edditYn: mvotJson.edditYn,
              filePathMet: mvotJson.filePathMet,
            })
          );
        }
        type.vrsc
          ? history.replace("/VrscMvot/MVOT_04")
          : history.replace("/SelfMvot/MVOT_04");
      } else {
        fnCancelPg();
      }
    } else if (res.serviceId === "pg36") {
      //console.log(res.data);
      type.vrsc
        ? history.replace("/VrscMvot/MVOT_11")
        : history.replace("/SelfMvot/MVOT_11");
    }
  };

  useEffect(() => {
    document.title = "삼천리 | 결제";
    if (!checkTime()) {
      history.push("/ImpossiblePage");
      return;
    }

    //토큰이 ""가 아닐 때
    if (token !== "") {
      //토큰이 유효하지 않을 때
      if (!checkJwt(userInfo.accessToken)) {
        //3자 전출일 때 token refresh
        if (type.type === "out" && vrsc) {
          if (checkRefreshCookie()) {
            requestTokenByRefresh(({ status, data }) => {
              if (status === 200) {
                //console.log(data);
                dispatch(setLoggedInApp(data));
              }
            });
          } else {
            dispatch(setExpiration(true));
            dispatch(setLoggedOffApp());
            sessionStorage.clear();
            history.push("/VrscMvot/APP_CM_02");
            return;
          }
        }
        //3자 전출이 아닐 때 accessTimeCheck
        else {
          history.push("/AccessTimeCheck");
          return;
        }
      }
      //토큰이 유효할 때
      else {
        //리덕스 값 체크 (없으면 404)
        if (
          payerInfo.delivDate === "" ||
          payerInfo.rolGb === "" ||
          payerInfo.qmNo === "" ||
          payerInfo.payerId === "" ||
          // payerInfo.custSign === "" ||
          payerInfo.payTyCd === "" ||
          payerInfo.name === "" ||
          payerInfo.ordNo === "" ||
          meterInfo.totalCost === "" ||
          userInfo.mobNo === ""
        ) {
          if (mvotJson === null) {
            history.push("/Error404");
            return;
          } else if (
            mvotJson.delivDate === undefined ||
            mvotJson.rolGb === undefined ||
            mvotJson.qmNo === undefined ||
            mvotJson.payerId === undefined ||
            // mvotJson.signStr === undefined ||
            mvotJson.payTyCd === undefined ||
            mvotJson.name === undefined ||
            mvotJson.ordNo === undefined ||
            mvotJson.totalCost === undefined ||
            mvotJson.mobNo === undefined ||
            mvotJson.address === undefined ||
            mvotJson.moveOutPrice === undefined
          ) {
            history.push("/Error404");
            return;
          }
        }
      }
    }
    //토큰이 ""일 때
    else {
      if (
        navigator.userAgent.indexOf("wv") > -1 ||
        (navigator.userAgent.indexOf("iPhone") > -1 &&
          navigator.userAgent.indexOf("Safari") === -1)
      ) {
        dispatch(setExpiration(true));
        dispatch(setLoggedOffApp());
        sessionStorage.clear();
        history.push("/VrscMvot/APP_CM_02");
        return;
      } else {
        history.push("/Error404");
        return;
      }
    }

    fnPgResultCheck();
    //토큰이 만료되었으면 404에러 페이지
    if (!checkJwt(userInfo.accessToken)) {
      history.push("/Error404");
      return;
    }
    // window.history.pushState(null, document.title, window.location.href);
    // window.addEventListener("popstate", () => {
    //   window.history.pushState(null, document.title, window.location.href);
    // });
  }, []);

  useEffect(() => {
    if (
      pg36Request.msgAuthVal !== "" &&
      pg36Request.pgAuthNo !== "" &&
      pg36Request.authId !== "" &&
      pg36Request.authNo !== "" &&
      pg10Data !== ""
    ) {
      fnOrderFinish(pg10Data);
    }
  }, [pg36Request, pg10Data]);

  return <></>;
};

export default MVOT_10;
