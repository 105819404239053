import React, { useEffect } from "react";
import Header from "@components/common/Header/Header";
import AppHeader from "@components/common/AppHeader/AppHeader";
import { selectType, setExpiration } from "@store/modules/typeSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  todayDate,
  checkTime,
  checkJwt,
  checkRefreshCookie,
} from "@src/lib/util/Util";
import { selectPayerNo } from "@store/modules/payerNoSlice";
import { selectMeterInfo } from "@store/modules/meterSlice";
import {
  selectUserInfo,
  setInitalUser,
  setLoggedInApp,
  setLoggedOffApp,
} from "@store/modules/userSlice";
import { requestTokenByRefresh } from "@src/lib/util/AuthUtil";

const MVOT_04 = ({ history, location }) => {
  const userInfo = useSelector(selectUserInfo);
  const meterInfo = useSelector(selectMeterInfo);
  const payerInfo = useSelector(selectPayerNo);
  const type = useSelector(selectType);
  const vrsc = type.vrsc;
  const date = todayDate();
  const dispatch = useDispatch();
  const token = userInfo.accessToken;

  useEffect(() => {
    document.title = "삼천리 | 전출 완료";

    //토큰이 ""가 아닐 때
    if (token !== "") {
      //토큰이 유효하지 않을 때
      if (!checkJwt(userInfo.accessToken)) {
        //3자 전출일 때 token refresh
        if (type.type === "out" && vrsc) {
          if (checkRefreshCookie()) {
            requestTokenByRefresh(({ status, data }) => {
              if (status === 200) {
                //console.log(data);
                dispatch(setLoggedInApp(data));
              }
            });
          } else {
            dispatch(setExpiration(true));
            sessionStorage.clear();
            dispatch(setLoggedOffApp());
            history.push("/VrscMvot/APP_CM_02");
            return;
          }
        }
        //3자 전출이 아닐 때 accessTimeCheck
        else {
          history.push("/AccessTimeCheck");
          return;
        }
      }
      //토큰이 유효할 때
      else {
        //리덕스 값 체크 (없으면 404)
        if (
          payerInfo.payerId === "" ||
          payerInfo.name === "" ||
          payerInfo.address === "" ||
          meterInfo.outIndicator === "" ||
          meterInfo.totalCost === "" ||
          payerInfo.payerId === undefined ||
          payerInfo.name === undefined ||
          payerInfo.address === undefined ||
          meterInfo.outIndicator === undefined ||
          meterInfo.totalCost === undefined
        ) {
          history.push("/Error404");
          return;
        }
      }
    }
    //토큰이 ""일 때
    else {
      if (
        navigator.userAgent.indexOf("wv") > -1 ||
        (navigator.userAgent.indexOf("iPhone") > -1 &&
          navigator.userAgent.indexOf("Safari") === -1)
      ) {
        dispatch(setExpiration(true));
        dispatch(setLoggedOffApp());
        sessionStorage.clear();
        history.push("/VrscMvot/APP_CM_02");
        return;
      } else {
        history.push("/Error404");
        return;
      }
    }

    // 현재시간이 22시~6시 사이면 이용불가 페이지
    if (!checkTime()) {
      history.push("/ImpossiblePage");
      return;
    }
    // 토큰이 만료되었으면 404에러 페이지
    if (!checkJwt(userInfo.accessToken)) {
      history.push("/Error404");
      return;
    }
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", () => {
      window.history.pushState(null, document.title, window.location.href);
    });
    sessionStorage.removeItem("persist:samchully");
    sessionStorage.removeItem("mvotFinish");
  }, []);

  return (
    <>
      {vrsc ? <AppHeader title="전출 완료" /> : <Header title="전출 완료" />}

      <div id="container" className="container">
        <div className="section_finished">
          <div className="icon_area" />
          <p className="txt">고객님의 전출이 완료되었습니다.</p>

          <div className="section_table2">
            <div className="tit_sub">전출 정보</div>
            <table className="tbl_ver type_right">
              <tbody>
                <tr>
                  <th>고객명</th>
                  <td>{payerInfo.name}</td>
                </tr>
                <tr>
                  <th>전출지 주소</th>
                  <td>{payerInfo.address}</td>
                </tr>
                <tr>
                  <th>납부자 번호</th>
                  <td>{payerInfo.payerId}</td>
                </tr>
                <tr>
                  <th>전출 일자</th>
                  <td>{date}</td>
                </tr>
                <>
                  <tr>
                    <th>계량기 지침</th>
                    <td>
                      <span>{meterInfo.outIndicator}</span> m<sup>3</sup>
                    </td>
                  </tr>
                  <tr>
                    <th>가스요금 총액</th>
                    <td>
                      <span>
                        {meterInfo.totalCost
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </span>{" "}
                      원
                    </td>
                  </tr>
                </>
              </tbody>
            </table>
          </div>
          {type.kakao === "inapp" ? (
            <button
              type="submit"
              className="btn btn_blue btn_big"
              onClick={() => {
                sessionStorage.clear();
                if (navigator.userAgent.includes("iPhone")) {
                  // 카카오 인앱 아이폰의 경우 닫기
                  // window.location.href = "kakaoweb://closeBrowser";
                  window.location.href = process.env.REACT_APP_KAKAO_LOGOUT;
                } else {
                  // 카카오 인앱에서 안드로이드로 접근했던 경우
                  // window.location.href = "http://pf.kakao.com/_dDxmzu/chat";
                  window.location.href = process.env.REACT_APP_KAKAO_LOGOUT;
                }
              }}
            >
              확인
            </button>
          ) : vrsc ? (
            // 제 3자의 경우 메인페이지로 이동
            <button
              type="submit"
              className="btn btn_blue btn_big"
              onClick={() => {
                history.push("/VrscMvot/APP_CM_02");
              }}
            >
              확인
            </button>
          ) : // 그 외에 버튼 없음
          null}
        </div>
      </div>
    </>
  );
};

export default MVOT_04;
