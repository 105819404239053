import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cardList: [],
  bankList: [{ bankCd: "012", bankNm: "산업은행" }],
  bankHolidayYn: "",
};

export const bankCardSlice = createSlice({
  name: "bankCardInfo",
  initialState,
  reducers: {
    setBankList: (state, action) => {
      state.bankList = action.payload.bankList;
    },
    setCardList: (state, action) => {
      state.cardList = action.payload.cardList;
    },
    setBankHolidayYn: (state, action) => {
      state.bankHolidayYn = action.payload.bankHolidayYn;
    },
  },
});

export const { setBankList, setCardList, setBankHolidayYn } =
  bankCardSlice.actions;

export const selectBankCardInfo = (state) => ({
  cardList: state.bankCardInfo.cardList,
  bankList: state.bankCardInfo.bankList,
  bankHolidayYn: state.bankCardInfo.bankHolidayYn,
});

export default bankCardSlice.reducer;
