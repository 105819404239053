import React, { useState, useEffect } from "react";
import Header from "@components/common/Header/Header";
import { Modal } from "react-bootstrap";
import {
  selectPayerNo,
  setIplNo,
  setCheckMvin,
  setAddress,
} from "@store/modules/payerNoSlice";
import { useSelector, useDispatch } from "react-redux";
import { selectType } from "@store/modules/typeSlice";
import { selectMeterInfo, setLastIndicator } from "@store/modules/meterSlice";
import { selectUserInfo } from "@store/modules/userSlice";
import { BASE_URL, comfnApiSvcCall, METHOD } from "@src/lib/common/comfn";
import { checkTime, checkJwt } from "@src/lib/util/Util";
import { LoadingSpinner } from "@src/components/common/LoadingSpinner";

const SELF_MVIN_04 = ({ history }) => {
  //({ location, history }) => {
  const userInfo = useSelector(selectUserInfo);
  const token = userInfo.accessToken;
  const payerInfo = useSelector(selectPayerNo);
  const meterInfo = useSelector(selectMeterInfo);
  const type = useSelector(selectType);
  const kakao = type.kakao;
  useEffect(() => {
    document.title = "삼천리 | 전입 내용 확인";
    // 현재시간이 22시~6시 사이면 이용불가 페이지
    if (!checkTime()) {
      history.push("/ImpossiblePage");
      return;
    }

    //토큰이 있을 때
    if (token !== "") {
      //토큰이 유효하지 않을 때
      if (!checkJwt(token)) {
        history.push("/AccessTimeCheck");
        return;
      }
      //토큰이 유효할 때
      else {
        //리덕스 값 체크 (없으면 404)
        if (
          meterInfo.meterNum === "" ||
          meterInfo.inIndicator === "" ||
          payerInfo.payerId === "" ||
          userInfo.mobNo === "" ||
          payerInfo.name === "" ||
          payerInfo.address === "" ||
          payerInfo.address === undefined
        ) {
          history.push("/Error404");
          return;
        }
      }
    } else {
      history.push("/Error404");
      return;
    }

    // window.history.pushState(null, document.title, window.location.href);
    // window.addEventListener("popstate", () => {
    //   window.history.pushState(null, document.title, window.location.href);
    // });
  }, []);

  const [show, setShow] = useState(false);
  const [impossibleData, setImpossibleData] = useState([]);
  const [qmGbDate, setQmGbDate] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (qmGbDate === "") {
      return;
    } else {
      // SELF_MVIN_05 사용계약입력 페이지로 이동
      history.push("/SelfMvin/SELF_MVIN_05");
    }
  }, [qmGbDate]);

  const onClickMvin = () => {
    checkmeter();
  };

  const checkmeter = () => {
    // 전입 가능여부 조회에 필요한 정보 추가 확인 필요
    const customInfo = {
      reqTy: "MI",
      mtrNo: meterInfo.meterNum,
      msrNo: meterInfo.inIndicator,
      chkTy: "2",
    };
    fnPg15(customInfo);
  };

  const fnPg15 = (data) => {
    //console.log(data);
    // 기물번호 유효성 조회
    const option = {
      serviceId: "fnPg15",
      baseUrl: BASE_URL.COMMON,
      url: "/mvio/pg15",
      header: "accessToken",
      method: METHOD.POST,
      data: data,
      callback: fnCallback,
    };
    setLoading(true);
    comfnApiSvcCall(option);
  };

  const fnPg16 = (data) => {
    //console.log(data);
    // 전입 가능여부 조회
    const option = {
      serviceId: "fnPg16",
      baseUrl: BASE_URL.COMMON,
      url: "/mvin/pg16",
      header: "accessToken",
      method: METHOD.POST,
      data: data,
      callback: fnCallback,
    };
    setLoading(true);
    comfnApiSvcCall(option);
  };

  //callback 함수
  const fnCallback = (res) => {
    setLoading(false);
    //console.log(res);
    if (res.data.hasOwnProperty("rtnCode")) {
      if (res.data.rtnCode === "-999") {
        return;
      }
    }

    if (res.errorCode < 1) {
      //console.log("ERROR");
      //console.log(res);
      if (res.serviceId === "fnPg16") {
        // 전입이 안될경우 해당 사유 모달띄우기
        if (res.data.rtnMsg.trim() !== "") {
          if (res.data.rtnMsg.indexOf("RFC") > -1) {
            alert(res.data.rtnMsg);
            return;
          } else {
            const set = new Set([...impossibleData, res.data.rtnMsg]);
            setImpossibleData([...set]);
            setShow(true);
          }
        }
      } else if (res.serviceId === "fnPg15") {
        const set = new Set([...impossibleData, res.data.rtnMsg]);
        setImpossibleData([...set]);
        setShow(true);
      }
    } else if (res.serviceId === "fnPg16") {
      //console.log(res);
      if (
        res.data.delivDate === "" ||
        res.data.qmNo === "" ||
        res.data.rolGb === ""
      ) {
        const set = new Set(["이미 전입하신 내역이 있습니다."]);
        setImpossibleData([...set]);
        setShow(true);
        return;
      }
      // 전입이 가능할 경우
      // 배정일자, 업무구분, 통지번호 공통변수에 저장
      dispatch(
        setCheckMvin({
          delivDate: res.data.delivDate,
          rolGb: res.data.rolGb,
          qmNo: res.data.qmNo,
        })
      );
      setQmGbDate({
        delivDate: res.data.delivDate,
        rolGb: res.data.rolGb,
        qmNo: res.data.qmNo,
      });
    } else if (res.serviceId === "fnPg15") {
      if (parseInt(res.data.lastMsrNo) === parseInt(meterInfo.inIndicator)) {
        dispatch(setLastIndicator(res.data.lastMsrNo));
        dispatch(setIplNo(res.data.iplNo));
        const checkMvinData = {
          iplNo: res.data.iplNo,
          paypNo: payerInfo.payerId,
          name: payerInfo.name,
          mobNo: userInfo.mobNo,
          addr: res.data.addr2,
          wayInTy: kakao === "inapp" ? "2" : "1",
        };
        //console.log(checkMvinData);
        fnPg16(checkMvinData);
      } else {
        setImpossibleData(["전출지침과 전입지침이 일치하지 않습니다."]);
        setShow(true);
      }
    }
  };

  return (
    <>
      <Header title="전입 내용 확인" />
      <div id="container" className="container">
        <h3
          style={{
            fontSize: "16px",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          전입 주소를 반드시 확인해 주세요.
        </h3>
        <hr />
        <table className="tbl_ver2">
          <tbody>
            <tr>
              <th>전입자</th>
              <td>{payerInfo.name}</td>
            </tr>
          </tbody>
        </table>
        <hr />

        <table className="tbl_ver3">
          <tbody>
            <tr>
              <th>계량기 번호</th>
              <td>
                <span className="txt_blue">{meterInfo.meterNum}</span>
              </td>
            </tr>
            <tr>
              <th>주소</th>
              <td>{payerInfo.address}</td>
            </tr>
            <tr>
              <th>전입 지침</th>
              <td>
                {parseInt(meterInfo.inIndicator)} m<sup>3</sup>
              </td>
            </tr>
          </tbody>
        </table>

        <button
          type="button"
          className="btn btn_blue mt-3 mb-3"
          disabled={loading}
          // onClick={() => setShow(true)}
          onClick={onClickMvin}
          // onClick 이벤트에 셀프 전입이 가능한지 여부 판단하는 api 호출 => 불가시 setShow(true) => 모달에 해당 내용 띄우기
        >
          {/* 해당 내용으로 전입하겠습니다 */}
          {loading ? <LoadingSpinner /> : "해당 내용으로 전입하겠습니다"}
        </button>

        <div
          className="btn two_line_link_txt"
          onClick={() => history.push("/SelfMvin/MVIO_07")}
        >
          확인하신 전입지 주소와 전입 지침이 다른 경우,{""}
          <br />
          계량기 번호 및 전입 지침을 직접 입력해 주세요.
        </div>
      </div>

      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header>
          <Modal.Title>
            <h5 className="modal-title shot">
              조회 결과 셀프 전입이 불가하며, <br /> 사유는 아래와 같습니다.
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: 0 }}>
          <div className="info_box">
            {impossibleData.map((info, index) => (
              <p className="info" key={index}>{`${info}`}</p>
            ))}
          </div>

          <div className="cut_line" />

          <div className="bottom_box">
            <p className="txt_bottom">
              고객센터 <a href="tel:1544-3002">1544-3002</a>
            </p>
            <p className="txt_bottom">(상담가능시간 : 평일 09:00~18:00) 로</p>
            <p className="txt_bottom">연락 부탁드립니다.</p>
          </div>
        </Modal.Body>
        <div className="row btn-choice">
          <div className="col-6 btn-mid">
            <button
              type="button"
              className="btn btn_ok2"
              onClick={() => {
                setShow(false);
              }}
            >
              확인
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SELF_MVIN_04;
