import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  loggedIn: false,
  accessToken: "",
  refreshToken: "",
  // tokenExpiryDate: '',
  // usrId: '',
  usrNm: "",
  usrId: "",
  birth: "",
  // mbrcmpNo: 0,
  mobNo: "",
  // emailAddr: '',
};

export const userSlice = createSlice({
  name: "authorization",
  initialState,
  reducers: {
    setLoggedIn: (state, action) => {
      state.loggedIn = true;
      state.accessToken = action.payload.access_token;
      state.usrNm = action.payload.usrNm;
      state.birth = action.payload.birth;
      state.mobNo = action.payload.mobNo;
      state.usrId = action.payload.usrId;
    },
    setLoggedInApp: (state, action) => {
      state.loggedIn = true;
      state.accessToken = action.payload.access_token;
      state.refreshToken = action.payload.refresh_token;
      state.usrNm = action.payload.usrNm;
      state.birth = action.payload.birth;
      state.mobNo = action.payload.mobNo;
      state.usrId = action.payload.usrId;
    },
    setLoggedOff: (state) => {
      state.loggedIn = false;
      state.accessToken = "";
      state.usrNm = "";
      state.birth = "";
      state.mobNo = "";
      state.usrId = "";
    },
    setLoggedOffApp: (state) => {
      state.loggedIn = false;
      state.accessToken = "";
      state.refreshToken = "";
      state.usrNm = "";
      state.birth = "";
      state.mobNo = "";
      state.usrId = "";
    },
    setUser: (state, action) => {
      state.usrNm = action.payload.name;
      state.birth = action.payload.brthYm;
      state.mobNo = action.payload.mobNo;
    },
    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
    setInitalUser: (state) => {
      state.loggedIn = false;
      state.accessToken = "";
      state.usrNm = "";
      state.birth = "";
      state.mobNo = "";
      state.usrId = "";
    },
  },
});

export const {
  setLoggedIn,
  setLoggedOff,
  setAccessToken,
  setUser,
  setInitalUser,
  setLoggedInApp,
  setLoggedOffApp,
} = userSlice.actions;

export const selectLoggedIn = (state) => state.userInfo.loggedIn;
export const selectAccessToken = (state) => state.userInfo.accessToken;
export const selectRefreshToken = (state) => state.userInfo.refreshToken;
export const selectUserInfo = (state) => ({
  usrNm: state.userInfo.usrNm,
  birth: state.userInfo.birth,
  mobNo: state.userInfo.mobNo,
  usrId: state.userInfo.usrId,
  accessToken: state.userInfo.accessToken,
  refreshToken: state.userInfo.refreshToken,
});

export default userSlice.reducer;
