import React from "react";

const NumberInput = React.forwardRef((props, ref) => {
  // 숫자가 아닌 값들은 빈 문자열로 replace
  const numberOnly = (e) =>
    (e.target.value = e.target.value.replace(/[^0-9]/g, ""));

  return (
    <input
      {...props}
      type="number"
      ref={ref}
      // onInput={numberOnly} // 숫자만 입력 가능
    />
  );
});

export default NumberInput;
