import React, { useEffect } from "react";
import { Header } from "@components/common/Header";
import { checkTime } from "@src/lib/util/Util";
import { BASE_URL, METHOD, comfnApiSvcCall } from "../../lib/common/comfn";

const MBR_19 = ({ history, location }) => {
  useEffect(() => {
    document.title = "삼천리 | 본인 인증";
    if (!checkTime()) {
      history.push("/ImpossiblePage");
      return;
    }
    const query = location.search.split("=")[0];
    const value = location.search.split(query + "=")[1];

    if (query === "?EncodeData") {
      fnPostValue({ encData: convertDecode(value) });
    }
  }, []);

  const convertDecode = (data) => {
    var str = data.replace(/%2B/g, "+");
    return str;
  };

  const fnPostValue = (val) => {
    const option = {
      serviceId: "fnPostValue",
      baseUrl: BASE_URL.COMMON,
      url: "/nice/pg41",
      method: METHOD.POST,
      data: val,
      callback: fnCallback,
    };
    comfnApiSvcCall(option);
  };

  const fnCallback = (res) => {
    //console.log(res);
    if (res.serviceId === "fnPostValue") {
      // var returnData = window.opener.getElemetById("resData");
      // returnData.value = res.data.result;

      window.opener.setData(res.data.result);
    }
  };

  return (
    <>
      <div
        style={{ width: "100%", height: "100vh%", backgroundColor: "#f5f5f5" }}
      >
        <Header title="본인 인증" />
        <div id="container" className="container"></div>
      </div>
    </>
  );
};

export default MBR_19;
