import React, { useEffect, useState } from "react";
import AppHeader from "@components/common/AppHeader/AppHeader";
import { useForm } from "react-hook-form";
import { checkTime } from "@src/lib/util/Util";
import { LoadingSpinner } from "@src/components/common/LoadingSpinner";
import { BASE_URL, METHOD, comfnApiSvcCall } from "../../lib/common/comfn";
import { useSelector } from "react-redux";
import { selectUserInfo } from "@store/modules/userSlice";
import { selectNiceInfo } from "@src/store/modules/niceSlice";

const MBR_12 = ({ history }) => {
  const niceInfo = useSelector(selectNiceInfo);
  useEffect(() => {
    document.title = "삼천리 | 비밀번호 재설정";
    // 현재시간이 22시~6시 사이면 이용불가 페이지
    if (!checkTime()) {
      history.push("/ImpossiblePage");
      return;
    }
    if (niceInfo.usrId === "" || niceInfo.createdDt === "") {
      history.push({
        pathname: "/VrscMvot/MBR_08",
        state: { page: "VRSC_MVOT_01" },
      });
      return;
    }
  }, []);

  // const userInfo = useSelector(selectUserInfo);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();

  const fnPg33 = (data) => {
    //console.log(data);
    const option = {
      serviceId: "fnPg33",
      baseUrl: BASE_URL.COMMON,
      url: "/mbr/pg33",
      method: METHOD.POST,
      data: data,
      callback: fnCallback,
    };
    setLoading(true);
    comfnApiSvcCall(option);
  };

  const fnCallback = (res) => {
    setLoading(false);
    if (res.data.hasOwnProperty("rtnCode")) {
      if (res.data.rtnCode === "-999") {
        return;
      }
    }
    //console.log(res);
    if (res.errorCode < 0) {
    } else if (res.serviceId === "fnPg33") {
      if (res.data.rtnCode === "0") {
        alert("비밀번호가 재설정되었습니다.");
        history.push({
          pathname: "/VrscMvot/MBR_08",
          state: { page: "" },
        });
      }
    } else {
      alert(res.data.rtnMsg);
    }
  };

  const onSubmit = (data) => {
    //console.log(`form data : ${JSON.stringify(data)}`);
    fnPg33({
      usrPwNew: data.password,
      usrId: niceInfo.usrId === undefined ? null : niceInfo.usrId,
    });
  };

  return (
    <>
      <AppHeader title="비밀번호 재설정" />

      <form onSubmit={handleSubmit(onSubmit)}>
        <div id="container" className="container">
          <p className="passreset_txt">새로 사용할 비밀번호를 입력해 주세요.</p>
          <div className="form-group">
            <label htmlFor="password">새로운 비밀번호</label>
            <input
              type="password"
              className="form-control"
              id="password"
              placeholder="영문+숫자+특수문자 조합 8자리 이상"
              {...register("password", {
                required: true,
                pattern:
                  /^(?=.*[a-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/,
              })}
            />
            {errors.password && (
              <small className="form-text text_alert">
                비밀번호를 확인해 주세요.
              </small>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="passwordChk">새로운 비밀번호 확인</label>
            <input
              type="password"
              className="form-control"
              id="passwordChk"
              placeholder="새로운 비밀번호와 동일하게 입력"
              {...register("passwordChk", {
                required: true,
                validate: (value) => {
                  const password = getValues("password");
                  return password === value;
                },
              })}
            />
            {errors.passwordChk && (
              <small className="form-text text_alert">
                새로운 비밀번호 확인 값을 확인해 주세요.
              </small>
            )}
          </div>
        </div>

        <button
          type="submit"
          className="btn btn_blue btn_big btn_bottom"
          style={{ marginTop: 20 }}
          disabled={loading}
        >
          {loading ? <LoadingSpinner /> : "비밀번호 재설정"}
        </button>
      </form>
    </>
  );
};

export default MBR_12;
