import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  meterNum: "", // 계량기 번호
  outIndicator: "", // 전출 계량기 지침
  inIndicator: "", // 전입 계량기 지침
  totalCost: "", //총액
  filePathGas: "", // 가스렌지 이미지 경로
  filePathMet: "", // 미터기 이미지 경로
  edditYn: "N", // 직접입력을 통한 수정여부
  lastIndicator: "", // 최종 지침
};

export const meterSlice = createSlice({
  name: "meterInfo",
  initialState,
  reducers: {
    setOutMeter: (state, action) => {
      state.meterNum = action.payload.meterNum;
      state.outIndicator = action.payload.outIndicator;
      state.totalCost = action.payload.totalCost;
      state.edditYn = "Y";
    },
    setInMeter: (state, action) => {
      state.meterNum = action.payload.meterNum;
      state.inIndicator = action.payload.inIndicator;
      state.totalCost = action.payload.totalCost;
      state.edditYn = "Y";
    },
    setFilePathGas: (state, action) => {
      state.filePathGas = action.payload;
    },
    setMeterPicture: (state, action) => {
      state.meterNum = action.payload.meterNum;
      state.outIndicator = action.payload.Indicator;
      state.inIndicator = action.payload.Indicator;
      state.filePathMet = action.payload.filePathMet;
      state.edditYn = "N";
    },
    setInitalMeter: (state) => {
      state.meterNum = "";
      state.outIndicator = "";
      state.inIndicator = "";
      state.totalCost = "";
      state.filePathGas = "";
      state.filePathMet = "";
    },
    setLastIndicator: (state, action) => {
      state.lastIndicator = action.payload;
    },
    setOutMeterSession: (state, action) => {
      state.outIndicator = action.payload.outIndicator;
      state.totalCost = action.payload.totalCost;
      state.edditYn = action.payload.edditYn;
      state.filePathMet = action.payload.filePathMet;
      state.lastIndicator = action.payload.lastIndicator;
    },
  },
});

export const {
  setOutMeter,
  setInMeter,
  setFilePathGas,
  setMeterPicture,
  setInitalMeter,
  setLastIndicator,
  setOutMeterSession,
} = meterSlice.actions;

export const selectMeterInfo = (state) => ({
  meterNum: state.meterInfo.meterNum,
  outIndicator: state.meterInfo.outIndicator,
  inIndicator: state.meterInfo.inIndicator,
  totalCost: state.meterInfo.totalCost,
  filePathGas: state.meterInfo.filePathGas,
  filePathMet: state.meterInfo.filePathMet,
  edditYn: state.meterInfo.edditYn,
  lastIndicator: state.meterInfo.lastIndicator,
});

export default meterSlice.reducer;
