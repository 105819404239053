import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  img: "", // 사진 촬영 이미지(base64)
  meterImg: "", // 원본 이미지
};

export const mvio04Slice = createSlice({
  name: "mvio04Info",
  initialState,
  reducers: {
    setImg: (state, action) => {
      state.img = action.payload.img;
      state.meterImg = action.payload.meterImg;
    },
    setCamImg: (state, action) => {
      state.img = action.payload;
    },
    setMeterImg: (state, action) => {
      state.meterImg = action.payload;
    },
  },
});

export const { setImg, setMeterImg, setCamImg } = mvio04Slice.actions;

export const selectMvio04 = (state) => ({
  img: state.mvio04Info.img,
  meterImg: state.mvio04Info.meterImg,
});

export default mvio04Slice.reducer;
