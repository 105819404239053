import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Header from "@components/common/Header/Header";
import NumberInput from "@components/common/NumberInput/NumberInput";
import {
  selectType,
  setInitalType,
  setType,
  setKakao,
  setTypeInapp,
} from "@store/modules/typeSlice";
import {
  setLoggedIn,
  setAccessToken,
  setInitalUser,
} from "@store/modules/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL, comfnApiSvcCall, METHOD } from "@src/lib/common/comfn";
import { checkTime, maxLengthCheck, isBirthdayValid } from "@src/lib/util/Util";
import { LoadingSpinner } from "@src/components/common/LoadingSpinner";

import {
  setInitalPayer,
  setPayerNo,
  setSELF_MVIN_03List,
  setSELF_MVOT_03List,
} from "@src/store/modules/payerNoSlice";
import { requestWebToken } from "../../lib/util/AuthUtil";
import { setInitalMeter } from "@src/store/modules/meterSlice";
import { setInitalMVOT_02 } from "@src/store/modules/mvot02Slice";
import { Modal } from "react-bootstrap";

const iNumber = ["010", "011", "016", "017", "018", "019"];

const MVIO_01 = ({ history, location }) => {
  const type = useSelector(selectType);
  const path = history.location.pathname;
  const [checkSendAgain, setCheckSendAgain] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setError,
    clearErrors,
    setFocus,
    setValue,
  } = useForm();
  const [showAuthInput, setShowAuthInput] = useState(false);
  const [authTextAlert, setAuthTextAlert] = useState("");
  const [id, setId] = useState();
  const [authNum, setAuthNum] = useState();
  const [userName, setUserName] = useState("");
  const [userPhoneNumber, setUserPhoneNumber] = useState("");
  const [userBirth, setUserBirth] = useState("");
  const [payerList, setPayerList] = useState();
  const watchName = watch("name");
  const watchPhoneStart = watch("phoneStart");
  const watchPhoneCenter = watch("phoneCenter");
  const watchPhoneEnd = watch("phoneEnd");
  const watchBirth = watch("birth");
  const watchAuthNum = watch("authNum");
  const [loading, setLoading] = useState(false);
  const [authLoading, setAuthLoading] = useState(false);
  const [authTime, setAuthTime] = useState("");
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();

  //인증번호 전송:pg3 호출
  const sendAuthNum = (name, mobNo, birth) => {
    const nowDate = new Date();
    const diffTime =
      authTime === "" ? 1000 : (nowDate.getTime() - authTime.getTime()) / 1000;

    if (diffTime < 10) {
      setError("authNum", {
        type: "invalidTime authNum",
      });
    } else if (authTime === "" || diffTime >= 10) {
      let data = {
        reqTy: type.vrsc === true ? "2" : "1",
        name: name,
        mobNo: mobNo,
      };
      setUserName(name);
      setUserPhoneNumber(mobNo);
      setUserBirth(birth);
      const option = {
        serviceId: "pg03",
        baseUrl: BASE_URL.COMMON,
        url: "/mvio/pg03",
        method: METHOD.POST,
        data: data,
        callback: fnCallback,
      };
      setAuthLoading(true);
      comfnApiSvcCall(option);
    }
  };

  const sendAllAuthInfo = () => {
    if (type["type"] === "out" || type["type"] === "in") {
      let data = {
        reqTy: type["type"] === "out" ? "MO" : "MI",
        brthYm: userBirth,
        name: userName,
        mobNo: userPhoneNumber,
      };

      const option = {
        serviceId: "pg01",
        baseUrl: BASE_URL.COMMON,
        url: "/mvio/pg01",
        method: METHOD.POST,
        data: data,
        callback: fnCallback,
      };
      setLoading(true);
      comfnApiSvcCall(option);
    } else {
      history.push("/Error404");
      return;
    }
  };

  //토큰 생성 요청
  const fnGetToken = (paypNo) => {
    let data = {
      req_ty: type.type === "in" ? "MI" : "",
      payp_no: paypNo,
      mob_no: userPhoneNumber,
      callback: callbackToken,
    };
    requestWebToken(data);
  };

  const fnCallback = (res) => {
    setLoading(false);
    setAuthLoading(false);
    if (res.data.hasOwnProperty("rtnCode")) {
      if (res.data.rtnCode === "-999") {
        return;
      }
    }
    //console.log(res);
    if (res.errorCode < 0) {
      alert(res.data.rtnMsg);
    } else if (res.serviceId === "pg01") {
      if (res.data.list.length < 1) {
        alert("고객정보가 존재하지 않습니다.");
        setValue("name", "");
        setValue("birth", "");
        setValue("phoneCenter", "");
        setValue("phoneEnd", "");
        setValue("authNum", "");
        setId("authNumSendBtn");
        setShowAuthInput(false);
        setCheckSendAgain(false);
        return;
      } else {
        if (type.type === "in") {
          if (res.data.creYn === "N") {
            dispatch(setSELF_MVIN_03List(res.data.list));
            // history.push({
            //   pathname: "/SelfMvin/SELF_MVIN_03",
            //   state: { payerInfo: res.data.list },
            // });
            history.push("/SelfMvin/SELF_MVIN_03");
          } else if (res.data.creYn === "Y") {
            // history.push({
            //   pathname: "/SelfMvin/SELF_MVIN_02",
            //   state: { payerInfo: res.data.list },
            // });

            dispatch(
              setPayerNo({
                payerId: res.data.list[0].paypNo,
                custNo: res.data.list[0].custno,
                iplNo: res.data.list[0].iplNo,
                name: userName,
                address: res.data.list[0].addr1,
                roadAddress: res.data.list[0].addr2,
              })
            );

            dispatch(
              setLoggedIn({
                usrNm: userName,
                birth: userBirth,
                mobNo: userPhoneNumber,
              })
            );

            setPayerList(res.data.list);
            fnGetToken(res.data.list[0].paypNo);
          }
        } else {
          let checkNoData = false;
          for (let i = 0; i < res.data.list.length; i++) {
            if (
              res.data.list[i].paypNo === "" ||
              res.data.list[i].custno === "" ||
              res.data.list[i].name === "" ||
              res.data.list[i].brthYm === "" ||
              res.data.list[i].mobNo === "" ||
              res.data.list[i].iplNo === "" ||
              res.data.list[i].addr1 === "" ||
              res.data.list[i].addr2 === ""
            ) {
              alert(
                "본인 인증을 실패했습니다.\n고객센터 1544-3002 (상담가능시간 : 평일 09:00~18:00)로 연락 부탁 드립니다."
              );
              checkNoData = true;
              return;
            }
          }
          if (checkNoData === false) {
            dispatch(setSELF_MVOT_03List(res.data.list));
            // history.push({
            //   pathname: "/SelfMvot/SELF_MVOT_03",
            //   state: { payerInfo: res.data.list },
            // }); // 전출 주소 선택으로 이동
            history.push("/SelfMvot/SELF_MVOT_03"); // 전출 주소 선택으로 이동
          }
        }
      }
    } else if (res.serviceId === "pg03") {
      if (res.data.rtnCode === "0") {
        //alert(res.data.chkNo); //배포시 수정필요
        setAuthNum(res.data.chkNo);

        if (checkSendAgain === false) {
          setShowAuthInput(true);
          setAuthTextAlert("인증번호가 발송되었습니다.");
          setCheckSendAgain(true);
        } else {
          clearErrors("authNum");
          setAuthTextAlert("인증번호를 재전송 했습니다.");
        }
      } else {
        alert(res.data.rtnMsg);
      }
      setAuthTime(new Date());
    }
  };

  const callbackToken = ({ status, data, message }) => {
    if (status === 200) {
      dispatch(setAccessToken(data.access_token));
      dispatch(setSELF_MVIN_03List(payerList));
      // history.push({
      //   pathname: "/SelfMvin/SELF_MVIN_02",
      //   state: { payerInfo: payerList },
      // });
      history.push("/SelfMvin/SELF_MVIN_02");
    } else {
      alert("실패하였습니다.");
    }
  };

  const onSubmit = (data) => {
    const mobNo = data["phoneStart"] + data["phoneCenter"] + data["phoneEnd"];
    // onClickTest(mobNo);

    if (id === "authNumSendBtn") {
      if (isBirthdayValid(watchBirth)) {
        sendAuthNum(watchName, mobNo, data["birth"]);
      } else {
        alert("존재하지 않는 생년월일입니다.");
        setError("birth");
        return;
      }
    } else {
      setAuthTextAlert("");
      if (!showAuthInput) alert("인증번호를 입력해 주세요.");
      else if (watchAuthNum + "" === "") {
        setError("authNum", {
          type: "no authNum",
        });
      } else if (authNum + "" !== watchAuthNum + "") {
        setError("authNum", {
          type: "invalid authNum",
        });
      } else {
        sendAllAuthInfo(); //pg01 호출
      }
    }
  };

  const onClick = () => {
    if (errors.phoneCenter || errors.phoneEnd) {
      // setError("authNum", {
      //   type: "invalid phoneNumber",
      //   message: "형식에 맞지 않는 번호입니다.",
      // });
      setAuthTextAlert("");
      return;
    } else if (errors.birth) {
      setAuthTextAlert("");
      return;
    } else if (errors.name) {
      setAuthTextAlert("");
      return;
    } else {
      sendAuthNum(
        watchName,
        watchPhoneStart + watchPhoneCenter + watchPhoneEnd,
        watchBirth
      );
    }
  };

  const onClickOk = () => {
    setId("authCompleteBtn");
  };

  //인증번호 전송 버튼
  const onClickSend = () => {
    setId("authNumSendBtn");
  };

  // phoneCenter input 값이 존재하고 길이가 4면, phoneEnd input 으로 focus
  useEffect(() => {
    if (watchPhoneCenter && watchPhoneCenter.length === 4) setFocus("phoneEnd");
  }, [watchPhoneCenter, setFocus]);

  useEffect(() => {
    if (watchBirth === undefined || watchBirth.length === 0) {
      clearErrors("birth");
    }
  }, [watchBirth]);

  useEffect(() => {
    document.title = "삼천리 | 본인인증";
    // 현재시간이 22시~6시 사이면 이용불가 페이지
    if (!checkTime()) {
      history.push("/ImpossiblePage");
      return;
    }
    sessionStorage.clear();
    dispatch(setInitalPayer());
    dispatch(setInitalMeter());
    dispatch(setInitalUser());
    dispatch(setInitalMVOT_02());
    if ((path + "").toLowerCase().includes("selfmvin")) {
      if (
        window.location.href.includes("agent") ||
        (navigator.userAgent.includes("iPhone") &&
          navigator.userAgent.match(
            /inapp|NAVER|KAKAOTALK|Snapchat|Line|WirtschaftsWoche|Thunderbird|Instagram|everytimeApp|WhatsApp|Electron|wadiz|AliApp|zumapp|iPhone(.*)Whale|Android(.*)Whale|kakaostory|band|twitter|DaumApps|DaumDevice\/mobile|FB_IAB|FB4A|FBAN|FBIOS|FBSS|SamsungBrowser\/[^1]/i
          ))
      ) {
        dispatch(setTypeInapp({ type: "in", vrsc: false, kakao: "inapp" }));
      } else {
        dispatch(setType({ type: "in", vrsc: false }));
        setShow(true);
      }
    }

    if (
      (path + "").toLowerCase().includes("selfmvot") &&
      type.kakao === false
    ) {
      setShow(true);
    }

    if ((path + "").toLowerCase().includes("selfmvot") && type.type !== "out") {
      history.push("/Error404");
      return;
    }
  }, []);

  useEffect(() => {
    if (showAuthInput === true) {
      setShowAuthInput(false);
      setCheckSendAgain(false);
      setValue("authNum", "");
      setAuthTime("");
    }
  }, [watchName, watchBirth, watchPhoneStart, watchPhoneCenter, watchPhoneEnd]);

  return (
    <>
      <Header title="본인 인증" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div id="container" className="container">
          <div className="form-group">
            <label htmlFor="name">이름</label>
            <input
              type="text"
              className="form-control"
              id="name"
              placeholder="홍길동"
              maxLength="40"
              // 필수로 입력하도록 제한
              {...register("name", { required: true })}
            />
            {/* 해당 input 에 에러가 발생했을 때, small 태그 렌더링 */}
            {errors.name && (
              <small className="form-text text_alert">
                이름을 확인해 주세요.
              </small>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="birth">생년월일</label>
            <NumberInput
              id="birth"
              className="form-control"
              placeholder={'YY-MM-DD (" - " 없이 입력)'}
              maxLength="6"
              onInput={maxLengthCheck}
              // 필수로 입력해야하고, 6자리 숫자만 입력 가능하도록 제한
              {...register("birth", {
                required: true,
                pattern: /^[0-9]{6}$/,
              })}
            />
            {errors.birth && (
              <small className="form-text text_alert">
                생년월일을 확인해 주세요.
              </small>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="phoneStart">휴대폰 번호</label>
            <div className="input-area">
              <select
                id="phoneStart"
                className="form-control phone"
                defaultValue="010"
                {...register("phoneStart")}
              >
                {iNumber.map((number, index) => (
                  <option value={number} key={index}>
                    {number}
                  </option>
                ))}
              </select>
              <span className="hyphen">&#45;</span>
              <NumberInput
                className="form-control phone"
                placeholder={"1234"}
                maxLength="4"
                onInput={maxLengthCheck}
                {...register("phoneCenter", {
                  required: true,
                  pattern: /^[0-9]{4}$/,
                })}
              />
              <span className="hyphen">&#45;</span>
              <NumberInput
                className="form-control phone"
                maxLength="4"
                placeholder={"5678"}
                onInput={maxLengthCheck}
                {...register("phoneEnd", {
                  required: true,
                  pattern: /^[0-9]{4}$/,
                })}
              />
            </div>
            {(errors.phoneCenter || errors.phoneEnd) && (
              <small className="form-text text_alert">
                휴대폰 번호를 확인해 주세요.
              </small>
            )}
          </div>
          {showAuthInput && (
            <div className="form-group authen">
              <label htmlFor="authNum">인증번호</label>
              <NumberInput
                id="authNum"
                className="form-control"
                placeholder="인증번호를 입력해 주세요."
                {...register("authNum")}
              />
              {!errors.authNum && authTextAlert !== "" && (
                <small
                  className="form-text text_alert"
                  style={{ color: "#08a600" }}
                >
                  {authTextAlert}
                </small>
              )}

              {errors.authNum && errors.authNum.type === "invalid authNum" && (
                <small className="form-text text_alert">
                  인증번호가 일치하지 않습니다.
                </small>
              )}

              {/* {errors.authNum &&
                errors.authNum.type === "required" &&
                checkSendAgain === false && (
                  <small className="form-text text_alert">
                    인증번호를 입력해 주세요.
                  </small>
                )} */}

              {errors.authNum && errors.authNum.type === "no authNum" && (
                <small className="form-text text_alert">
                  인증번호를 입력해 주세요.
                </small>
              )}

              {errors.authNum &&
                errors.authNum.type === "invalid phoneNumber" && (
                  <small className="form-text text_alert">
                    {errors.authNum.message}
                  </small>
                )}

              {errors.authNum &&
                errors.authNum.type === "invalidTime authNum" && (
                  <small className="form-text text_alert">
                    잠시후 다시 전송해 주세요.
                  </small>
                )}
            </div>
          )}
          <button
            id="authNumSendBtn"
            type={showAuthInput ? "button" : "submit"}
            className="btn btn_white"
            {...(showAuthInput && { onClick: onClick })}
            {...(!showAuthInput && { onClick: onClickSend })}
            disabled={authLoading}
          >
            {authLoading ? (
              <LoadingSpinner />
            ) : showAuthInput ? (
              "인증번호 재전송"
            ) : (
              "인증번호 전송"
            )}
            {/* {showAuthInput ? "인증번호 재전송" : "인증번호 전송"} */}
          </button>
        </div>

        <button
          id="authCompleteBtn"
          type="submit"
          className="btn btn_blue btn_big btn_bottom"
          onClick={onClickOk}
          disabled={loading}
        >
          {/* 인증 완료 */}
          {loading ? <LoadingSpinner /> : "인증 완료"}
        </button>
      </form>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        centered
        style={{ top: "-5%" }}
      >
        <Modal.Header closeButton style={{ padding: "10px" }}></Modal.Header>
        <Modal.Title>
          <h5
            className="modal-title shot"
            style={{ padding: "0px 20px 5px 20px" }}
          >
            삼천리 도시가스에 등록된
            <br />
            고객정보로 인증이 가능합니다.
          </h5>
        </Modal.Title>
        <div className="row btn-choice">
          <div className="col-3 btn-mid">
            <button
              type="button"
              className="btn btn_blue mb-3 mt-3"
              onClick={() => {
                setShow(false);
              }}
            >
              확인
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default MVIO_01;
