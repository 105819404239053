import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { NumberInput } from "@components/common/NumberInput";
import AppHeader from "@components/common/AppHeader/AppHeader";
import { checkTime } from "@src/lib/util/Util";
import { LoadingSpinner } from "@src/components/common/LoadingSpinner";
import { BASE_URL, METHOD, comfnApiSvcCall } from "../../lib/common/comfn";

const MBR_06 = ({ location, history }) => {
  useEffect(() => {
    document.title = "삼천리 | 회원가입-회원정보";
    // 현재시간이 22시~6시 사이면 이용불가 페이지
    if (!checkTime()) {
      history.push("/ImpossiblePage");
      return;
    }
    if (location.state === undefined) {
      history.push("/ErrorRedirect");
      return;
    } else {
      setValue("name", location.state.data.usrNm);
      setValue("relation", "default");
    }
    // setValue("name", "홍길동");
  }, []);

  const [signinInfo, setSigninInfo] = useState("");
  const [sign, setSign] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (signinInfo === "") return;
    else if (sign) {
      fnPg28(signinInfo);
    }
  }, [signinInfo, sign]);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
    setValue,
    clearErrors,
    setError,
  } = useForm();

  const onSubmit = (data) => {
    // console.log(`form data : ${JSON.stringify(data)}`);
    setSigninInfo({
      ci: location.state.data.ci,
      usrNm: location.state.data.usrNm,
      mobNo: location.state.data.mobNo,
      brthYm: location.state.data.brthYm.substring(2),
      usrId: data.id,
      usrPw: data.password,
      thrdTyCd: data.relation,
      postCd: data.zipCode,
      baseAddr: data.address,
      dtlAddr: data.detailAddress,
      cmpNm: data.companyName,
      crNo: data.businessNumber,
      jurirNo: data.corporationNumber,
      thrdTyTxt: data.relationship,
    });
    fnPg38({ usrId: data.id });
  };

  const watchRelation = watch("relation");
  const watchCompanyName = watch("companyName");
  const watchBusinessNumber = watch("businessNumber");
  const watchCorporationNumber = watch("corporationNumber");
  const watchZipCode = watch("zipCode");
  const watchAddress = watch("address");
  const watchDetailAddress = watch("detailAddress");

  // 회원 ID조회 (중복조회)
  const fnPg38 = (data) => {
    const option = {
      serviceId: "fnPg38",
      baseUrl: BASE_URL.COMMON,
      url: "/mbr/pg38",
      method: METHOD.POST,
      data: data,
      callback: fnCallback,
    };
    comfnApiSvcCall(option);
  };

  // 회원가입
  const fnPg28 = (data) => {
    const option = {
      serviceId: "fnPg28",
      baseUrl: BASE_URL.COMMON,
      url: "/mbr/pg28",
      method: METHOD.POST,
      data: data,
      callback: fnCallback,
    };
    setLoading(true);
    comfnApiSvcCall(option);
  };

  const fnCallback = (res) => {
    setLoading(false);
    if (res.data.hasOwnProperty("rtnCode")) {
      if (res.data.rtnCode === "-999") {
        return;
      }
    }
    //console.log(res);
    if (res.errorCode === -1) {
      //console.log("err");
      alert(res.data.rtnMsg);
    } else if (res.serviceId === "fnPg38") {
      if (res.data.usrId === "") {
        //회원가입
        setSign(true);
      } else {
        // 존재하는 아이디인 경우
        alert("이미 사용중인 아이디입니다. 다른 아이디를 입력해 주세요.");
      }
    } else if (res.serviceId === "fnPg28") {
      if (res.data.rtnCode === "0") {
        history.push("/VrscMvot/MBR_07");
      } else {
        alert(res.data.rtnMsg);
      }
    }
  };

  const openAddressPopUp = () => {
    window.open(
      process.env.REACT_APP_API_COMMON_URL + "/VrscMvot/FindAddress",
      "",
      "fullscreen=yes, menubar=no, toolbar=no, titlebar=yes, scrollbar=no"
    );
    window.scrollTo(0, 0);
    // window.open(
    //   "http://localhost:3000/VrscMvot/FindAddress",
    //   "",
    //   "fullscreen=yes, menubar=no, toolbar=no, titlebar=yes, scrollbar=no"
    // );
    // window.open(
    //   process.env.REACT_APP_API_COMMON_URL + "/VrscMvot/FindAddress",
    //   "",
    //   "height=" +
    //     (window.screen.height - 300) +
    //     ", width=" +
    //     window.screen.width +
    //     ", top=" +
    //     window.screen.height / 2
    // );
  };

  const onClickSignButton = () => {
    if (!watchZipCode || !watchAddress || !watchDetailAddress) {
      setError("zipInfo");
    }

    if (watchRelation === "default") {
      clearErrors("companyName");
      clearErrors("businessNumber");
      clearErrors("corporationNumber");
    } else {
      if (watchRelation !== "3") {
        if (watchCompanyName === undefined || watchCompanyName === "") {
          setError("companyName");
        }
        if (
          watchBusinessNumber === undefined ||
          watchBusinessNumber + "".length !== 10
        ) {
          setError("businessNumber");
        }
        if (
          watchCorporationNumber !== undefined ||
          watchCorporationNumber !== "" ||
          watchCorporationNumber + "".length !== 13
        ) {
          setError("corporationNumber");
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener("message", (e) => {
      //console.log(e.data);
      if (e.data.hasOwnProperty("message")) {
        //console.log("setvalue");
        if (e.data.message.hasOwnProperty("postCd")) {
          setValue("zipCode", e.data.message.postCd);
          setValue("address", e.data.message.baseAddr);
          setValue("detailAddress", e.data.message.dtlAddr);
          clearErrors("zipInfo");
        }
      }
    });
  }, []);

  useEffect(() => {
    if (watchRelation === "3") {
      clearErrors("companyName");
      clearErrors("businessNumber");
      clearErrors("corporationNumber");
      setValue("relationship", "");
      setValue("companyName", "");
      setValue("businessNumber", "");
      setValue("corporationNumber", "");
    } else if (watchRelation === "1") {
      setValue("relationship", "부동산");
      clearErrors("relationship");
    } else if (watchRelation === "2") {
      setValue("relationship", "관리인");
      clearErrors("relationship");
    }
  }, [watchRelation]);

  return (
    <>
      <AppHeader title="회원가입" />
      <div id="container" className="container">
        <div className="sequen_area">
          <div className="gray_circle" />
          <div className="gray_circle" />
          <div className="sequen_box">
            <span>3 회원정보</span>
          </div>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <label htmlFor="name">이름</label>
            <input
              type="text"
              className="form-control"
              id="name"
              // placeholder="홍길동"
              value={
                location.state === undefined ? null : location.state.data.usrNm
              }
              disabled
              {...register("name", { required: true })}
            />
            {errors.name && (
              <small className="form-text text_alert">
                이름을 확인해 주세요.
              </small>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="id">아이디</label>
            <input
              type="text"
              className="form-control"
              id="id"
              placeholder="영문 소문자+숫자 조합 4~12자리"
              {...register("id", {
                required: true,
                pattern: /^(?=.*[a-z])(?=.*\d)[a-z\d]{4,12}$/,
              })}
            />
            {errors.id && (
              <small className="form-text text_alert">
                아이디를 확인해 주세요.
              </small>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="password">비밀번호</label>
            <input
              type="password"
              className="form-control"
              id="password"
              placeholder="영문+숫자+특수문자 조합 8자리 이상"
              {...register("password", {
                required: true,
                pattern:
                  /^(?=.*[a-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/,
              })}
            />
            {errors.password && (
              <small className="form-text text_alert">
                비밀번호를 확인해 주세요.
              </small>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="passwordChk">비밀번호 확인</label>
            <input
              type="password"
              className="form-control"
              id="passwordChk"
              placeholder="비밀번호와 동일하게 입력"
              {...register("passwordChk", {
                required: true,
                validate: (value) => {
                  const password = getValues("password");
                  return password === value;
                },
              })}
            />
            {errors.passwordChk && (
              <small className="form-text text_alert">
                비밀번호 확인을 확인해 주세요.
              </small>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="relation">
              계약자와의 관계 <span className="nec">(필수)</span>
            </label>
            <select
              className="form-control"
              id="relation"
              defaultValue="default"
              {...register("relation", {
                required: true,
                validate: (value) => value !== "default",
              })}
            >
              <option value="default" disabled hidden>
                선택해 주세요
              </option>
              <option value="1">부동산</option>
              <option value="2">관리인</option>
              <option value="3">기타</option>
            </select>
            {errors.relation && (
              <small className="form-text text_alert">
                계약자와의 관계를 선택해 주세요.
              </small>
            )}
          </div>
          {watchRelation === "3" && (
            <>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="relationship"
                  placeholder="(필수) 계약자와 관계를 직접 입력해 주세요."
                  {...register("relationship", { required: true })}
                />
                {errors.relationship && (
                  <small className="form-text text_alert">
                    계약자와의 관계를 입력해 주세요.
                  </small>
                )}
              </div>
            </>
          )}
          {(watchRelation === "1" || watchRelation === "2") && (
            <>
              <div className="form-group">
                <label htmlFor="companyName">
                  회사명 <span className="nec">(필수)</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="companyName"
                  {...register("companyName", { required: true })}
                />
                {errors.companyName && (
                  <small className="form-text text_alert">
                    회사명을 확인해 주세요.
                  </small>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="businessNumber">
                  사업자번호 <span className="nec">(필수)</span>
                </label>
                <NumberInput
                  className="form-control"
                  id="businessNumber"
                  maxLength="10"
                  {...register("businessNumber", {
                    required: true,
                    pattern: /^[0-9]{10}$/,
                  })}
                />
                {errors.businessNumber && (
                  <small className="form-text text_alert">
                    사업자번호를 확인해 주세요.
                  </small>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="corporationNumber">법인번호 (선택)</label>
                <NumberInput
                  className="form-control"
                  id="corporationNumber"
                  maxLength="13"
                  {...register("corporationNumber", {
                    pattern: /^[0-9]{13}$/,
                  })}
                />
                {errors.corporationNumber && (
                  <small className="form-text text_alert">
                    법인번호를 확인해 주세요.
                  </small>
                )}
              </div>
            </>
          )}

          <div className="form-group">
            <label htmlFor="zipCode">
              주소 <span className="nec">(필수)</span>
            </label>
            <div className="zipcode_area row">
              <div className="col-7 zip">
                <NumberInput
                  className="form-control mb-2"
                  id="zipCode"
                  placeholder="우편번호"
                  maxLength="5"
                  {...register("zipCode")}
                  readOnly
                />
              </div>
              <div className="col-5 btn_add">
                <button
                  type="button"
                  className="btn btn_white address"
                  onClick={openAddressPopUp}
                >
                  우편번호 찾기
                </button>
              </div>
            </div>
            <input
              type="text"
              className="form-control"
              id="address"
              placeholder="주소"
              {...register("address")}
              readOnly
            />
            <input
              type="text"
              className="form-control mt-2"
              id="detailAddress"
              placeholder="상세주소"
              {...register("detailAddress")}
            />
            {errors.zipInfo && (
              <small className="form-text text_alert">
                주소를 확인해 주세요.
              </small>
            )}
          </div>

          <button
            type="submit"
            className="btn btn_blue btn_big"
            disabled={loading}
            onClick={onClickSignButton}
          >
            {loading ? <LoadingSpinner /> : "회원가입"}
          </button>
        </form>
      </div>
    </>
  );
};

export default MBR_06;
