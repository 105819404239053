import React, { useState, useRef, useEffect, useCallback } from "react";
import Header from "@components/common/Header/Header";
import { BASE_URL, comfnApiSvcCall, METHOD } from "@src/lib/common/comfn";
import { checkJwt, checkTime } from "@src/lib/util/Util";
import { useDispatch, useSelector } from "react-redux";
import {
  setBankList,
  setCardList,
  setBankHolidayYn,
} from "@store/modules/bankCardSlice";
import { setLockYn } from "@store/modules/payerNoSlice";
import { selectUserInfo } from "@src/store/modules/userSlice";

import meter01 from "@src/images/exam_meter_lock_y.png";
import meter02 from "@src/images/exam_meter_lock_n2.png";

const guides = [
  "아니오, 잠금 장치가 없습니다.",
  "네, 잠금 장치가 있습니다. (기사방문 필요)",
];

const SelfMoveInOutGuide = ({ history }) => {
  const userInfo = useSelector(selectUserInfo);
  const token = userInfo.accessToken;
  useEffect(() => {
    document.title = "삼천리 | 셀프 전입 안내";
    // 현재시간이 22시~6시 사이면 이용불가 페이지
    if (!checkTime()) {
      history.push("/ImpossiblePage");
      return;
    }

    //토큰이 있을 때
    if (token !== "") {
      //토큰이 유효하지 않을 때
      if (!checkJwt(userInfo.accessToken)) {
        history.push("/AccessTimeCheck");
        return;
      }
    } else {
      history.push("/Error404");
      return;
    }
    // window.history.pushState(null, document.title, window.location.href);
    // window.addEventListener("popstate", () => {
    //   window.history.pushState(null, document.title, window.location.href);
    // });
  }, []);

  const [checkedInputs, setCheckedInputs] = useState([]);
  const [checkedAgreementInputs, setCheckedAgreementInputs] = useState([]);
  const [meterLock, setMeterLock] = useState("checkbox20");
  const dispatch = useDispatch();

  const fnPg20 = () => {
    const option = {
      serviceId: "fnPg20",
      baseUrl: BASE_URL.COMMON,
      url: "/mvin/pg20",
      header: "accessToken",
      method: METHOD.POST,
      data: "",
      callback: fnCallback,
    };
    comfnApiSvcCall(option);
  };

  const fnCallback = (res) => {
    if (res.data.hasOwnProperty("rtnCode")) {
      if (res.data.rtnCode === "-999") {
        return;
      }
    }
    if (res.errorCode < 0) {
      //console.log("ERROR");
      alert(res.data.rtnMsg);
    } else if (res.serviceId === "fnPg20") {
      if (res.data.rtnCode === "0") {
        //console.log(res);
        //console.log(res.data.cardList);
        //console.log(res.data.bankList);
        // console.log(res.data.bankHolidayYn);
        //은행목록, 카드사 목록 저장
        dispatch(setCardList({ cardList: res.data.cardList }));
        dispatch(setBankList({ bankList: res.data.bankList }));
        dispatch(setBankHolidayYn({ bankHolidayYn: res.data.bankHolidayYn }));
        history.push("/SelfMvin/MVOT_07");
      } else {
        alert(res.data.rtnMsg);
      }
    }
  };

  const handleLockCheckbox = (e) => {
    const { id } = e.target;
    setMeterLock(id);
  };

  return (
    <div style={{ width: "100%", height: "100%", backgroundColor: "#f5f5f5" }}>
      <Header title={`셀프 전입 안내`} />

      <div id="container" className="container">
        <div className="tit_small">고객님, 셀프 전입은 이렇게 진행합니다.</div>
        <div className="section_radio" style={{ marginBottom: "20px" }}>
          <div className="stepMvinImage" />
        </div>

        <div className="section_radio">
          <div className="info03">가스 계량기에 잠금 장치가 되어 있습니까?</div>
          <hr />
          {guides.map((guide2, index) => {
            return (
              <div key={index}>
                <label htmlFor={`checkbox2${index}`} className="radio-wrap">
                  <div className="info04" style={{ fontSize: "15.5px" }}>
                    {guide2.includes("\n")
                      ? guide2.split("\n").map((guideSplit, index) => (
                          <span key={index}>
                            {guideSplit}
                            <br />
                          </span>
                        ))
                      : guide2}
                  </div>
                  <input
                    type="radio"
                    id={`checkbox2${index}`}
                    value={`checkbox2${index}`}
                    checked={meterLock === `checkbox2${index}`}
                    onChange={handleLockCheckbox}
                  />
                  <span class="checkmark"></span>
                </label>
              </div>
            );
          })}
          <div>
            <img src={meter01} alt="img" className="exam_meter1" />
            <img src={meter02} alt="img" className="exam_meter" />
          </div>
        </div>
      </div>

      <button
        type="button"
        className="btn btn_blue btn_big btn_bottom mt-3"
        onClick={() => {
          if (meterLock === "checkbox20") {
            dispatch(setLockYn("N"));
          } else if (meterLock === "checkbox21") {
            dispatch(setLockYn("Y"));
          }
          fnPg20();
        }}
      >
        확인
      </button>

      <div className="bottom_txt mt-1 pb-5">
        <div>
          <span className="gray">고객센터</span>{" "}
          <span className="space">
            <a href="tel:1544-3002">1544-3002</a>
          </span>
        </div>
        <div>
          <span className="gray">상담가능시간</span>
          <span>평일 09:00~18:00</span>
        </div>
      </div>
    </div>
  );
};

export default SelfMoveInOutGuide;
