import axios from "axios";
import * as Util from "@src/lib/util/Util";
// import * as AuthUtil from 'lib/util/AuthUtil';

//http method
export const METHOD = {
  GET: "GET",
  POST: "POST",
  PATCH: "PATCH",
  PUT: "PUT",
  DELETE: "DELETE",
};

//api base url
export const BASE_URL = {
  COMMON: String(process.env.REACT_APP_API_COMMON_URL),
};

const authorization =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtb2JObyI6IjAxMDEyMzQ1Njc4IiwidXNyTm0iOiLthYzsiqTthLAwMSIsInVzZXJfbmFtZSI6InRlc3RlcjAxIiwic2NvcGUiOlsicmVhZCJdLCJlbWFpbEFkZHIiOiJ0ZXN0ZXIwMUB3b29uZ2ppbi5jby5rciIsInVzcklkIjoidGVzdGVyMDEiLCJleHAiOjE2Mjc5ODMxMDQsImp0aSI6IjYyNzRiM2I1LTc5ZjktNGU0OC1hZWMwLTI5MmI3M2I5ZjAwOCIsImNsaWVudF9pZCI6InNhbWNodWxseSJ9.a7S_QNOHUN8lPTrKGgiGqep6sqrVavH8UQt7Je8UeV4";

/**************************************************
 * 함수
 *************************************************/

// api 서비스 호출
export function comfnApiSvcCall(props) {
  const jsonResult = JSON.parse(sessionStorage.getItem("persist:samchully"));
  // const session_accessToken = JSON.parse(jsonResult.userInfo).accessToken;

  const headers = {
    "Content-type": "application/json",
    Accept: "*/*",
  };

  if (props.hasOwnProperty("header")) {
    headers.Authorization =
      "Bearer " + JSON.parse(jsonResult.userInfo).accessToken;
  } else if (props.hasOwnProperty("accessToken")) {
    headers.Authorization = "Bearer " + props.accessToken;
  }

  if (props.method === "GET") {
    axios
      .get(props.baseUrl + props.url, { headers, params: props.data })
      .then(function (response) {
        const result = {
          serviceId: props.serviceId,
          data: response.data,
          status: response.data.rtn_code,
          message: response.data.rtn_msg,
          errorCode: response.data.rtn_code,
        };
        if (props.callback) {
          props.callback(result);
        }
      })
      .catch(function (error) {
        if (error.response) {
          const result = {
            serviceId: props.serviceId,
            data: error.response.data,
            status: error.response.status,
            errorCode: -1,
          };
          props.callback(result);
        } else if (error.request) {
          const result = {
            serviceId: props.serviceId,
            message: "ERROR",
            errorCode: -1,
          };
          props.callback(result);
        } else {
          const result = {
            serviceId: props.serviceId,
            message: error.message,
            errorCode: -1,
          };
          props.callback(result);
        }
      });
  } else if (props.method === "POST") {
    axios
      .post(props.baseUrl + props.url, props.data, { headers })
      .then(function (response) {
        const result = {
          serviceId: props.serviceId,
          data: response.data,
          status: response.data.rtn_code,
          message: response.data.rtn_msg,
          errorCode: response.data.rtn_code,
        };
        if (result.data.rtnCode === "-999") {
          alert(
            "서비스 진행 시 시스템 오류가 발생 되어, 서비스 진행이 불가하오니 고객센터 ☎1544-3002 로 연락주시기 바랍니다"
          );
        }
        if (props.callback) {
          props.callback(result);
        }
      })
      .catch(function (error) {
        if (error.response) {
          const result = {
            serviceId: props.serviceId,
            data: error.response.data,
            status: error.response.status,
            errorCode: -1,
          };
          if (result.data.rtnCode === "-999") {
            alert(
              "서비스 진행 시 시스템 오류가 발생 되어, 서비스 진행이 불가하오니 고객센터 ☎1544-3002 로 연락주시기 바랍니다"
            );
          }
          props.callback(result);
        } else if (error.request) {
          const result = {
            serviceId: props.serviceId,
            message: "ERROR",
            errorCode: -1,
          };
          props.callback(result);
        } else {
          const result = {
            serviceId: props.serviceId,
            message: error.message,
            errorCode: -1,
          };
          props.callback(result);
        }
      });
  } else if (props.method === "PUT") {
    axios
      .put(props.baseUrl + props.url, props.data, { headers })
      .then(function (response) {
        const result = {
          serviceId: props.serviceId,
          data: response.data,
          status: response.data.rtn_code,
          message: response.data.rtn_msg,
          errorCode: response.data.rtn_code,
        };
        if (props.callback) {
          props.callback(result);
        }
      })
      .catch(function (error) {
        if (error.response) {
          const result = {
            serviceId: props.serviceId,
            data: error.response.data,
            status: error.response.status,
            message: error.response.data.message,
            errorCode: -1,
          };
          props.callback(result);
        } else if (error.request) {
          const result = {
            serviceId: props.serviceId,
            message: "ERROR",
            errorCode: -1,
          };
          props.callback(result);
        } else {
          const result = {
            serviceId: props.serviceId,
            message: error.message,
            errorCode: -1,
          };
          props.callback(result);
        }
      });
  } else if (props.method === "PATCH") {
    axios
      .patch(props.baseUrl + props.url, props.data, { headers })
      .then(function (response) {
        const result = {
          serviceId: props.serviceId,
          data: response.data,
          status: response.data.rtn_code,
          message: response.data.rtn_msg,
          errorCode: response.data.rtn_code,
        };
        if (props.callback) {
          props.callback(result);
        }
      })
      .catch(function (error) {
        if (error.response) {
          const result = {
            serviceId: props.serviceId,
            data: error.response.data,
            status: error.response.status,
            message: error.response.data.message,
            errorCode: -1,
          };
          props.callback(result);
        } else if (error.request) {
          const result = {
            serviceId: props.serviceId,
            message: "ERROR",
            errorCode: -1,
          };
          props.callback(result);
        } else {
          const result = {
            serviceId: props.serviceId,
            message: error.message,
            errorCode: -1,
          };
          props.callback(result);
        }
      });
  } else if (props.method === "DELETE") {
    axios
      .delete(props.baseUrl + props.url, { headers, params: props.data })
      .then(function (response) {
        const result = {
          serviceId: props.serviceId,
          data: response.data,
          status: response.data.rtn_code,
          message: response.data.rtn_msg,
          errorCode: response.data.rtn_code,
        };
        if (props.callback) {
          props.callback(result);
        }
      })
      .catch(function (error) {
        if (error.response) {
          const result = {
            serviceId: props.serviceId,
            data: error.response.data,
            status: error.response.status,
            message: error.response.data.message,
            errorCode: -1,
          };
          props.callback(result);
        } else if (error.request) {
          const result = {
            serviceId: props.serviceId,
            message: "ERROR",
            errorCode: -1,
          };
          props.callback(result);
        } else {
          const result = {
            serviceId: props.serviceId,
            message: error.message,
            errorCode: -1,
          };
          props.callback(result);
        }
      });
  }
}

// api 서비스 호출
export function comfnApiSvcTokenCall(props) {
  const headers = {
    "Content-type": "application/json",
    Authorization: "Bearer " + authorization,
    Accept: "*/*",
  };

  if (props.method === "GET") {
    axios
      .get(props.baseUrl + props.url, { headers, params: props.data })
      .then(function (response) {
        const result = {
          serviceId: props.serviceId,
          data: response.data,
          status: response.data.rtn_code,
          message: response.data.rtn_msg,
          errorCode: response.data.rtn_code,
        };
        if (props.callback) {
          props.callback(result);
        }
      })
      .catch(function (error) {
        if (error.response) {
          const result = {
            serviceId: props.serviceId,
            data: error.response.data,
            status: error.response.status,
            errorCode: -1,
          };
          props.callback(result);
        } else if (error.request) {
          const result = {
            serviceId: props.serviceId,
            message: "ERROR",
            errorCode: -1,
          };
          props.callback(result);
        } else {
          const result = {
            serviceId: props.serviceId,
            message: error.message,
            errorCode: -1,
          };
          props.callback(result);
        }
      });
  } else if (props.method === "POST") {
    axios
      .post(props.baseUrl + props.url, props.data, { headers })
      .then(function (response) {
        const result = {
          serviceId: props.serviceId,
          data: response.data,
          status: response.data.rtn_code,
          message: response.data.rtn_msg,
          errorCode: response.data.rtn_code,
        };
        if (result.data.rtnCode === "-999") {
          alert(
            "서비스 진행 시 시스템 오류가 발생 되어, 서비스 진행이 불가하오니 고객센터 ☎1544-3002 로 연락주시기 바랍니다"
          );
        }
        if (props.callback) {
          props.callback(result);
        }
      })
      .catch(function (error) {
        if (error.response) {
          const result = {
            serviceId: props.serviceId,
            data: error.response.data,
            status: error.response.status,
            errorCode: -1,
          };
          props.callback(result);
        } else if (error.request) {
          const result = {
            serviceId: props.serviceId,
            message: "ERROR",
            errorCode: -1,
          };
          props.callback(result);
        } else {
          const result = {
            serviceId: props.serviceId,
            message: error.message,
            errorCode: -1,
          };
          props.callback(result);
        }
      });
  } else if (props.method === "PUT") {
    axios
      .put(props.baseUrl + props.url, props.data, { headers })
      .then(function (response) {
        const result = {
          serviceId: props.serviceId,
          data: response.data,
          status: response.data.rtn_code,
          message: response.data.rtn_msg,
          errorCode: response.data.rtn_code,
        };
        if (props.callback) {
          props.callback(result);
        }
      })
      .catch(function (error) {
        if (error.response) {
          const result = {
            serviceId: props.serviceId,
            data: error.response.data,
            status: error.response.status,
            message: error.response.data.message,
            errorCode: -1,
          };
          props.callback(result);
        } else if (error.request) {
          const result = {
            serviceId: props.serviceId,
            message: "ERROR",
            errorCode: -1,
          };
          props.callback(result);
        } else {
          const result = {
            serviceId: props.serviceId,
            message: error.message,
            errorCode: -1,
          };
          props.callback(result);
        }
      });
  } else if (props.method === "PATCH") {
    axios
      .patch(props.baseUrl + props.url, props.data, { headers })
      .then(function (response) {
        const result = {
          serviceId: props.serviceId,
          data: response.data,
          status: response.data.rtn_code,
          message: response.data.rtn_msg,
          errorCode: response.data.rtn_code,
        };
        if (props.callback) {
          props.callback(result);
        }
      })
      .catch(function (error) {
        if (error.response) {
          const result = {
            serviceId: props.serviceId,
            data: error.response.data,
            status: error.response.status,
            message: error.response.data.message,
            errorCode: -1,
          };
          props.callback(result);
        } else if (error.request) {
          const result = {
            serviceId: props.serviceId,
            message: "ERROR",
            errorCode: -1,
          };
          props.callback(result);
        } else {
          const result = {
            serviceId: props.serviceId,
            message: error.message,
            errorCode: -1,
          };
          props.callback(result);
        }
      });
  } else if (props.method === "DELETE") {
    axios
      .delete(props.baseUrl + props.url, { headers, params: props.data })
      .then(function (response) {
        const result = {
          serviceId: props.serviceId,
          data: response.data,
          status: response.data.rtn_code,
          message: response.data.rtn_msg,
          errorCode: response.data.rtn_code,
        };
        if (props.callback) {
          props.callback(result);
        }
      })
      .catch(function (error) {
        if (error.response) {
          const result = {
            serviceId: props.serviceId,
            data: error.response.data,
            status: error.response.status,
            message: error.response.data.message,
            errorCode: -1,
          };
          props.callback(result);
        } else if (error.request) {
          const result = {
            serviceId: props.serviceId,
            message: "ERROR",
            errorCode: -1,
          };
          props.callback(result);
        } else {
          const result = {
            serviceId: props.serviceId,
            message: error.message,
            errorCode: -1,
          };
          props.callback(result);
        }
      });
  }
}

export function comfnApiSvcImgCall(props) {
  const jsonResult = JSON.parse(sessionStorage.getItem("persist:samchully"));
  const session_accessToken = JSON.parse(jsonResult.userInfo).accessToken;

  const headers = {
    "Content-type": "multipart/form-data",
    Accept: "*/*",
    Authorization: "Bearer " + session_accessToken,
  };
  if (props.method === "POST") {
    // console.log(props.baseUrl + props.url, props.data, { headers });
    axios({
      method: "post",
      url: props.baseUrl + props.url,
      timeout: 1000 * 10,
      headers: {
        "Content-type": "multipart/form-data",
        Accept: "*/*",
      },
      data: props.data,
    })
      .then(function (response) {
        const result = {
          serviceId: props.serviceId,
          data: response.data,
          status: response.data.rtn_code,
          message: response.data.rtn_msg,
          errorCode: response.data.rtn_code,
        };
        if (props.callback) {
          props.callback(result);
        }
      })
      .catch(function (error) {
        if (error.response) {
          const result = {
            serviceId: props.serviceId,
            data: error.response.data,
            status: error.response.status,
            errorCode: -1,
          };
          //console.log(result);
          props.callback(result);
        } else if (error.request) {
          const result = {
            serviceId: props.serviceId,
            message: "ERROR",
            errorCode: -1,
          };
          //console.log(result);
          alert("다시 시도해주세요.");
          props.callback(result);
        } else {
          const result = {
            serviceId: props.serviceId,
            message: error.message,
            errorCode: -1,
          };
          //console.log(result);
          props.callback(result);
        }
      });
  }
}

export function comfnApiSvcMetImgCall(props) {
  const jsonResult = JSON.parse(sessionStorage.getItem("persist:samchully"));
  const session_accessToken = JSON.parse(jsonResult.userInfo).accessToken;

  const headers = {
    "Content-type": "multipart/form-data",
    Accept: "*/*",
    Authorization: "Bearer " + session_accessToken,
  };
  if (props.method === "POST") {
    axios({
      method: "post",
      url: props.baseUrl + props.url,
      timeout: 1000 * 10,
      headers: {
        "Content-type": "multipart/form-data",
        Accept: "*/*",
      },
      data: props.data,
    })
      .then(function (response) {
        const result = {
          serviceId: props.serviceId,
          data: response.data,
          status: response.data.rtn_code,
          message: response.data.rtn_msg,
          errorCode: response.data.rtn_code,
        };
        if (props.callback) {
          props.callback(result);
        }
      })
      .catch(function (error) {
        if (error.response) {
          const result = {
            serviceId: props.serviceId,
            data: error.response.data,
            status: error.response.status,
            errorCode: -1,
          };
          //console.log(result);
          props.callback(result);
        } else if (error.request) {
          const result = {
            serviceId: props.serviceId,
            message: "ERROR",
            errorCode: -1,
          };
          //console.log(result);
          alert("다시 시도해주세요.");
          props.callback(result);
        } else {
          const result = {
            serviceId: props.serviceId,
            message: error.message,
            errorCode: -1,
          };
          //console.log(result);
          props.callback(result);
        }
      });
  }
}
