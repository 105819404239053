import React, { useEffect, useState } from "react";
import AppHeader from "@components/common/AppHeader/AppHeader";
import { useForm } from "react-hook-form";
import NumberInput from "@components/common/NumberInput/NumberInput";
import { useDispatch, useSelector } from "react-redux";
import {
  selectUserInfo,
  setLoggedInApp,
  setLoggedOffApp,
} from "@store/modules/userSlice";
import { setVrsc } from "@store/modules/typeSlice";
import { selectType, setType, setExpiration } from "@store/modules/typeSlice";
import {
  checkJwt,
  todayDate,
  checkTime,
  maxLengthCheck,
  parseJwt,
  checkRefreshCookie,
} from "@src/lib/util/Util";
import { requestTokenByRefresh } from "@src/lib/util/AuthUtil";
import { LoadingSpinner } from "@src/components/common/LoadingSpinner";

import { BASE_URL, comfnApiSvcCall, METHOD } from "@src/lib/common/comfn";
import { setSELF_MVOT_03List } from "@src/store/modules/payerNoSlice";

const iNumber = ["010", "011", "016", "017", "018", "019"];

const VRSC_MVOT_01 = ({ history }) => {
  const userInfo = useSelector(selectUserInfo);
  const type = useSelector(selectType);
  const vrsc = type.vrsc;
  const date = todayDate();
  const dispatch = useDispatch();
  const token = userInfo.accessToken;

  useEffect(() => {
    document.title = "삼천리 | 계약자 인증";
    //history.push("/VrscMvot/MVOT_07");
    // 현재시간이 22시~6시 사이면 이용불가 페이지
    if (!checkTime()) {
      history.push("/ImpossiblePage");
      return;
    }

    //토큰이 ""가 아닐 때
    if (token !== "") {
      //토큰이 유효하지 않을 때
      if (!checkJwt(userInfo.accessToken)) {
        if (checkRefreshCookie()) {
          requestTokenByRefresh(({ status, data }) => {
            if (status === 200) {
              dispatch(setLoggedInApp(data));
            }
          });
        } else {
          dispatch(setExpiration(true));
          sessionStorage.clear();
          dispatch(setLoggedOffApp());
          history.push("/VrscMvot/APP_CM_02");
          return;
        }
      }
    }
    //토큰이 ""일 때
    else {
      if (
        navigator.userAgent.indexOf("wv") > -1 ||
        (navigator.userAgent.indexOf("iPhone") > -1 &&
          navigator.userAgent.indexOf("Safari") === -1)
      ) {
        dispatch(setExpiration(true));
        dispatch(setLoggedOffApp());
        sessionStorage.clear();
        history.push("/VrscMvot/APP_CM_02");
        return;
      } else {
        history.push("/Error404");
        return;
      }
    }

    // 토큰이 만료되었으면 404에러 페이지
    // if (!checkJwt(userInfo.accessToken)) {
    //   history.push("/Error404");
    //   return;
    // }
  }, []);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setError,
    clearErrors,
    setFocus,
    setValue,
  } = useForm();
  const [showAuthInput, setShowAuthInput] = useState(false);
  const [authTextAlert, setAuthTextAlert] = useState("");
  const [checkSendAgain, setCheckSendAgain] = useState(false);
  const [authNum, setAuthNum] = useState();
  const [id, setId] = useState("");
  const [userName, setUserName] = useState("");
  const [userPhoneNumber, setUserPhoneNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [authLoading, setAuthLoading] = useState(false);

  const watchAuthNum = watch("authNum");
  const watchName = watch("name");
  const watchPhoneStart = watch("phoneStart");
  const watchPhoneCenter = watch("phoneCenter");
  const watchPhoneEnd = watch("phoneEnd");

  //인증번호 전송:pg3 호출
  const sendAuthNum = (name, mobNo) => {
    let data = {
      reqTy: 2,
      name: name,
      mobNo: mobNo,
      thrdNm: parseJwt(userInfo.accessToken).usrNm,
      thrdMobNo: parseJwt(userInfo.accessToken).mobNo,
    };

    setUserName(watchName);
    setUserPhoneNumber(watchPhoneStart + watchPhoneCenter + watchPhoneEnd);

    const option = {
      serviceId: "pg03",
      baseUrl: BASE_URL.COMMON,
      url: "/mvio/pg03",
      method: METHOD.POST,
      data: data,
      callback: fnCallback,
    };
    setAuthLoading(true);
    comfnApiSvcCall(option);
  };

  const sendAllAuthInfo = () => {
    let reqTy = "";

    if (type["type"] === "out") reqTy = "MO";

    let data = {
      reqTy: reqTy,
      name: userName,
      mobNo: userPhoneNumber,
    };

    //console.log(data);

    const option = {
      serviceId: "pg01",
      baseUrl: BASE_URL.COMMON,
      url: "/mvio/pg01",
      method: METHOD.POST,
      data: data,
      callback: fnCallback,
    };
    setLoading(true);
    comfnApiSvcCall(option);
  };

  useEffect(() => {
    if (showAuthInput === true) {
      setShowAuthInput(false);
      setCheckSendAgain(false);
      setValue("authNum", "");
    }
  }, [watchName, watchPhoneStart, watchPhoneCenter, watchPhoneEnd]);

  const fnCallback = (res) => {
    setLoading(false);
    setAuthLoading(false);
    //console.log(res);
    if (res.data.hasOwnProperty("rtnCode")) {
      if (res.data.rtnCode === "-999") {
        return;
      }
    }
    if (res.errorCode < 0) {
      alert(res.data.rtnMsg);
    } else if (res.serviceId === "pg01") {
      if (res.data.list.length >= 1) {
        // history.push({
        //   pathname: "/VrscMvot/VRSC_MVOT_03",
        //   state: { payerInfo: res.data.list },
        // }); // 기존 이력 확인으로 이동
        dispatch(setSELF_MVOT_03List(res.data.list));
        history.push("/VrscMvot/VRSC_MVOT_03");
      } else {
        alert("계약자 정보가 없습니다.");
        setValue("name", "");
        setValue("phoneCenter", "");
        setValue("phoneEnd", "");
        setValue("authNum", "");
        setId("authNumSendBtn");
        setShowAuthInput(false);
        setCheckSendAgain(false);
      }
      //history.push("/VrscMvot/VRSC_MVOT_03");
    } else if (res.serviceId === "pg03") {
      //로직 작성
      if (res.data.rtnCode === "0") {
        //alert(res.data.chkNo); //배포시 수정필요
        setAuthNum(res.data.chkNo);

        if (checkSendAgain === false) {
          setShowAuthInput(true);
          setAuthTextAlert(
            "계약자에게 인증번호를 발송했습니다.\n계약자에게 인증번호를 확인 후 입력해 주세요."
          );
          setCheckSendAgain(true);
        } else {
          clearErrors("authNum");
          setAuthTextAlert("계약자에게 인증번호를 재전송 했습니다.");
        }
      } else {
        alert(res.data.rtnMsg);
      }
    }
  };

  const onSubmit = (data) => {
    //console.log(`form data : ${JSON.stringify(data)}`);

    if (id === "authNumSendBtn") {
      const mobNo = data["phoneStart"] + data["phoneCenter"] + data["phoneEnd"];
      sendAuthNum(data["name"], mobNo);
    } else {
      setAuthTextAlert("");
      if (!showAuthInput) alert("인증번호를 입력해 주세요.");
      else if (watchAuthNum + "" === "") {
        setError("authNum", {
          type: "no authNum",
        });
      } else if (authNum + "" !== watchAuthNum + "") {
        setError("authNum", {
          type: "invalid authNum",
        });
      } else {
        sendAllAuthInfo();
        // alert("계약자 인증 완료");
        // dispatch(setVrsc());
        // history.push("/VrscMvot/VRSC_MVOT_03");
      }
    }
  };

  const onClick = () => {
    if (errors.phoneCenter || errors.phoneEnd) {
      setAuthTextAlert("");
      return;
    } else if (errors.birth) {
      setAuthTextAlert("");
    } else if (errors.name) {
      setAuthTextAlert("");
    } else {
      sendAuthNum(
        watchName,
        watchPhoneStart + watchPhoneCenter + watchPhoneEnd
      );
    }
  };

  const onClickOk = () => {
    setId("authCompleteBtn");
  };

  const onClickSend = () => {
    setId("authNumSendBtn");
  };

  // phoneCenter input 값이 존재하고 길이가 4면, phoneEnd input 으로 focus
  useEffect(() => {
    if (watchPhoneCenter && watchPhoneCenter.length === 4) setFocus("phoneEnd");
  }, [watchPhoneCenter, setFocus]);

  return (
    <>
      <AppHeader title="계약자 인증" />

      <form onSubmit={handleSubmit(onSubmit)}>
        <div id="container" className="container">
          <p className="passreset_txt mb-0">
            전출 대행할 계약자의 동의가 필요합니다.
          </p>
          <p className="passreset_txt mt-0">계약자 정보를 입력하세요.</p>
          <div className="form-group">
            <label htmlFor="name">계약자 이름</label>
            <input
              type="text"
              className="form-control"
              id="name"
              placeholder="홍길동"
              {...register("name", { required: true })}
            />
            {errors.name && (
              <small className="form-text text_alert">
                이름을 확인해 주세요.
              </small>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="phone">휴대폰 번호</label>
            <div className="input-area">
              <select
                id="phoneStart"
                className="form-control phone"
                defaultValue="010"
                {...register("phoneStart")}
              >
                {iNumber.map((number, index) => (
                  <option value={number} key={index}>
                    {number}
                  </option>
                ))}
              </select>
              <span className="hyphen">&#45;</span>
              <NumberInput
                className="form-control phone"
                maxLength="4"
                onInput={maxLengthCheck}
                {...register("phoneCenter", {
                  required: true,
                  pattern: /^[0-9]{4}$/,
                })}
              />
              <span className="hyphen">&#45;</span>
              <NumberInput
                className="form-control phone"
                maxLength="4"
                onInput={maxLengthCheck}
                {...register("phoneEnd", {
                  required: true,
                  pattern: /^[0-9]{4}$/,
                })}
              />
            </div>
            {(errors.phoneCenter || errors.phoneEnd) && (
              <small className="form-text text_alert">
                휴대폰 번호를 확인해 주세요.
              </small>
            )}
          </div>
          {showAuthInput && (
            <div className="form-group authen">
              <label htmlFor="authNum">인증번호</label>
              <NumberInput
                id="authNum"
                className="form-control"
                placeholder="인증번호를 입력해 주세요."
                {...register("authNum")}
              />

              {!errors.authNum &&
                authTextAlert ===
                  "계약자에게 인증번호를 발송했습니다.\n계약자에게 인증번호를 확인 후 입력해 주세요." && (
                  <div>
                    <small
                      className="form-text text_alert"
                      style={{ color: "#08a600" }}
                    >
                      계약자에게 인증번호를 발송했습니다.
                    </small>
                    <small
                      className="form-text text_alert"
                      style={{ color: "#08a600" }}
                    >
                      계약자에게 인증번호를 확인 후 입력해 주세요.
                    </small>
                  </div>
                )}

              {!errors.authNum &&
                authTextAlert === "계약자에게 인증번호를 재전송 했습니다." && (
                  <small
                    className="form-text text_alert"
                    style={{ color: "#08a600" }}
                  >
                    {authTextAlert}
                  </small>
                )}

              {errors.authNum && errors.authNum.type === "invalid authNum" && (
                <small className="form-text text_alert">
                  인증번호가 일치하지 않습니다.
                </small>
              )}

              {/* {errors.authNum && errors.authNum.type === "required" && (
                <small className="form-text text_alert">
                  인증번호를 입력해 주세요.
                </small>
              )} */}
              {errors.authNum && errors.authNum.type === "no authNum" && (
                <small className="form-text text_alert">
                  인증번호를 입력해 주세요.
                </small>
              )}

              {errors.authNum &&
                errors.authNum.type === "invalid phoneNumber" && (
                  <small className="form-text text_alert">
                    {errors.authNum.message}
                  </small>
                )}
            </div>
          )}
          <button
            id="authNumSendBtn"
            type={showAuthInput ? "button" : "submit"}
            className="btn btn_white"
            {...(showAuthInput && { onClick: onClick })}
            {...(!showAuthInput && { onClick: onClickSend })}
            disabled={authLoading}
          >
            {authLoading ? (
              <LoadingSpinner />
            ) : showAuthInput ? (
              "인증번호 재전송"
            ) : (
              "인증번호 전송"
            )}
          </button>
        </div>

        <button
          id="authCompleteBtn"
          type="submit"
          className="btn btn_blue btn_big btn_bottom"
          style={{ marginTop: 20 }}
          onClick={onClickOk}
          disabled={loading}
        >
          {/* 확인 */}
          {loading ? <LoadingSpinner /> : "확인"}
        </button>
      </form>
    </>
  );
};

export default VRSC_MVOT_01;
