const ToggleSwitch = (props) => {
  const { item, isChecked, handleToggle } = props;

  return (
    <div className="switch" style={{ float: "right" }}>
      {item === "disCheck" ? (
        <div>
          <input
            type="checkbox"
            className="switch-checkbox"
            checked={isChecked}
            onChange={handleToggle}
            id="switch-input1" // label의 htmlFor에 걸어줄 id
          />
          <label
            className="switch-label"
            htmlFor="switch-input1" // input id를 걸어준다
            style={{ top: "-50px" }}
          >
            {isChecked ? (
              <span
                className="labelMsg"
                style={{
                  left: "17px",
                }}
              >
                예
              </span>
            ) : (
              <span
                className="labelMsg"
                style={{
                  left: "26px",
                }}
              >
                아니오
              </span>
            )}
            <div className="ball" />
          </label>
        </div>
      ) : (
        <div>
          <input
            type="checkbox"
            className="switch-checkbox"
            checked={isChecked}
            onChange={handleToggle}
            id="switch-input2" // label의 htmlFor에 걸어줄 id
          />
          <label
            className="switch-label"
            htmlFor="switch-input2" // input id를 걸어준다
          >
            <div className="ball" />
            {isChecked ? (
              <span
                className="labelMsg"
                style={{
                  left: "17px",
                }}
              >
                예
              </span>
            ) : (
              <span
                className="labelMsg"
                style={{
                  left: "26px",
                }}
              >
                아니오
              </span>
            )}
          </label>
        </div>
      )}
    </div>
  );
};

export default ToggleSwitch;
