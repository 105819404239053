import { selectMeterInfo } from "@src/store/modules/meterSlice";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const AppHeader = ({ title }) => {
  const meterInfo = useSelector(selectMeterInfo);
  const edditYn = meterInfo.edditYn;
  const onClickBack = () => {
    history.go(-1);
  };
  const history = useHistory();
  return (
    <div id="header">
      <div className="wrap_header app_main_toparea" style={{ padding: "0px" }}>
        {title === "전출 완료" ||
        title === "결제 오류 안내" ||
        title === "전출 완료 실패 안내" ||
        title === "주소 검색" ||
        title === "이용 불가 시간 안내" ||
        title === "접속 시간 초과" ? (
          ""
        ) : (
          <div style={{ padding: "15px" }} onClick={onClickBack}>
            <div className="previous" />
          </div>
        )}
        {title !== "주소 검색" ? (
          <h1 style={{ padding: "15px 15px 15px calc(3% + 15px)" }}>{title}</h1>
        ) : (
          <h1 style={{ padding: "8px 8px 8px calc(7% + 8px)" }}>{title}</h1>
        )}
        {title !== "주소 검색" ? (
          <div
            style={{ padding: "15px" }}
            onClick={() => history.push("/VrscMvot/APP_CM_02")}
          >
            <div className="go_home">
              <span>홈으로</span>
            </div>
          </div>
        ) : (
          <div style={{ padding: "8px 8px 10px 8px", marginBottom: "5px" }}>
            <div
              className="go_close"
              style={{ marginLeft: "5%" }}
              onClick={() => {
                window.opener.postMessage(
                  { message: { openPopup: true } },
                  "*"
                );
                window.close();
              }}
            ></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AppHeader;
