import React, { useEffect, useState } from "react";
import AppHeader from "@components/common/AppHeader/AppHeader";
import { Tab, Tabs } from "react-bootstrap";
import { useForm } from "react-hook-form";
import NumberInput from "@components/common/NumberInput/NumberInput";
import { setLoggedOffApp } from "@store/modules/userSlice";
import { BASE_URL, comfnApiSvcCall, METHOD } from "@src/lib/common/comfn";
import { useDispatch, useSelector } from "react-redux";
import { checkTime, parseJwt } from "@src/lib/util/Util";
import Cookies from "universal-cookie";
import { LoadingSpinner } from "@src/components/common/LoadingSpinner";
import { selectUserInfo } from "@store/modules/userSlice";
import { setExpiration } from "@src/store/modules/typeSlice";

const iNumber = ["010", "011", "016", "017", "018", "019"];
const iThrdTyName = ["부동산", "관리인", "기타"];

const MyPage = ({ history, location }) => {
  const appUserInfo = useSelector(selectUserInfo);
  const [accessToken, setAccessToken] = useState("");
  const [usrId, setUsrId] = useState("");
  const [loading, setLoading] = useState(false);
  const [pwdLoading, setPwdLoading] = useState(false);
  const [resData, setResData] = useState("");
  const [encodeData, setEncodeData] = useState("");
  const [w, setW] = useState("");
  const [checkOpen, setCheckOpen] = useState("");
  const [thrdTyCd, setThrdTyCd] = useState("");
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setFocus,
    getValues,
    setError,
    setValue,
    clearErrors,
  } = useForm();
  const [key, setKey] = useState("editMyInfo");
  const [id, setId] = useState();
  const [userInfo, setUserInfo] = useState({
    usrNm: "",
    ci: "",
    usrId: "",
    mobNo: "",
    brthYm: "",
    thrdTyCd: "",
    cmpNm: "", //회사명
    crNo: "", //사업자 번호
    jurirNo: "", //법인번호
    postCd: "", //우편번호
    baseAddr: "", //기본주소
    dtlAddr: "", //상세주소
    regDe: "", //회원가입일
  });

  const watchPhoneCenter = watch("phoneCenter");
  const watchUsrPw = watch("usrPw");
  const watchUsrPwNew = watch("usrPwNew");
  const watchCmpNm = watch("cmpNm");
  const watchCrNo = watch("crNo");
  const watchThrdTyCd = watch("thrdTyCd");
  const watchDtlAddr = watch("dtlAddr");
  const watchJurirNo = watch("jurirNo");

  window.setData = function (data) {
    setResData(data);
  };

  const convertDecode = (data) => {
    var str = data.replace(/%2B/g, "+");
    return str;
  };

  useEffect(() => {
    if (resData === "") {
      return;
    } else {
      w.close();
      setCheckOpen("ok");
    }
  }, [resData]);

  useEffect(() => {
    if (checkOpen === "ok") {
      //console.log(resData);
      setEncodeData("");
      setW("");
      setCheckOpen("");
      if (resData !== "") {
        if (resData.errorCode !== "") {
          alert("본인인증 실패했습니다.");
        } else {
          //console.log(convertDecode(resData.ci));
          //console.log(userInfo.ci);
          if (convertDecode(resData.ci) === userInfo.ci) {
            //휴대폰 번호 변경
            alert("휴대폰 번호를 변경 했습니다.");
            setValue("phoneStart", resData.mobNo.substr(0, 3));
            setValue("phoneCenter", resData.mobNo.substr(3, 4));
            setValue("phoneEnd", resData.mobNo.substr(7, 4));
          } else {
            //휴대폰 번호 변경 실패
            alert("가입자 휴대폰이 아닙니다. 다시 확인 후 이용해 주세요.");
          }
        }
      }
    }
  }, [checkOpen]);

  useEffect(() => {
    document.title = "삼천리 | 마이 페이지";
    // 현재시간이 22시~6시 사이면 이용불가 페이지
    if (!checkTime()) {
      history.push("/ImpossiblePage");
      return;
    }
    if (appUserInfo.accessToken !== "") {
      setUsrId(parseJwt(appUserInfo.accessToken).usrId);
      setAccessToken(appUserInfo.accessToken);
    } else {
      if (location.state.access_token !== "") {
        setUsrId(parseJwt(location.state.access_token).usrId);
        setAccessToken(location.state.access_token);
      }
    }
  }, []);

  useEffect(() => {
    if (usrId !== "" && accessToken !== "") {
      clearErrors("crNo");
      clearErrors("cmpNm");
      clearErrors("jurirNo");
      getUserInfo();
    }
  }, [usrId, accessToken]);

  const onSubmit = (info) => {
    // console.log(`form info : ${JSON.stringify(info)}`);
    if (!info.hasOwnProperty("usrPw")) {
      // if (id === "editMyInfoBtn") {
      let data = {
        ci: userInfo.ci,
        usrNm: userInfo.usrNm,
        mobNo: info.phoneStart + info.phoneCenter + info.phoneEnd,
        brthYm: userInfo.brthYm,
        usrId: userInfo.usrId,
        thrdTyCd: info.thrdTyCd,
        postCd: info.postCd,
        baseAddr: info.baseAddr,
        dtlAddr: info.dtlAddr,
        regDe: userInfo.regDe,
        cmpNm: info.thrdTyCd !== "3" ? info.cmpNm : "",
        crNo: info.thrdTyCd !== "3" ? info.crNo : "",
        jurirNo: info.thrdTyCd !== "3" ? info.jurirNo : "",
        thrdTyTxt: info.relationship,
      };
      const option = {
        serviceId: "pg29",
        baseUrl: BASE_URL.COMMON,
        url: "/mbr/pg29",
        header: "accessToken",
        method: METHOD.POST,
        data: data,
        callback: fnCallback,
      };
      setLoading(true);
      comfnApiSvcCall(option);
    } else {
      //비밀번호 변경
      let data = {
        usrId: usrId,
        usrPw: info.usrPw,
      };

      //console.log(data);

      const option = {
        serviceId: "pg40",
        baseUrl: BASE_URL.COMMON,
        url: "/mbr/pg40",
        header: "accessToken",
        method: METHOD.POST,
        data: data,
        callback: fnCallback,
      };
      setLoading(true);
      comfnApiSvcCall(option);

      // if (watchOldPassword !== "test") {
      //   setError("oldPassword");
      // } else {
      //   alert("비밀번호가 재설정 되었습니다.\n로그인 후 이용해 주세요.");
      // }
    }
  };

  const onClickLogOut = () => {
    if (cookies) {
      sessionStorage.clear();
      dispatch(setLoggedOffApp());
      sessionStorage.clear();
      history.replace("/VrscMvot/APP_CM_02");
      cookies.remove("refresh_token");
      // cookies.set("refresh_token", "", {
      //   sameSite: "strict",
      // });
      alert("로그아웃 되었습니다.");
      dispatch(setExpiration(false));
    } else {
      alert("로그아웃 도중 실패하였습니다.");
    }
  };

  const onClickEditMyInfo = () => {
    setId("editMyInfoBtn");
  };

  const onClickChangePassword = () => {
    setId("changePasswordBtn");
    clearErrors("crNo");
    clearErrors("cmpNm");
    clearErrors("jurirNo");
    clearErrors("postInfo");
  };

  const openAddressPopUp = () => {
    // window.open(
    //   "https://www.juso.go.kr/addrlink/addrMobileLinkUrl.do?confmKey=devU01TX0FVVEgyMDIxMDgyMzEwMzkzNTExMTU1ODg=&returnUrl=http://localhost:3000/VRSCMVOT/MBR_14",
    //   "",
    //   "fullscreen=yes, menubar=no, toolbar=no, titlebar=yes, scrollbar=no"
    // );
    window.open(
      process.env.REACT_APP_API_COMMON_URL + "/VrscMvot/FindAddress",
      "",
      "fullscreen=yes, menubar=no, toolbar=no, titlebar=yes, scrollbar=no"
    );
    window.scrollTo(0, 0);
    // window.open(
    //   "http://localhost:3000/VrscMvot/FindAddress",
    //   "",
    //   "fullscreen=yes, menubar=no, toolbar=no, titlebar=yes, scrollbar=no"
    // );
    // window.open(
    //   process.env.REACT_APP_API_COMMON_URL + "/VrscMvot/FindAddress",
    //   "",
    //   "height=" +
    //     (window.screen.height - 300) +
    //     ", width=" +
    //     window.screen.width +
    //     ", top=" +
    //     window.screen.height / 2
    // );
  };

  const getUserInfo = () => {
    let data = { usrId: parseJwt(accessToken).usrId };

    const option = {
      serviceId: "pg39",
      baseUrl: BASE_URL.COMMON,
      url: "/mbr/pg39",
      accessToken: accessToken,
      method: METHOD.POST,
      data: data,
      callback: fnCallback,
    };
    setLoading(true);
    comfnApiSvcCall(option);
  };

  const changePwd = () => {
    let data = {
      usrId: usrId,
      usrPwNew: watchUsrPwNew,
    };

    const option = {
      serviceId: "pg33",
      baseUrl: BASE_URL.COMMON,
      url: "/mbr/pg33",
      header: "accessToken",
      method: METHOD.POST,
      data: data,
      callback: fnCallback,
    };
    setPwdLoading(true);
    comfnApiSvcCall(option);
  };

  const onClickSecede = () => {
    if (window.confirm("회원 탈퇴하시겠습니까?")) {
      let data = {
        usrId: usrId,
      };

      const option = {
        serviceId: "pg30",
        baseUrl: BASE_URL.COMMON,
        url: "/mbr/pg30",
        header: "accessToken",
        method: METHOD.POST,
        data: data,
        callback: fnCallback,
      };
      setLoading(true);
      comfnApiSvcCall(option);
    }
  };

  const onClickReCerify = () => {
    window.scrollTo(0, 0);
    const option = {
      serviceId: "pg27",
      baseUrl: BASE_URL.COMMON,
      url: "/nice/pg27",
      method: METHOD.POST,
      callback: fnCallback,
    };
    comfnApiSvcCall(option);
  };

  const fnCallback = (res) => {
    //console.log(res);
    setLoading(false);
    setPwdLoading(false);
    if (res.data.hasOwnProperty("rtnCode")) {
      if (res.data.rtnCode === "-999") {
        return;
      }
    }
    if (res.serviceId === "pg39") {
      if (res.data.rtnCode === "0") {
        setUserInfo({
          usrNm: res.data.usrNm,
          usrId: res.data.usrId,
          mobNo: res.data.mobNo,
          brthYm: res.data.brthYm,
          thrdTyCd: res.data.thrdTyCd,
          thrdTyTxt: res.data.thrdTyTxt,
          cmpNm: res.data.cmpNm,
          crNo: res.data.crNo,
          jurirNo: res.data.jurirNo,
          postCd: res.data.postCd,
          baseAddr: res.data.baseAddr,
          dtlAddr: res.data.dtlAddr,
          regDe: res.data.regDe,
          ci: res.data.ci,
        });
        setValue("usrNm", res.data.usrNm);
        setValue("usrId", res.data.usrId);
        setValue("phoneStart", res.data.mobNo.substr(0, 3));
        setValue("phoneCenter", res.data.mobNo.substr(3, 4));
        setValue("phoneEnd", res.data.mobNo.substr(7, 4));
        setValue("thrdTyCd", res.data.thrdTyCd);

        if (res.data.thrdTyCd === "1") {
          setValue("cmpNm", res.data.cmpNm);
          setValue("crNo", res.data.crNo);
          setValue("jurirNo", res.data.jurirNo);
          setValue("relationship", "부동산");
        } else if (res.data.thrdTyCd === "2") {
          setValue("cmpNm", res.data.cmpNm);
          setValue("crNo", res.data.crNo);
          setValue("jurirNo", res.data.jurirNo);
          setValue("relationship", "관리인");
        } else if (res.data.thrdTyCd === "3") {
          setValue("relationship", res.data.thrdTyTxt);
          setValue("cmpNm", "");
          setValue("crNo", "");
          setValue("jurirNo", "");
        }

        setValue("postCd", res.data.postCd);
        setValue("baseAddr", res.data.baseAddr);
        setValue("dtlAddr", res.data.dtlAddr);
        clearErrors("crNo");
        clearErrors("cmpNm");
        clearErrors("jurirNo");
        clearErrors("relationship");
      } else {
        alert(res.data.rtnMsg);
        return;
      }
    } else if (res.serviceId === "pg30") {
      if (res.data.rtnCode === "0") {
        alert("탈퇴 완료되었습니다.");
        if (cookies) {
          sessionStorage.clear();
          dispatch(setLoggedOffApp());
          sessionStorage.clear();
          history.replace("/VrscMvot/APP_CM_02");
          cookies.remove("refresh_token");
          dispatch(setExpiration(false));
          // cookies.set("refresh_token", "", {
          //   sameSite: "strict",
          // });
        }
      } else {
        alert(res.data.rtnMsg);
        return;
      }
    } else if (res.serviceId === "pg40") {
      if (res.data.rtnCode === "0") {
        if (usrId === res.data.usrId) {
          changePwd();
        } else {
          alert("현재 비밀번호를 확인해 주세요.");
          return;
        }
      } else {
        alert(res.data.rtnMsg);
        return;
      }
    } else if (res.serviceId === "pg33") {
      if (res.data.rtnCode === "0") {
        alert("비밀번호가 재설정되었습니다. 로그인 후 이용해 주세요.");
        if (cookies) {
          sessionStorage.clear();
          dispatch(setLoggedOffApp());
          sessionStorage.clear();
          history.replace("/VrscMvot/APP_CM_02");
          cookies.remove("refresh_token");
          dispatch(setExpiration(false));
          // cookies.set("refresh_token", "", {
          //   sameSite: "strict",
          // });
        }
      } else {
        alert(res.data.rtnMsg);
        return;
      }
    } else if (res.serviceId === "pg29") {
      if (res.data.rtnCode === "0") {
        alert("수정 완료 되었습니다.");
        getUserInfo();
      } else {
        alert(res.data.rtnMsg);
        return;
      }
    } else if (res.serviceId === "pg27") {
      setEncodeData(res.data.encData);
    }
  };

  useEffect(() => {
    if (encodeData === "") {
      return;
    } else {
      setW(
        window.open(
          "",
          "popupChk",
          "fullscreen=yes, menubar=no, toolbar=no, titlebar=yes, scrollbar=no"
        )
      );
    }
  }, [encodeData]);

  useEffect(() => {
    if (w !== "") {
      document.form_chk.target = "popupChk";
      document.form_chk.action =
        "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
      document.form_chk.submit();
    }
  }, [w]);

  useEffect(() => {
    window.addEventListener("message", (e) => {
      //console.log(e.data);
      if (e.data.hasOwnProperty("message")) {
        //console.log("setvalue");
        if (e.data.message.hasOwnProperty("postCd")) {
          setValue("postCd", e.data.message.postCd);
          setValue("baseAddr", e.data.message.baseAddr);
          setValue("dtlAddr", e.data.message.dtlAddr);
        }
        if (e.data.message.hasOwnProperty("openPopup")) {
          if (e.data.message.openPopup === true) {
            window.scrollTo(0, document.body.scrollHeight);
          }
        }
      }
    });
  }, []);

  // useEffect(() => {
  //   window.addEventListener("message", onMessageReceived);
  //   return () => window.removeEventListener("message", onMessageReceived);
  // }, [onMessageReceived]);
  // useEffect(() => {
  //   console.log("state", abc);
  // }, [abc]);

  useEffect(() => {
    if (watchThrdTyCd === "3") {
      setThrdTyCd("3");
      if (userInfo.thrdTyCd === "3") {
        setValue("relationship", userInfo.thrdTyTxt);
      } else if (userInfo.thrdTyCd === "1" || userInfo.thrdTyCd === "2") {
        setValue("relationship", "");
      }
      clearErrors("cmpNm");
      clearErrors("crNo");
      clearErrors("jurirNo");
    } else if (watchThrdTyCd === "1") {
      setValue("relationship", "부동산");
      setThrdTyCd("1");
      clearErrors("relationship");
    } else if (watchThrdTyCd === "2") {
      setThrdTyCd("2");
      setValue("relationship", "관리인");
      clearErrors("relationship");
    }
  }, [watchThrdTyCd]);

  useEffect(() => {
    if (watchJurirNo !== undefined) {
      if (
        watchJurirNo.toString().length === 0 ||
        watchJurirNo.toString().length === 13
      ) {
        clearErrors("jurirNo");
      } else {
        setError("jurirNo");
      }
    }
  }, [watchJurirNo]);

  useEffect(() => {
    if (watchDtlAddr !== undefined) {
      if (watchDtlAddr.length !== 0) {
        clearErrors("postInfo");
      } else {
        setError("postInfo");
      }
    }
  }, [watchDtlAddr]);

  useEffect(() => {
    if (watchThrdTyCd !== "3") {
      if (watchCmpNm === "" || watchCmpNm === undefined) {
        setError("cmpNm");
      } else {
        clearErrors("cmpNm");
      }
      if ((watchCrNo + "").length !== 10 || watchCrNo === undefined) {
        setError("crNo");
      } else {
        clearErrors("crNo");
      }
    } else {
      clearErrors("cmpNm");
      clearErrors("crNo");
    }
  }, [watchCmpNm, watchCrNo]);

  return (
    <>
      <AppHeader title="마이 페이지" />

      <div className="container wrap_aotopayment">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Tabs
            id="myTab"
            activeKey={key}
            onSelect={(currentKey) => {
              setKey(currentKey);
              // 다른 탭 화면으로 이동할 때 input 값들 초기화
              if (key !== currentKey) {
                reset();
                if (currentKey === "editMyInfo") {
                  setThrdTyCd(userInfo.thrdTyCd);
                }
              }
            }}
          >
            <Tab title="개인정보 수정" eventKey="editMyInfo">
              {key === "editMyInfo" && (
                <>
                  <div className="form-group">
                    <label htmlFor="usrNm">이름</label>
                    <input
                      type="text"
                      className="form-control"
                      id="usrNm"
                      defaultValue={userInfo.usrNm}
                      disabled
                      {...register("usrNm")}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="usrId">아이디</label>
                    <input
                      type="text"
                      className="form-control"
                      id="usrId"
                      // placeholder="dbcdedf"
                      defaultValue={userInfo.usrId}
                      disabled
                      {...register("usrId")}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="phone">
                      휴대폰 번호 <span className="nec">(필수)</span>
                    </label>
                    <div className="input-area">
                      <select
                        id="phoneStart"
                        className="form-control phone"
                        {...register("phoneStart")}
                        readOnly
                      >
                        {iNumber.map((number, index) =>
                          userInfo.mobNo.substr(0, 3) === number ? (
                            <option
                              value={number}
                              key={index}
                              selected
                              disabled
                            >
                              {number}
                            </option>
                          ) : (
                            <option value={number} key={index} disabled>
                              {number}
                            </option>
                          )
                        )}
                      </select>
                      <span className="hyphen">&#45;</span>
                      <NumberInput
                        className="form-control phone"
                        defaultValue={userInfo.mobNo.substr(3, 4)}
                        maxLength="4"
                        {...register("phoneCenter", {})}
                        readOnly
                      />
                      <span className="hyphen">&#45;</span>
                      <NumberInput
                        className="form-control phone"
                        maxLength="4"
                        defaultValue={userInfo.mobNo.substr(7, 4)}
                        {...register("phoneEnd", {})}
                        readOnly
                      />
                    </div>
                    {(errors.phoneCenter || errors.phoneEnd) && (
                      <small className="form-text text_alert">
                        휴대폰 번호를 확인해 주세요.
                      </small>
                    )}
                  </div>
                  <button
                    type="button"
                    className="btn btn_white"
                    onClick={onClickReCerify}
                  >
                    재인증
                  </button>
                  <div className="form-group">
                    <label htmlFor="thrdTyCd">
                      계약자와의 관계 <span className="nec">(필수)</span>
                    </label>
                    <select className="form-control" {...register("thrdTyCd")}>
                      {/* <option value="default" disabled hidden>
                        선택해 주세요
                      </option> */}
                      {iThrdTyName.map((tyName, index) =>
                        userInfo.thrdTyCd === (index + 1).toString() ? (
                          <option
                            value={(index + 1).toString()}
                            key={(index + 1).toString()}
                            selected
                          >
                            {tyName}
                          </option>
                        ) : (
                          <option
                            value={(index + 1).toString()}
                            key={(index + 1).toString()}
                          >
                            {tyName}
                          </option>
                        )
                      )}
                    </select>
                    {/* {errors.relation && (
                      <small className="form-text text_alert">
                        계약자와의 관계를 선택해 주세요.
                      </small>
                    )} */}
                  </div>
                  <div></div>
                  {(watchThrdTyCd === "3" || thrdTyCd === "3") && (
                    <>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="relationship"
                          placeholder="(필수) 계약자와 관계를 직접 입력해 주세요."
                          defaultValue={
                            userInfo.thrdTyCd === "3" ? userInfo.thrdTyTxt : ""
                          }
                          {...register("relationship", { required: true })}
                        />
                        {errors.relationship && (
                          <small className="form-text text_alert">
                            계약자와의 관계를 입력해 주세요.
                          </small>
                        )}
                      </div>
                    </>
                  )}

                  {(watchThrdTyCd === "1" ||
                    watchThrdTyCd === "2" ||
                    thrdTyCd === "1" ||
                    thrdTyCd === "2") && (
                    <div>
                      {" "}
                      <div className="form-group">
                        <label htmlFor="cmpNm">
                          회사명 <span className="nec">(필수)</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="cmpNm"
                          defaultValue={userInfo.cmpNm}
                          {...register("cmpNm", { required: true })}
                        />
                        {errors.cmpNm && (
                          <small className="form-text text_alert">
                            회사명을 확인해 주세요.
                          </small>
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="crNo">
                          사업자번호 <span className="nec">(필수)</span>
                        </label>
                        <NumberInput
                          className="form-control"
                          id="crNo"
                          defaultValue={userInfo.crNo}
                          {...register("crNo", {
                            pattern: /^[0-9]{10}$/,
                            required: true,
                          })}
                        />

                        {errors.crNo && (
                          <small className="form-text text_alert">
                            사업자번호를 확인해 주세요.
                          </small>
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="jurirNo">법인번호 (선택)</label>
                        <NumberInput
                          className="form-control"
                          id="jurirNo"
                          defaultValue={userInfo.jurirNo}
                          {...register("jurirNo", {
                            pattern: /^[0-9]{13}$/,
                          })}
                        />

                        {errors.jurirNo && (
                          <small className="form-text text_alert">
                            법인번호를 확인해 주세요.
                          </small>
                        )}
                      </div>
                    </div>
                  )}

                  <div className="form-group">
                    <label htmlFor="relation">
                      주소 <span className="nec">(필수)</span>
                    </label>
                    <div className="zipcode_area row">
                      <div className="col-7 zip">
                        <NumberInput
                          className="form-control mb-2"
                          id="postCd"
                          placeholder="우편번호"
                          defaultValue={userInfo.postCd}
                          {...register("postCd")}
                          readOnly
                        />
                      </div>
                      <div className="col-5 btn_add">
                        <button
                          type="button"
                          className="btn btn_white address"
                          onClick={openAddressPopUp}
                        >
                          우편번호 찾기
                        </button>
                      </div>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      id="baseAddr"
                      defaultValue={userInfo.baseAddr}
                      placeholder="주소"
                      {...register("baseAddr")}
                      readOnly
                    />
                    <input
                      type="text"
                      className="form-control mt-2"
                      id="dtlAddr"
                      defaultValue={userInfo.dtlAddr}
                      placeholder="상세주소"
                      {...register("dtlAddr")}
                    />
                    {errors.postInfo && (
                      <small className="form-text text_alert">
                        주소를 확인해 주세요.
                      </small>
                    )}
                  </div>
                  <button
                    id="editMyInfoBtn"
                    type="submit"
                    className="btn btn_blue mt-2"
                    onClick={onClickEditMyInfo}
                    disabled={loading}
                  >
                    {loading ? <LoadingSpinner /> : "수정"}
                  </button>

                  <div className="logout_box">
                    <span onClick={onClickLogOut}>로그아웃</span>
                    <div className="stick" />
                    <span onClick={onClickSecede}>회원탈퇴</span>
                  </div>
                </>
              )}
            </Tab>
            <Tab title="비밀번호 변경" eventKey="changePassword">
              {key === "changePassword" && (
                <>
                  <p className="passreset_txt">
                    새로 사용할 비밀번호를 입력해 주세요.
                  </p>
                  <div className="form-group">
                    <label htmlFor="usrPw">현재 비밀번호</label>
                    <input
                      type="password"
                      className="form-control"
                      id="usrPw"
                      {...register("usrPw", {
                        required: true,
                      })}
                    />
                    {errors.usrPw && (
                      <small className="form-text text_alert">
                        현재 비밀번호를 확인해 주세요.
                      </small>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="usrPwNew">새로운 비밀번호</label>
                    <input
                      type="password"
                      className="form-control"
                      id="usrPwNew"
                      placeholder="영문+숫자+특수문자 조합 8자리 이상"
                      {...register("usrPwNew", {
                        required: true,
                        pattern:
                          /^(?=.*[a-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/,
                      })}
                    />
                    {errors.usrPwNew && (
                      <small className="form-text text_alert">
                        새로운 비밀번호를 확인해 주세요.
                      </small>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="usrPwNewChk">새로운 비밀번호 확인</label>
                    <input
                      type="password"
                      className="form-control"
                      id="usrPwNewChk"
                      placeholder="새로운 비밀번호와 동일하게 입력"
                      {...register("usrPwNewChk", {
                        required: true,
                        validate: (value) => {
                          const usrPwNew = getValues("usrPwNew");
                          return usrPwNew === value;
                        },
                      })}
                    />
                    {errors.usrPwNewChk && (
                      <small className="form-text text_alert">
                        새로운 비밀번호 확인 값을 확인해 주세요.
                      </small>
                    )}
                  </div>
                  <button
                    id="changePasswordBtn"
                    type="submit"
                    className="btn btn_blue mt-2"
                    onClick={onClickChangePassword}
                    disabled={pwdLoading}
                  >
                    {loading ? <LoadingSpinner /> : "비밀번호 변경"}
                  </button>
                </>
              )}
            </Tab>
          </Tabs>
        </form>
        <form name="form_chk" method="post">
          <input type="hidden" name="m" value="checkplusSerivce" />
          <input type="hidden" name="recvMethodType" value="get"></input>
          <input type="hidden" name="EncodeData" value={encodeData} />
        </form>
      </div>
    </>
  );
};

export default MyPage;
