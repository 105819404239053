import React, { useEffect } from "react";
import AppHeader from "@components/common/AppHeader/AppHeader";
import { useHistory } from "react-router-dom";
import { checkTime } from "@src/lib/util/Util";

const MBR_18 = () => {
  const history = useHistory();
  useEffect(() => {
    document.title = "삼천리 | 이용가이드";
    // 현재시간이 22시~6시 사이면 이용불가 페이지
    if (!checkTime()) {
      history.push("/ImpossiblePage");
      return;
    }
  }, []);
  return (
    <>
      <AppHeader title="이용가이드" />

      <div id="container" className="container usageguide">
        <div className="sequen_area">
          <div className="sequen_box" style={{ marginTop: "40px" }}>
            <span>STEP01</span>
          </div>
        </div>
        <div className="title">회원 가입하기</div>
        <div className="info_txt">본인 회원가입을 진행해 주세요.</div>
        <div className="phone step01" />

        <div className="sequen_area">
          <div className="sequen_box">
            <span>STEP02</span>
          </div>
        </div>
        <div className="title">계약자 인증하기</div>
        <div className="info_txt">
          계약자에게 인증번호를 전송하고,
          <br />
          전달받아 입력하세요.
        </div>
        <div className="phone step02" />

        <div className="sequen_area">
          <div className="sequen_box">
            <span>STEP03</span>
          </div>
        </div>
        <div className="title">가스렌지 사진 촬영</div>
        <div className="info_txt">
          계약자 세대의 가스렌지 사진을
          <br />
          촬영해 주세요.
        </div>
        <div className="phone step03" />

        <div className="sequen_area">
          <div className="sequen_box">
            <span>STEP04</span>
          </div>
        </div>
        <div className="title">계량기 사진 촬영</div>
        <div className="info_txt">
          계약자 세대의 계량기 사진을 촬영하면
          <br />
          납부할 요금을 알려 드려요.
        </div>
        <div className="phone step04" />

        <div className="sequen_area">
          <div className="sequen_box">
            <span>STEP05</span>
          </div>
        </div>
        <div className="title">납부 금액 확인</div>
        <div className="info_txt">납부 금액을 확인하세요.</div>
        <div className="phone step05" style={{ minHeight: "665px" }} />

        <div className="sequen_area">
          <div className="sequen_box">
            <span>STEP06</span>
          </div>
        </div>
        <div className="title">결제하기</div>
        <div className="info_txt">
          신용카드로 요금을 즉시 납부하실 수 있어요
          <br />
          (추후 실시간 계좌이체 서비스 제공 예정)
        </div>
        <div className="phone step06" style={{ minHeight: "560px" }} />

        <div className="sequen_area">
          <div className="sequen_box">
            <span>STEP07</span>
          </div>
        </div>
        <div className="title">셀프 전출 완료</div>
        <div className="info_txt">
          카카오톡으로 전출완료 알림을 받으셨나요?
          <br />
          수고하셨어요 &#58; &#41; 전출이 완료되었습니다.
        </div>
        <div className="phone step07" />
      </div>
    </>
  );
};

export default MBR_18;
