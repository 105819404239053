import React, { useEffect, useState } from "react";
import { Header } from "@components/common/Header";
import { useHistory } from "react-router-dom";
import { selectType } from "@store/modules/typeSlice";
import { selectUserInfo, setAccessToken } from "@store/modules/userSlice";
import { selectPayerNo } from "@store/modules/payerNoSlice";
import { useDispatch, useSelector } from "react-redux";
import { requestWebToken } from "../../lib/util/AuthUtil";
import { AppHeader } from "@src/components/common/AppHeader";

const AccessTimeCheck = () => {
  useEffect(() => {
    document.title = "삼천리 | 접속 시간 초과";
    // window.history.pushState(null, document.title, window.location.href);
    // window.addEventListener("popstate", () => {
    //   window.history.pushState(null, document.title, window.location.href);
    // });
  }, []);

  const allType = useSelector(selectType);
  const type = allType.type;
  const vrsc = allType.vrsc;
  const history = useHistory();
  const payerInfo = useSelector(selectPayerNo);
  const payerId = payerInfo.payerId;
  const userInfo = useSelector(selectUserInfo);
  const [realVrsc, setRealVrsc] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (vrsc) setRealVrsc(true);
    else if (navigator.userAgent.indexOf("wv") > -1) {
      setRealVrsc(true);
    } else if (
      navigator.userAgent.indexOf("iPhone") > -1 &&
      navigator.userAgent.indexOf("Safari") === -1
    ) {
      setRealVrsc(true);
    }
  }, []);

  //토큰 생성 요청, 서버 연동 후 수정 필요
  const fnGetToken = () => {
    let data = {
      req_ty: type === "in" ? "MI" : "MO",
      payp_no: payerInfo.payerId,
      mob_no: userInfo.mobNo,
      callback: callbackToken,
    };
    requestWebToken(data);
  };

  const callbackToken = ({ status, data, message }) => {
    if (status === 200) {
      dispatch(setAccessToken(data.access_token));
      //셀프 전출일 때
      if (type === "out" && !vrsc) {
        history.push("/SelfMvot/self_mvot_02");
      }
      //3자 전출일 때
      else if (type === "out" && vrsc) {
        history.push("/VrscMvot/VRSC_MVOT_02");
      }
      //셀프 전입일 때
      else if (type === "in") {
        history.push("/SelfMvin/self_mvin_02");
      }
    } else {
      alert("실패하였습니다.");
    }
  };

  const onClickButton = () => {
    // 납부자 번호가 있다면 전입/전출 안내페이지
    if (payerId !== "" && userInfo.mobNo !== "") {
      fnGetToken();
    }
    // 납부자 번호가 없다면 초기 본인인증 페이지
    else {
      //셀프 전출일 때
      if (type === "out" && !vrsc) {
        history.push("/SelfMvot");
      }
      //3자 전출일 때
      else if (type === "out" && vrsc) {
        history.push("/VrscMvot/vrsc_mvot_01");
      }
      //셀프 전입일 때
      else if (type === "in") {
        history.push("/SelfMvin");
      }
    }
  };

  return (
    <>
      {realVrsc ? (
        <AppHeader title="접속 시간 초과" />
      ) : (
        <Header title="접속 시간 초과" />
      )}

      <div id="container" className="container">
        <div className="section_error">
          <div className="icon_area3" />
          <p className="txt">안녕하세요, 삼천리입니다.</p>

          <p className="error_txt center">접속 시간을 초과하였습니다.</p>
          <p className="error_txt center">
            첫 페이지로 이동 후, 다시 시도 부탁 드립니다.
          </p>
        </div>
      </div>

      {(type === "out" && vrsc) ||
      (type === "out" && !vrsc) ||
      (type === "in" && !vrsc) ? (
        <button
          type="button"
          className="btn btn_blue btn_big btn_bottom mt-5"
          onClick={onClickButton}
        >
          첫 페이지로 이동
        </button>
      ) : (
        ""
      )}
    </>
  );
};

export default AccessTimeCheck;
