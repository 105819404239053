import React, { useEffect } from "react";
import { Header } from "@components/common/Header";
import AppHeader from "@components/common/AppHeader/AppHeader";
import { useHistory } from "react-router-dom";
import { selectType } from "@store/modules/typeSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectPayerNo } from "@src/store/modules/payerNoSlice";
import { setMVOT_02 } from "@src/store/modules/mvot02Slice";
import { checkTime } from "@src/lib/util/Util";

const MVOT_12 = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const type = useSelector(selectType);
  const vrsc = type.vrsc;
  const payerInfo = useSelector(selectPayerNo);

  useEffect(() => {
    document.title = "삼천리 | 결제 오류 안내";
    // window.history.pushState(null, document.title, window.location.href);
    // window.addEventListener("popstate", () => {
    //   window.history.pushState(null, document.title, window.location.href);
    // });
    if (!checkTime()) {
      history.push("/ImpossiblePage");
      return;
    }
  }, []);

  return (
    <>
      {vrsc ? (
        <AppHeader title="결제 오류 안내" />
      ) : (
        <Header title="결제 오류 안내" />
      )}

      <div id="container" className="container">
        <div className="section_error">
          <div className="icon_area2" />
          <p className="txt">
            죄송합니다.
            <br />
            결제 중 오류가 발생했습니다.
            <br />
            다시 시도 부탁 드립니다.
          </p>

          <p className="error_txt center" style={{ lineHeight: "normal" }}>
            고객센터: <a href="tel:1544-3002">1544-3002</a> (상담가능시간: 평일
            09:00~18:00)로 연락 부탁 드립니다.
          </p>
        </div>
      </div>

      <button
        type="button"
        className="btn btn_blue btn_big btn_bottom"
        onClick={() => {
          // vrsc
          //   ? history.push({
          //       pathname: "/VrscMvot/MVOT_02",
          //       state: { notiData: payerInfo },
          //     })
          //   : history.push({
          //       pathname: "/SelfMvot/MVOT_02",
          //       state: { notiData: payerInfo },
          //     });

          vrsc
            ? history.push("/VrscMvot/MVOT_02")
            : history.push("/SelfMvot/MVOT_02");
        }}
      >
        다시 결제 시도하기
      </button>
    </>
  );
};

export default MVOT_12;
