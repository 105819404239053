import axios from "axios";
import { clearTimeout } from "timers";
import Cookies from "universal-cookie";

const cookies = new Cookies();

let timeout;
const apiReqUrl =
  process.env.REACT_APP_API_COMMON_URL + "/scl-auth/oauth/token";
const headers = {
  "Content-type": "application/x-www-form-urlencoded",
  Authorization: "Basic c2FtY2h1bGx5OnNhbWNodWxseTEyIyQ=",
};

/**================================================
 * Access Token 요청
 *================================================ */
export function requestToken(param) {
  const data =
    "username=" +
    param.id +
    "&" +
    "password=" +
    param.password +
    "&grant_type=password";

  axios
    .post(apiReqUrl, data, { headers })
    .then(function (response) {
      //console.log(response.data);
      if (param.rememberMe) {
        cookies.set("refresh_token", response.data.refresh_token, {
          sameSite: "strict",
          // expires: new Date(Date.now() + 2592000000), 한달로 설정
          expires: new Date(Date.now() + 86400000),
        });
      }
      // if (param.saveId) {
      //   cookies.set("userId", param.id, {
      //     sameSite: "strict",
      //   });
      // }
      setToken(response.data).then(function () {
        param.callback({
          status: response.status,
          data: response.data,
          message: response.statusText,
        });
      });
    })
    .catch(function (error) {
      //console.log(error);

      try {
        param.callback({
          status: error.response.status,
          data: error.response.data,
          message: error.response.statusText,
        });
      } catch (e) {
        alert("실패하였습니다.");
      }
    });
}

export function requestWebToken(param) {
  const data =
    "payp_no=" +
    param.payp_no +
    "&" +
    "mob_no=" +
    param.mob_no +
    "&" +
    "req_ty=" +
    param.req_ty +
    "&grant_type=payp_no";

  axios
    .post(apiReqUrl, data, { headers })
    .then(function (response) {
      //console.log(response);
      setToken(response.data).then(function () {
        param.callback({
          status: response.status,
          data: response.data,
          message: response.statusText,
        });
      });
    })
    .catch(function (error) {
      //console.log(error);

      try {
        param.callback({
          status: error.response.status,
          data: error.response.data,
          message: error.response.statusText,
        });
      } catch (e) {
        alert("실패하였습니다.");
      }
    });
}

/**================================================
 * Access Token 로컬 변수, Access Token 갱신 타이머 설정
 *================================================ */
export const setToken = (data) => {
  return new Promise(function (resolve, reject) {
    // 글로벌 변수에 저장~~
    const expires = data.expires_in;
    let date = new Date();
    // 토큰 만료 5분전 갱신
    timeout = setTimeout(() => {
      requestTokenByRefresh();
    }, 1000 * (expires - 300));

    // // 스토어에 토큰 저장(TODO 최적화 필요)
    // const dispatch = useDispatch();
    // dispatch(setAccessToken(getToken()));

    // axios.defaults.headers.common["Authorization"] =
    //   "Bearer " + data.access_token;
    resolve();
  });
};

/**================================================
 * Access Token 갱신
 *================================================ */
export function requestAppTokenByRefresh(param) {
  const apiReqUrl =
    process.env.REACT_APP_API_COMMON_URL + "/scl-auth/oauth/token";
  const headers = {
    "Content-type": "application/x-www-form-urlencoded",
    Authorization: "Basic c2FtY2h1bGx5OnNhbWNodWxseTEyIyQ=",
  };
  const refreshToken = param.refreshToken;
  const data = "grant_type=refresh_token&refresh_token=" + refreshToken;
  //console.log(data);
  axios
    .post(apiReqUrl, data, { headers })
    .then(function (response) {
      //console.log(response);
      setToken(response.data).then(function () {
        param.callback({
          status: response.status,
          data: response.data,
          message: response.statusText,
        });
      });
    })
    .catch(function (error) {
      try {
        param.callback({
          status: error.response.status,
          data: error.response.data,
          message: error.response.statusText,
        });
      } catch (e) {}
    });
}

export function requestTokenByRefresh(callback) {
  const apiReqUrl =
    process.env.REACT_APP_API_COMMON_URL + "/scl-auth/oauth/token";
  const headers = {
    "Content-type": "application/x-www-form-urlencoded",
    Authorization: "Basic c2FtY2h1bGx5OnNhbWNodWxseTEyIyQ=",
  };
  const refreshToken = cookies.get("refresh_token");
  const data = "grant_type=refresh_token&refresh_token=" + refreshToken;
  //console.log(data);
  axios
    .post(apiReqUrl, data, { headers })
    .then(function (response) {
      setToken(response.data).then(function () {
        if (callback != null) {
          callback({
            status: response.status,
            data: response.data,
            message: response.statusText,
          });
        }
      });
    })
    .catch(function (error) {
      try {
        if (callback != null) {
          callback({
            status: error.response.status,
            data: error.response.data,
            message: error.response.statusText,
          });
        }
      } catch (e) {
        //console.log("access-token refresh fail..");
      }
    });
}
