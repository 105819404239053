import React, { useEffect, useState } from "react";
import Header from "@components/common/Header/Header";
import AppHeader from "@components/common/AppHeader/AppHeader";
import { selectPayerNo, setPayerNo } from "@store/modules/payerNoSlice";
import { selectType } from "@store/modules/typeSlice";
import { useSelector, useDispatch } from "react-redux";
import { requestWebToken } from "@src/lib/util/AuthUtil";
import { checkTime } from "@src/lib/util/Util";

import {
  selectUserInfo,
  setLoggedIn,
  setAccessToken,
  setUser,
} from "@store/modules/userSlice";
import { BASE_URL, comfnApiSvcCall, METHOD } from "@src/lib/common/comfn";

const SELF_MVOT_03 = ({ history }) => {
  const payerInfoAll = useSelector(selectPayerNo);
  const payerInfo = payerInfoAll.self_mvot_03_list;
  // const [payerInfo, setPayerInfo] = useState(
  //   location.state === undefined
  //     ? [
  //         {
  //           paypNo: "",
  //           custNo: "",
  //           name: "",
  //           brthYm: "",
  //           mobNo: "",
  //           iplNo: "",
  //           addr1: "",
  //           addr2: "",
  //         },
  //       ]
  //     : location.state.payerInfo
  // );
  // const payerInfo =
  //   location.state === undefined
  //     ? [
  //         {
  //           paypNo: "none",
  //           custNo: "",
  //           name: "",
  //           brthYm: "",
  //           mobNo: "",
  //           iplNo: "",
  //           addr1: "",
  //           addr2: "",
  //         },
  //       ]
  //     : location.state.payerInfo;
  const userInfo = useSelector(selectUserInfo);
  const type = useSelector(selectType);
  const vrsc = type.vrsc;
  const dispatch = useDispatch();
  const [selectedPayerInfo, setSelectedPayerInfo] = useState({
    paypNo: "",
    custNo: "",
    name: "",
    brthYm: "",
    mobNo: "",
    iplNo: "",
    addr1: "",
    addr2: "",
  });
  // const [payerInfo, setPayerInfo] = useState({payerId:'', name:'', address:''})

  useEffect(() => {
    document.title = "삼천리 | 전출 주소 선택";
    // 현재시간이 22시~6시 사이면 이용불가 페이지
    if (!checkTime()) {
      history.push("/ImpossiblePage");
      return;
    }

    if (payerInfo.length === 0) {
      history.push("/ErrorRedirect");
      return;
    }
    // window.history.pushState(null, document.title, window.location.href);
    // window.addEventListener("popstate", () => {
    //   window.history.pushState(null, document.title, window.location.href);
    // });
  }, []);

  //토큰 생성 요청
  const fnGetToken = (out) => {
    let data = {
      req_ty: out.req_ty,
      payp_no: out.payp_no,
      mob_no: out.mob_no,
      callback: callbackToken,
    };
    //console.log(data);
    requestWebToken(data);
  };

  const onClickButton = () => {
    //console.log(payerInfo);
    if (selectedPayerInfo.paypNo === "") {
      alert("납부자 번호를 선택해주세요.");
      return;
    } else {
      dispatch(
        setPayerNo({
          payerId: selectedPayerInfo.paypNo,
          custNo: selectedPayerInfo.custno,
          iplNo: selectedPayerInfo.iplNo,
          name: selectedPayerInfo.name,
          address: selectedPayerInfo.addr1,
          roadAddress: selectedPayerInfo.addr2,
        })
      );
      vrsc
        ? dispatch(
            setUser({
              usrNm: selectedPayerInfo.name,
              birth: selectedPayerInfo.brthYm,
              mobNo: selectedPayerInfo.mobNo,
            })
          )
        : dispatch(
            setLoggedIn({
              usrNm: selectedPayerInfo.name,
              birth: selectedPayerInfo.brthYm,
              mobNo: selectedPayerInfo.mobNo,
            })
          );

      if (!vrsc) {
        let out = {
          payp_no: selectedPayerInfo.paypNo,
          mob_no: selectedPayerInfo.mobNo,
          req_ty: "MO",
        };
        fnGetToken(out);
      } else {
        history.push("/VrscMvot/VRSC_MVOT_02");
      }
    }
  };

  const callbackToken = ({ status, data, message }) => {
    if (status === 200) {
      dispatch(setAccessToken(data.access_token));
      if (vrsc === true) {
        history.push("/VrscMvot/VRSC_MVOT_02");
      } else {
        history.push("/SelfMvot/SELF_MVOT_02");
      }
    } else {
      alert("실패하였습니다.");
    }
  };

  useEffect(() => {
    if (payerInfo.length !== 0) {
      setSelectedPayerInfo({
        paypNo: payerInfo[0].paypNo,
        custNo: payerInfo[0].custno,
        name: payerInfo[0].name,
        brthYm: payerInfo[0].brthYm,
        mobNo: payerInfo[0].mobNo,
        iplNo: payerInfo[0].iplNo,
        addr1: payerInfo[0].addr1,
        addr2: payerInfo[0].addr2,
      });
    }
  }, []);

  return (
    <>
      <div
        style={{ width: "100%", height: "100vh", backgroundColor: "#f5f5f5" }}
      >
        {vrsc ? (
          <AppHeader title="전출 주소 선택" />
        ) : (
          <Header title="전출 주소 선택" />
        )}

        <div id="container" className="container memnumber_select">
          <div className="tit_small">전출할 주소를 선택해 주세요.</div>
          {payerInfo.map((info, index) => (
            <div className="section_radio" key={index}>
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  defaultChecked={index === 0 ? true : false}
                  id={`customRadio${index}`}
                  name="customRadio"
                  value={info.paypNo}
                  className="custom-control-input"
                  onClick={(e) => {
                    // setSelectedPayerInfo(e.target.value);
                    setSelectedPayerInfo({
                      paypNo: info.paypNo,
                      custNo: info.custno,
                      name: info.name,
                      brthYm: info.brthYm,
                      mobNo: info.mobNo,
                      iplNo: info.iplNo,
                      addr1: info.addr1,
                      addr2: info.addr2,
                    });
                  }}
                />
                <label
                  className="custom-control-label"
                  htmlFor={`customRadio${index}`}
                >
                  <div className="info01">
                    납부자번호: <strong>{info.paypNo}</strong> &#47;&nbsp;
                    {info.name}
                  </div>
                  <div className="info02">{info.addr2}</div>
                </label>
              </div>
            </div>
          ))}
        </div>

        <button
          type="button"
          className="btn btn_blue btn_big btn_bottom "
          onClick={onClickButton}
        >
          선택
        </button>
      </div>
    </>
  );
};

export default SELF_MVOT_03;
