import React, { useEffect, useState } from "react";
import topLogo from "../../images/logo_top.png";
import {
  requestAppTokenByRefresh,
  requestTokenByRefresh,
} from "../../lib/util/AuthUtil";
import {
  setLoggedInApp,
  selectAccessToken,
  setLoggedOffApp,
  selectUserInfo,
  selectRefreshToken,
} from "@store/modules/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { checkTime, parseJwt } from "@src/lib/util/Util";
import Cookies from "universal-cookie";
import {
  setVrsc,
  setExpiration,
  selectExpiration,
} from "@store/modules/typeSlice";
import { setInitalNice } from "@src/store/modules/niceSlice";

// import { isNull } from "../../lib/util/Util";

const APP_CM_02 = ({ history, location }) => {
  const userInfo = useSelector(selectUserInfo);
  const refreshToken = useSelector(selectRefreshToken);
  const expiration = useSelector(selectExpiration).expiration;
  useEffect(() => {
    // console.log(parseJwt(userInfo.accessToken));
    document.title = "삼천리 | 메인";
    dispatch(setInitalNice());
    dispatch(setVrsc());

    const prop = {
      refreshToken: refreshToken,
      callback: callbackRefreshToken,
    };

    if (!expiration) {
      if (
        cookies.get("refresh_token") === undefined ||
        cookies.get("refresh_token") === ""
      ) {
        requestAppTokenByRefresh(prop);
      } else {
        requestTokenByRefresh(({ status, data }) => {
          if (status === 200) {
            // 자동 로그인을 선택한 경우, 쿠키에 저장된 refresh 토큰으로 로그인자동
            dispatch(setLoggedInApp(data));
            setCheckLogin(true);
          } else if (token !== "") {
            // 로그아웃을 하지 않은 상태 = 리덕스에 토큰이 저장되어있는 경우
            setCheckLogin(true);
          } else {
            setCheckLogin(false);
          }
        });
      }
    } else {
      setCheckLogin(false);
      cookies.remove("refresh_token");
      dispatch(setExpiration(false));
      dispatch(setLoggedOffApp());
      sessionStorage.clear();
    }

    // 현재시간이 22시~6시 사이면 이용불가 페이지
    if (!checkTime()) {
      history.push("/ImpossiblePage");
      return;
    }
  }, []);

  const callbackRefreshToken = ({ status, data, message }) => {
    if (status === 200) {
      // 스토어에 토큰 저장
      // dispatch(함수명(data));
      dispatch(setLoggedInApp(data));
      setCheckLogin(true);
    } else if (token !== "") {
      // 로그아웃을 하지 않은 상태 = 리덕스에 토큰이 저장되어있는 경우
      setCheckLogin(true);
    } else {
      setCheckLogin(false);
    }
  };

  const token = useSelector(selectAccessToken);
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const [checkLogin, setCheckLogin] = useState("");

  const onClickMvot = () => {
    if (checkLogin === true) {
      history.push("/VrscMvot/VRSC_MVOT_01");
    } else if (checkLogin === false) {
      history.push({
        pathname: "/VrscMvot/MBR_08",
        state: { page: "VRSC_MVOT_01" },
      });
    }

    // requestTokenByRefresh(({ status, data }) => {
    //   if (status === 200) {
    //     //console.log(data);
    //     dispatch(setLoggedInApp(data));
    //     // 자동 로그인을 선택한 경우, 쿠키에 저장된 refresh 토큰으로 로그인자동
    //     history.push("/VrscMvot/VRSC_MVOT_01");
    //   } else if (token !== "") {
    //     // 로그아웃을 하지 않은 상태 = 리덕스에 토큰이 저장되어있는 경우
    //     history.push("/VrscMvot/VRSC_MVOT_01");
    //   } else {
    //     // 로그인 페이지 이동
    //     history.push({
    //       pathname: "/VrscMvot/MBR_08",
    //       state: { page: "VRSC_MVOT_01" },
    //     });
    //   }
    // });
  };

  const onClickMyPage = () => {
    // 로그인이 되어있지 않으면 로그인 페이지로 이동

    if (checkLogin === true) {
      history.push("/VrscMvot/MBR_14");
    } else if (checkLogin === false) {
      history.push({
        pathname: "/VrscMvot/MBR_08",
        state: { page: "MBR_14" },
      });
    }
    // requestTokenByRefresh(({ status, data }) => {
    //   if (status === 200) {
    //     //console.log(data);
    //     dispatch(setLoggedInApp(data));
    //     history.push("/VrscMvot/MBR_14");
    //   } else if (token !== "") {
    //     history.push("/VrscMvot/MBR_14");
    //   } else {
    //     history.push({
    //       pathname: "/VrscMvot/MBR_08",
    //       state: { page: "MBR_14" },
    //     });
    //   }
    // });
  };

  const onClickLogout = () => {
    if (cookies) {
      dispatch(setLoggedOffApp());
      sessionStorage.clear();
      history.push("/VrscMvot/APP_CM_02");
      cookies.remove("refresh_token");
      // cookies.set("refresh_token", "", {
      //   sameSite: "strict",
      // });
      alert("로그아웃 되었습니다.");
      setCheckLogin(false);
      dispatch(setExpiration(false));
    } else {
      alert("로그아웃 도중 실패하였습니다.");
    }
  };

  return (
    <div id="container" className="container app_main">
      <div className="app_main_toparea">
        <div className="logo_area">
          <img src={topLogo} alt="삼천리" />
        </div>
        {/* <div
          className="go_home"
          onClick={() => history.replace("/VrscMvot/APP_CM_02")}
        >
          <span>홈으로</span>
        </div> */}
      </div>
      <div className="main_top_title">
        <div className="txt_hash">#미래를 생각하는 삼천리</div>
        <div className="title">간편하게! 직접!</div>
        <div className="title">
          <span>셀프 전출</span>하세요
        </div>
      </div>
      <div className="cards_area">
        <div className="card first" onClick={onClickMvot}>
          <div className="tit">셀프 전출 대행</div>
          <div className="txt">
            기사님 방문 없이
            <br />
            계량기만 촬영하면 완료!
          </div>
        </div>

        <div className="card second" onClick={onClickMyPage}>
          <div className="tit">마이페이지</div>
          <div className="txt">
            내 정보 관리를
            <br />
            편리하게 관리해 보세요.
          </div>
        </div>

        <div
          className="card third"
          onClick={() => history.push("/VrscMvot/MBR_18")}
        >
          <div className="tit">이용 가이드</div>
          <div className="txt">
            셀프 전출하는 방법을
            <br />
            쉽게 알려 드려요!
          </div>
        </div>
      </div>
      {checkLogin === true ? (
        <div className="link_txt" style={{ marginBottom: "37px" }}>
          <span
            className="linked"
            onClick={onClickLogout}
            style={{ marginBottom: "10px" }}
          >
            로그아웃
          </span>
        </div>
      ) : checkLogin === false ? (
        <div className="link_txt" style={{ marginBottom: "37px" }}>
          아직 회원이 아니신가요?
          <span
            className="linked"
            onClick={() => history.push("/VrscMvot/MBR_01")}
          >
            회원가입
          </span>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default APP_CM_02;
