import React, { useEffect, useState } from "react";
import Header from "@components/common/Header/Header";
import AppHeader from "@components/common/AppHeader/AppHeader";
import examShot01 from "../../images/exam_shot_01.png";
import { useForm } from "react-hook-form";
import NumberInput from "@components/common/NumberInput/NumberInput";
import {
  setOutMeter,
  setInMeter,
  selectMeterInfo,
} from "@store/modules/meterSlice";
import {
  setAddress,
  setIplNo,
  selectPayerNo,
  setMvio_07IplNo,
  setIsClickedBack,
  setIsReCal,
} from "@store/modules/payerNoSlice";
import { selectType } from "@store/modules/typeSlice";
import { useDispatch, useSelector } from "react-redux";
import { checkTime, checkJwt, maxLengthCheck } from "@src/lib/util/Util";
import { selectUserInfo } from "@store/modules/userSlice";
import { selectAiResult } from "@store/modules/aiResultSlice";
import { BASE_URL, METHOD, comfnApiSvcCall } from "../../lib/common/comfn";
import { LoadingSpinner } from "@src/components/common/LoadingSpinner";

const MVIO_07 = ({ history }) => {
  const userInfo = useSelector(selectUserInfo);
  const token = userInfo.accessToken;
  const payerInfo = useSelector(selectPayerNo);

  useEffect(() => {
    document.title = "삼천리 | 직접 입력";
    // 현재시간이 22시~6시 사이면 이용불가 페이지
    if (!checkTime()) {
      history.push("/ImpossiblePage");
      return;
    }

    //토큰이 있을 때
    if (token !== "") {
      //토큰이 유효하지 않을 때
      if (!checkJwt(userInfo.accessToken)) {
        history.push("/AccessTimeCheck");
        return;
      }
      //토큰이 유효할 때
      else {
        //리덕스 값 체크 (없으면 404)
        if (payerInfo.payerId === "" || payerInfo.payerId === undefined) {
          history.push("/Error404");
          return;
        }
      }
    } else {
      history.push("/Error404");
      return;
    }

    // window.history.pushState(null, document.title, window.location.href);
    // window.addEventListener("popstate", () => {
    //   window.history.pushState(null, document.title, window.location.href);
    // });
  }, []);

  const [loading, setLoading] = useState(false);
  const check = useSelector(selectType);
  const vrsc = check.vrsc;
  const type = check.type;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const typeName = type === "out" ? "전출" : "전입";
  const dispatch = useDispatch();
  const meterInfo = useSelector(selectMeterInfo);
  const aiResult = useSelector(selectAiResult);
  let meterNumber = "";
  let Indicator = "";

  const onSubmit = (data) => {
    //console.log(`form data : ${JSON.stringify(data)}`);
    //console.log(data);
    meterNumber = data.meterNum;
    Indicator = type === "out" ? data.outIndicator : data.inIndicator;
    checkmeter({ meterNum: meterNumber, indicator: Indicator });
    fnSendImagePath({
      reqTy: type === "out" ? "MO" : "MI",
      paypNo: payerInfo.payerId,
      msrNo: Indicator,
      regionGroup: "",
      notiNo: "",
      addr1: type === "out" ? payerInfo.address : null,
      mtrNo: meterNumber,
      imgInfo: {
        numEditYn: "Y",
        filePathNameGas: meterInfo.filePathGas,
        filePathName: aiResult.path,
        success: aiResult.returnMsg === "SUCCESS" ? "S" : "F",
        readMsrNo: aiResult.meterageNum,
        readMtrNo: aiResult.wareNum,
        tidnrLtX: aiResult.wareNumX0,
        tidnrRtX: aiResult.wareNumX1,
        tidnrLbX: aiResult.wareNumX3,
        tidnrRbX: aiResult.wareNumX2,
        valLtX: aiResult.meterageNumX0,
        valRtX: aiResult.meterageNumX1,
        valLbX: aiResult.meterageNumX3,
        valRbX: aiResult.meterageNumX2,
        tidnrLtY: aiResult.wareNumY0,
        tidnrRtY: aiResult.wareNumY1,
        tidnrLbY: aiResult.wareNumY3,
        tidnrRbY: aiResult.wareNumY2,
        valLtY: aiResult.meterageNumY0,
        valRtY: aiResult.meterageNumY1,
        valLbY: aiResult.meterageNumY3,
        valRbY: aiResult.meterageNumY2,
      },
    });
  };

  const fnCallback = (res) => {
    setLoading(false);
    if (res.data.hasOwnProperty("rtnCode")) {
      if (res.data.rtnCode === "-999") {
        return;
      }
    }
    //console.log(res);
    if (res.errorCode < 0) {
      if (res.serviceId === "fnPg15") {
        alert(res.data.rtnMsg);
      }
      //console.log(res);
      return;
    } else if (res.serviceId === "fnPg15") {
      //로직 작성
      if (type === "out") {
        dispatch(
          setOutMeter({ meterNum: meterNumber, outIndicator: Indicator })
        );
        dispatch(setMvio_07IplNo(res.data.iplNo));
      } else {
        dispatch(setInMeter({ meterNum: meterNumber, inIndicator: Indicator }));
        dispatch(setIplNo(res.data.iplNo));
      }
      dispatch(setAddress({ addr1: res.data.addr1, addr2: res.data.addr2 }));
      dispatch(setIsClickedBack(false));
      dispatch(setIsReCal(false));
      //console.log(res.data);
      vrsc
        ? history.push("/VrscMvot/MVOT_01")
        : // history.push({
        //     pathname: "/VrscMvot/MVOT_01",
        //     state: { iplNo: res.data.iplNo },
        //   })
        type === "out"
        ? history.push("/SelfMvot/MVOT_01")
        : // history.push({
          //     pathname: "/SelfMvot/MVOT_01",
          //     state: { iplNo: res.data.iplNo },
          //   })
          history.push("/SelfMvin/SELF_MVIN_04");
      // history.push({
      //     pathname: "/SelfMvin/SELF_MVIN_04",
      //     state: { iplNo: res.data.iplNo },
      //   });
    }
  };

  const fnSendImagePath = (pathData) => {
    //console.log(pathData);
    const option = {
      serviceId: "fnSendImagePath",
      baseUrl: BASE_URL.COMMON,
      url: "/mvio/pg07",
      header: "accessToken",
      method: METHOD.POST,
      data: pathData,
      callback: fnCallback,
    };
    setLoading(true);
    comfnApiSvcCall(option);
  };

  const checkmeter = (data) => {
    // {meterNum: meterNumber, indicator:Indicator}
    // 전입 가능여부 조회에 필요한 정보 추가 확인 필요
    const customInfo = {
      reqTy: type === "out" ? "MO" : "MI",
      mtrNo: data.meterNum,
      msrNo: data.indicator,
      chkTy: "1",
    };
    setLoading(true);
    fnPg15(customInfo);
  };

  const fnPg15 = (data) => {
    // 기물번호 유효성 조회
    const option = {
      serviceId: "fnPg15",
      baseUrl: BASE_URL.COMMON,
      url: "/mvio/pg15",
      method: METHOD.POST,
      header: "accessToken",
      data: data,
      callback: fnCallback,
    };
    setLoading(true);
    comfnApiSvcCall(option);
  };

  const numberOnly = (e) =>
    (e.target.value = e.target.value.replace(/[^0-9]/g, ""));

  return (
    <div style={{ width: "100%", height: "100vh", backgroundColor: "#f5f5f5" }}>
      {vrsc ? <AppHeader title="직접 입력" /> : <Header title="직접 입력" />}

      <div id="container" className="container wrap_white">
        <h3>계량기 번호 &#47; {typeName} 지침</h3>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <label htmlFor="meterNum">계량기 번호</label>
            <NumberInput
              id="meterNum"
              className="form-control"
              maxLength="12"
              onInput={maxLengthCheck} // 숫자만 입력 가능
              {...register("meterNum", {
                required: true,
                pattern: /^[0-9]{12}$/,
              })}
            />
            {errors.meterNum && (
              <small className="form-text text_alert">
                계량기 번호를 확인해 주세요.
              </small>
            )}
          </div>
          <div className="form-group guide">
            <label htmlFor={`${type}Indicator`}>{typeName} 지침</label>
            <NumberInput
              id={`${type}Indicator`}
              className="form-control"
              onInput={numberOnly} // 숫자만 입력 가능
              {...register(`${type}Indicator`, {
                required: true,
              })}
            />
            <div className="timer">
              m<sup>3</sup>
            </div>
            {errors[`${type}Indicator`] && (
              <small className="form-text text_alert">
                {typeName} 지침을 확인해 주세요.
              </small>
            )}
          </div>

          <button
            type="submit"
            disabled={loading}
            className="btn btn_blue mb-3"
          >
            {/* 확인 */}
            {loading ? <LoadingSpinner /> : "확인"}
          </button>
        </form>
      </div>

      <div className="container text-center">
        <div className="wrap_bottom" style={{ display: "inline-block" }}>
          <div style={{ textAlign: "left" }}>
            ⓘ <span className="txt">계량기 확인하는 방법</span>
          </div>
          <img src={examShot01} alt="" />
        </div>
      </div>
    </div>
  );
};

export default MVIO_07;
