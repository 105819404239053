import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BASE_URL, comfnApiSvcCall, METHOD } from "@src/lib/common/comfn";
import { selectPayerNo, setOrdNo } from "@src/store/modules/payerNoSlice";
import { LoadingSpinner } from "@src/components/common/LoadingSpinner";
import { selectUserInfo, setLoggedInApp } from "@src/store/modules/userSlice";
import { requestTokenByRefresh } from "@src/lib/util/AuthUtil";
import { checkJwt, checkTime } from "@src/lib/util/Util";
import { selecttMVOT_02 } from "@src/store/modules/mvot02Slice";
import { selectMeterInfo } from "@src/store/modules/meterSlice";
import { selectAiResult } from "@src/store/modules/aiResultSlice";

const MVOT_03 = ({ history }) => {
  const dispatch = useDispatch();

  // const moveOutPrice = {
  //   delivDate: "20210822",
  //   rolGb: "MO",
  //   qmNo: "000223456",
  //   usedCpcty: "              0.0000",
  //   baseAmt: "     10",
  //   usedAmt: "        0",
  //   vatAmt: "      10",
  //   addedAmt: "        0",
  //   notiAmt: "     10",
  //   npyAmt: "20",
  //   dmdAmt: "        0",
  //   dmdYm: "2021/08",
  // };
  // const [moveOutPrice, setMoveOutPrice] = useState(
  //   location.state === undefined
  //     ? {
  //         delivDate: "",
  //         rolGb: "",
  //         qmNo: "none",
  //         usedCpcty: "",
  //         baseAmt: "",
  //         usedAmt: "",
  //         vatAmt: "",
  //         addedAmt: "",
  //         notiAmt: "",
  //         npyAmt: "",
  //         dmdAmt: "",
  //         dmdYm: "",
  //       }
  //     : location.state.moveOutPrice
  // );
  const moveOutPrice = useSelector(selecttMVOT_02);
  // const moveOutPrice = location.state.moveOutPrice;
  const payerInfo = useSelector(selectPayerNo);
  const userInfo = useSelector(selectUserInfo);
  const meterInfo = useSelector(selectMeterInfo);
  const aiResInfo = useSelector(selectAiResult);
  const token = userInfo.accessToken;
  const [loading, setLoading] = useState(false);
  const [hourMinSec, setHourMinSec] = useState("");

  const getOrdNo = () => {
    // window.location.href = "http://localhost:3000/selfmvot";
    const nowDate = new Date();
    const hour =
      nowDate.getHours() < 10 ? "0" + nowDate.getHours() : nowDate.getHours();

    const min =
      nowDate.getMinutes() < 10
        ? "0" + nowDate.getMinutes()
        : nowDate.getMinutes();

    const sec =
      nowDate.getSeconds() < 10
        ? "0" + nowDate.getSeconds()
        : nowDate.getSeconds();

    setHourMinSec(hour.toString() + min.toString() + sec.toString());
    dispatch(
      setOrdNo(
        moveOutPrice.qmNo.toString() +
          hour.toString() +
          min.toString() +
          sec.toString()
      )
    );

    // let data = {
    //   payTyCd: "11",
    //   amt: 5,
    //   ordNo: "23456500003",
    //   paypNo: "23456500003",
    //   delivDate: "23456500003",
    //   rolGb: "23456500003",
    //   qmNo: "23456500003",
    // };
  };

  const getPGUrl = () => {
    let data = {
      payTyCd: payerInfo.payTyCd,
      amt: parseInt(moveOutPrice.dmdAmt.trim()),
      ordNo: moveOutPrice.qmNo + hourMinSec,
      paypNo: payerInfo.payerId,
      delivDate: moveOutPrice.delivDate,
      rolGb: moveOutPrice.rolGb,
      qmNo: moveOutPrice.qmNo,
    };

    const option = {
      serviceId: "pg12",
      baseUrl: BASE_URL.COMMON,
      url: "/pay/pg12",
      header: "accessToken",
      method: METHOD.POST,
      data: data,
      callback: fnCallback,
    };
    setLoading(true);
    comfnApiSvcCall(option);
  };

  const fnCallback = (res) => {
    setLoading(false);
    if (res.data.hasOwnProperty("rtnCode")) {
      if (res.data.rtnCode === "-999") {
        return;
      }
    }
    //console.log(res);
    if (res.errorCode === -1) {
      history.replace("/SelfMvot/MVOT_12");
      return;
    }
    if (res.serviceId === "pg12") {
      if (res.data.resMsg === "정상") {
        // window.location.replace(res.data.authPageUrl);
        // window.location.href = res.data.authPageUrl;
        if (
          navigator.userAgent.indexOf("iPhone") > -1 &&
          navigator.userAgent.indexOf("Safari") === -1
        ) {
          window.open(
            res.data.authPageUrl,
            "",
            "fullscreen=yes, menubar=no, toolbar=no, titlebar=yes, scrollbar=no"
          );
        } else {
          window.location.replace(res.data.authPageUrl);
        }
      }
      // } else {
      //   history.replace("/SelfMvot/MVOT_12");
      //   return;
      // }
    }
  };

  useEffect(() => {
    document.title = "삼천리 | 결제PG";
    if (!checkTime()) {
      history.push("/ImpossiblePage");
      return;
    }

    //토큰이 ""가 아닐 때
    if (token !== "") {
      //토큰이 유효하지 않을 때
      if (!checkJwt(userInfo.accessToken)) {
        history.push("/AccessTimeCheck");
        return;
      }
      //토큰이 유효할 때
      else {
        //리덕스 값 체크 (없으면 404)
        if (
          payerInfo.payTyCd === "" ||
          payerInfo.payerId === "" ||
          payerInfo.payTyCd === undefined ||
          payerInfo.payerId === undefined
        ) {
          history.push("/Error404");
          return;
        }
      }
    }
    //토큰이 ""일 때
    else {
      history.push("/Error404");
      return;
    }

    if (moveOutPrice.qmNo === "") {
      history.push("/ErrorRedirect");
      return;
    } else {
      getOrdNo();
    }
    // window.history.pushState(null, document.title, window.location.href);
    // window.addEventListener("popstate", () => {
    //   window.history.pushState(null, document.title, window.location.href);
    // });
  }, []);

  useEffect(() => {
    if (hourMinSec !== "") {
      const sessionInfo = {
        payerId: payerInfo.payerId,
        name: payerInfo.name,
        address: payerInfo.address,
        outIndicator: meterInfo.outIndicator,
        totalCost: meterInfo.totalCost,
        delivDate: moveOutPrice.delivDate,
        rolGb: moveOutPrice.rolGb,
        qmNo: moveOutPrice.qmNo,
        payTyCd: payerInfo.payTyCd,
        signStr: payerInfo.custSign,
        meterageNum: aiResInfo.meterageNum,
        mobNo: userInfo.mobNo,
        filePathMet: meterInfo.filePathMet,
        wareNum: aiResInfo.wareNum,
        edditYn: meterInfo.edditYn,
        ordNo: moveOutPrice.qmNo.toString() + hourMinSec,
        moveOutPrice: moveOutPrice,
        lastIndicator: meterInfo.lastIndicator,
      };
      window.sessionStorage.setItem("mvotFinish", JSON.stringify(sessionInfo));
      getPGUrl();
    }
  }, [hourMinSec]);

  return <></>;
};

export default MVOT_03;
