import React, { useEffect, useState } from "react";
import Header from "@components/common/Header/Header";
import AppHeader from "@components/common/AppHeader/AppHeader";
import { selectType, setExpiration } from "@store/modules/typeSlice";
import { useSelector, useDispatch } from "react-redux";
import AgreementModal from "@components/common/AgreementModal/AgreementModal";
import { BASE_URL, comfnApiSvcCall, METHOD } from "@src/lib/common/comfn";
import MVOT_03 from "./MVOT_03";
import { setOutMeter, selectMeterInfo } from "@store/modules/meterSlice";
import {
  checkTime,
  parseJwt,
  checkJwt,
  checkRefreshCookie,
} from "@src/lib/util/Util";
import {
  selectPayerNo,
  setPayTyCd,
  setPayerNo,
  setIsReCal,
  setIsClickedBack,
} from "@src/store/modules/payerNoSlice";
import {
  selectUserInfo,
  setLoggedInApp,
  setLoggedOffApp,
} from "@src/store/modules/userSlice";
import { LoadingSpinner } from "@src/components/common/LoadingSpinner";
import { selectAiResult } from "@src/store/modules/aiResultSlice";
import { requestTokenByRefresh } from "@src/lib/util/AuthUtil";
import {
  selecttMVOT_02,
  setMVOT_02payTyCd,
} from "@src/store/modules/mvot02Slice";

const MVOT_02 = ({ history }) => {
  const moveOutPrice = useSelector(selecttMVOT_02);
  // const [moveOutPrice, setMoveOutPrice] = useState(
  //   location.state === undefined
  //     ? {
  //         delivDate: "",
  //         rolGb: "",
  //         qmNo: "none",
  //         usedCpcty: "",
  //         baseAmt: "",
  //         usedAmt: "",
  //         vatAmt: "",
  //         addedAmt: "",
  //         notiAmt: "",
  //         npyAmt: "",
  //         dmdAmt: "",
  //         dmdYm: "",
  //       }
  //     : location.state.notiData
  // );
  // const moveOutPrice = {
  //   delivDate: "20210822",
  //   rolGb: "MO",
  //   qmNo: "000223456",
  //   usedCpcty: "              0.0000",
  //   baseAmt: "     10",
  //   usedAmt: "        0",
  //   vatAmt: "      10",
  //   addedAmt: "        0",
  //   notiAmt: "     10",
  //   npyAmt: "20",
  //   dmdAmt: "        0",
  //   dmdYm: "2021/08",
  // };
  const dispatch = useDispatch();
  const type = useSelector(selectType);
  const meterInfo = useSelector(selectMeterInfo);
  const payerInfo = useSelector(selectPayerNo);
  const userInfo = useSelector(selectUserInfo);
  const aiResInfo = useSelector(selectAiResult);
  const [calLoading, setCalLoading] = useState(false);
  const [payLoading, setPayLoading] = useState(false);
  const token = userInfo.accessToken;

  const vrsc = type.vrsc;
  const [paymentMethod, setPaymentMethod] = useState("");
  const [agreement, setAgreement] = useState({
    show: false,
    title: "",
    content: "",
  });
  const [checkedInput, setCheckedInput] = useState(false);

  const handleCheckbox = (e) => {
    const { id, checked } = e.target;
    //console.log(checked, id);
    setCheckedInput(checked);
  };

  const movePgPage = () => {
    dispatch(setMVOT_02payTyCd(paymentMethod));
    history.push("/SelfMvot/MVOT_03");
  };

  const onClickCalPrice = () => {
    let data = {
      paypNo: payerInfo.payerId,
      delivDate: moveOutPrice.delivDate,
      rolGb: moveOutPrice.rolGb,
      qmNo: moveOutPrice.qmNo,
    };

    const option = {
      serviceId: "pg46",
      baseUrl: BASE_URL.COMMON,
      url: "/mvot/pg46",
      header: "accessToken",
      method: METHOD.POST,
      data: data,
      callback: fnCallback,
    };
    setPayLoading(true);
    setCalLoading(true);
    comfnApiSvcCall(option);
  };

  //요금 0원일 때, 전출 완료 버튼 클릭
  const onClickFinishMvot = () => {
    let data = {
      delivDate: moveOutPrice.delivDate,
      rolGb: moveOutPrice.rolGb,
      qmNo: moveOutPrice.qmNo,
      paypNo: payerInfo.payerId,
      // signStr: payerInfo.custSign,
      dmdAmt: moveOutPrice.dmdAmt,
      name: payerInfo.name,
      lastMsrNo: meterInfo.outIndicator,
      readMsrNo: aiResInfo.meterageNum, //계량기 이미지 인식 지침
      mobNo: userInfo.mobNo,
      reqTy: vrsc ? 2 : 1,
      filePathName: meterInfo.filePathMet, //계량기 이미지 인식 사진 경로 o
      readMtrNo: aiResInfo.wareNum, //계량기 이미지 인식 사진 기물번호
      numEditYn: meterInfo.edditYn, //계량기 이미지인식 사진 지침 수정 여부 o
    };

    if (vrsc) {
      data.thrdNm = parseJwt(userInfo.accessToken).usrNm;
      data.thrdMobNo = parseJwt(userInfo.accessToken).mobNo;
      data.thrdTyVal = parseJwt(userInfo.accessToken).tvVal;
      data.thrdTyCd = parseJwt(userInfo.accessToken).tvCd;
    }

    const option = {
      serviceId: "pg10",
      baseUrl: BASE_URL.COMMON,
      url: "/mvot/pg10",
      header: "accessToken",
      method: METHOD.POST,
      data: data,
      callback: fnCallback,
    };
    setCalLoading(true);
    setPayLoading(true);
    comfnApiSvcCall(option);
  };

  useEffect(() => {
    document.title = "삼천리 | 전출 요금 청구서";

    vrsc
      ? history.push("/VrscMvot/MVOT_02")
      : history.push("/SelfMvot/MVOT_02");
    window.addEventListener("popstate", () => {
      vrsc
        ? history.push("/VrscMvot/MVOT_02")
        : history.push("/SelfMvot/MVOT_02");
    });

    if (moveOutPrice.qmNo === "") {
      history.push("/ErrorRedirect");
      return;
    }
    dispatch(
      setOutMeter({
        ...meterInfo,
        totalCost: parseInt(moveOutPrice.dmdAmt.trim()),
      })
    );
    setPaymentMethod("11");
    dispatch(setPayTyCd("11"));
    // 현재시간이 22시~6시 사이면 이용불가 페이지
    if (!checkTime()) {
      history.push("/ImpossiblePage");
      return;
    }

    //토큰이 ""가 아닐 때
    if (token !== "") {
      //토큰이 유효하지 않을 때
      if (!checkJwt(userInfo.accessToken)) {
        //3자 전출일 때 token refresh
        if (type.type === "out" && vrsc) {
          if (checkRefreshCookie()) {
            requestTokenByRefresh(({ status, data }) => {
              if (status === 200) {
                //console.log(data);
                dispatch(setLoggedInApp(data));
              }
            });
          } else {
            dispatch(setExpiration(true));
            sessionStorage.clear();
            dispatch(setLoggedOffApp());
            history.push("/VrscMvot/APP_CM_02");
            return;
          }
        }
        //3자 전출이 아닐 때 accessTimeCheck
        else {
          history.push("/AccessTimeCheck");
          return;
        }
      }
      //토큰이 유효할 때
      else {
        //리덕스 값 체크 (없으면 404)
        if (
          // payerInfo.custSign === "" ||
          payerInfo.payerId === "" ||
          payerInfo.name === "" ||
          userInfo.mobNo === "" ||
          // payerInfo.custSign === undefined ||
          payerInfo.payerId === undefined ||
          payerInfo.name === undefined ||
          userInfo.mobNo === undefined
        ) {
          history.push("/Error404");
          return;
        }
      }
    }
    //토큰이 ""일 때
    else {
      if (
        navigator.userAgent.indexOf("wv") > -1 ||
        (navigator.userAgent.indexOf("iPhone") > -1 &&
          navigator.userAgent.indexOf("Safari") === -1)
      ) {
        dispatch(setExpiration(true));
        sessionStorage.clear();
        dispatch(setLoggedOffApp());
        history.push("/VrscMvot/APP_CM_02");
        return;
      } else {
        history.push("/Error404");
        return;
      }
    }
    dispatch(setIsClickedBack(false));
  }, []);

  const fnCallback = (res) => {
    setPayLoading(false);
    if (res.data.hasOwnProperty("rtnCode")) {
      if (res.data.rtnCode === "-999") {
        return;
      }
    }

    if (res.serviceId === "pg10") {
      if (res.data.rtnCode === "0") {
        vrsc
          ? history.push("/VrscMvot/MVOT_04")
          : history.push("/SelfMvot/MVOT_04");
      } else {
        alert(res.data.rtnMsg);
      }
    } else if (res.serviceId === "pg46") {
      if (res.data.rtnCode === "0") {
        setCalLoading(false);
        setPayLoading(false);
        dispatch(setIsReCal(true));
        history.replace("/SelfMvot/MVIO_04");

        history.replace("/SelfMvot/MVIO_04");
      } else {
        alert(res.data.rtnMsg);
      }
    }
  };

  return (
    <>
      {vrsc ? (
        <AppHeader title="전출 요금 청구서" />
      ) : (
        <Header title="전출 요금 청구서" />
      )}
      <div>
        <div id="container" className="container">
          <div className="tit_sub2">
            {parseInt(moveOutPrice.dmdAmt.trim()) === 0
              ? "결제하실 금액이 없습니다."
              : "요금 확인 후 결제해 주세요."}
          </div>

          <div className="section_payment">
            <div className="tit_sub">요금합계</div>
            <div className="pay_sum">
              <span>
                {parseInt(moveOutPrice.dmdAmt.trim())
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </span>
              원
            </div>

            <hr />

            <div className="tit_sub">요금 내역</div>
            <table className="tbl_ver type_right">
              <tbody>
                <tr>
                  <th>&#45; 전월지침</th>
                  <td>
                    {meterInfo.lastIndicator} m<sup>3</sup>
                  </td>
                </tr>
                <tr>
                  <th>&#45; 전출 지침</th>
                  <td>
                    {meterInfo.outIndicator} m<sup>3</sup>
                  </td>
                </tr>
                <tr>
                  <th>&#45; 사용량</th>
                  <td>
                    {moveOutPrice.usedCpcty} m<sup>3</sup>
                  </td>
                </tr>
                <tr>
                  <th>&#45; 기본 요금</th>
                  <td>
                    {moveOutPrice.baseAmt
                      .trim()
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                    원
                  </td>
                </tr>
                <tr>
                  <th>&#45; 사용 요금</th>
                  <td>
                    {moveOutPrice.usedAmt
                      .trim()
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                    원
                  </td>
                </tr>
                <tr>
                  <th>&#45; 부가세</th>
                  <td>
                    {moveOutPrice.vatAmt
                      .trim()
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                    원
                  </td>
                </tr>
                <tr>
                  <th>&#45; 가산금</th>
                  <td>
                    {moveOutPrice.addedAmt
                      .trim()
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                    원
                  </td>
                </tr>
                <tr>
                  <th>&#45; 고지 금액</th>
                  <td>
                    {moveOutPrice.notiAmt
                      .trim()
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                    원
                  </td>
                </tr>
                <tr>
                  <th>&#45; 미납 금액</th>
                  <td>
                    {moveOutPrice.npyAmt
                      .trim()
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                    원
                  </td>
                </tr>
              </tbody>
            </table>

            <button
              type="button"
              className="btn btn_white mt-3 mb-1"
              onClick={onClickCalPrice}
              disabled={calLoading}
              style={{ marginBottom: 20 }}
            >
              {/* 전출완료 */}
              {calLoading ? <LoadingSpinner /> : "요금 재계산"}
            </button>
          </div>

          {/* {!(moveOutPrice.dmdAmt.trim() === "0") && (
            <div className="row btn-area mb-3">
              <div className="col-6 btn-lt">
                <button
                  type="button"
                  className={`btn ${
                    paymentMethod === "11" ? "btn_gray" : "btn_white"
                  }`}
                  onClick={() => {
                    setPaymentMethod("11");
                    dispatch(setPayTyCd("11"));
                  }}
                >
                  신용카드
                </button>
              </div>
              <div className="col-6 btn-rt">
                <button
                  type="button"
                  className={`btn ${
                    paymentMethod === "21" ? "btn_gray" : "btn_white"
                  }`}
                  onClick={() => {
                    setPaymentMethod("21");
                    dispatch(setPayTyCd("21"));
                  }}
                >
                  실시간 계좌이체
                </button>
              </div>
            </div>
          )} */}

          {parseInt(moveOutPrice.dmdAmt.trim()) === 0 && (
            <button
              type="button"
              className="btn btn_blue btn_big mt-2"
              onClick={onClickFinishMvot}
              disabled={payLoading}
              style={{ marginBottom: 20 }}
            >
              {/* 전출완료 */}
              {payLoading ? <LoadingSpinner /> : "전출완료"}
            </button>
          )}
        </div>

        {parseInt(moveOutPrice.dmdAmt.trim()) !== 0 && (
          <div className="section_agreeterms">
            <div className="container">
              <div className="section_radio">
                <div className="custom-control custom-radio">
                  <input
                    type="checkbox"
                    id="checkbox1"
                    className="custom-control-input"
                    checked={checkedInput}
                    onChange={handleCheckbox}
                    defaultChecked={checkedInput}
                    autoComplete="off"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="checkbox1"
                    style={{ fontSize: "14px", width: "80%" }}
                  >
                    요금정보 등의 제3자 제공에 관한 동의 (필수)
                  </label>
                  <div
                    className="linked_txt"
                    onClick={() =>
                      setAgreement({
                        show: true,
                        title: "요금정보 등의 제3자 제공에 관한 동의",
                        content:
                          "1. 성명, 전화번호, 관계, 요금정보, 고객전용입금계좌, 전출입, 안전점검 정보\n2. 건물주의 임대차 관리를 위한 사용자 관리\n3. 동의 철회 시 까지 활용",
                      })
                    }
                  >
                    내용보기
                  </div>
                </div>
              </div>

              <button
                type="button"
                className="btn btn_blue btn_big mt-4 mb-4"
                disabled={payLoading}
                onClick={() => {
                  if (paymentMethod === "") {
                    alert("결제 방법을 선택해 주세요.");
                    return;
                  } else if (!checkedInput) {
                    alert("약관에 동의해 주세요.");
                    return;
                  } else {
                    movePgPage();
                  }
                  // vrsc
                  //   ? history.push("/VrscMvot/MVOT_04")
                  //   : history.push("/SelfMvot/MVOT_04");
                }}
              >
                {payLoading ? <LoadingSpinner /> : "신용카드로 결제하기"}
              </button>
            </div>
          </div>
        )}

        <AgreementModal
          show={agreement.show}
          onHide={() =>
            setAgreement({
              show: false,
              title: "",
              content: "",
            })
          }
          title={agreement.title}
          content={agreement.content}
        />
      </div>
    </>
  );
};

export default MVOT_02;
