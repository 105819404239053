import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  type: 0,
  vrsc: false,
  kakao: false,
  expiration: false, //refresh Token 만료 후 갱신했는지에 대한 flag (true가 갱신)
};

export const typeSlice = createSlice({
  name: "type",
  initialState,
  reducers: {
    setType: (state, action) => {
      state.type = action.payload.type;
      state.vrsc = action.payload.vrsc;
    },
    setVrsc: (state) => {
      state.vrsc = true;
      state.type = "out";
    },
    setTypeInapp: (state, action) => {
      state.type = action.payload.type;
      state.vrsc = action.payload.vrsc;
      state.kakao = action.payload.kakao;
    },
    setInitalType: (state) => {
      state.vrsc = false;
      state.type = 0;
    },
    setKakao: (state) => {
      state.kakao = "inapp";
    },
    setExpiration: (state, action) => {
      state.expiration = action.payload;
    },
  },
});

//console.log("flagSlice 컴포넌트");
export const {
  setType,
  setVrsc,
  setInitalType,
  setKakao,
  setTypeInapp,
  setExpiration,
} = typeSlice.actions;
export const selectType = (state) => ({
  type: state.type.type,
  vrsc: state.type.vrsc,
  kakao: state.type.kakao,
});

export const selectExpiration = (state) => ({
  expiration: state.type.expiration,
});

export default typeSlice.reducer;
