import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  delivDate: "",
  rolGb: "",
  qmNo: "",
  usedCpcty: "",
  baseAmt: "",
  usedAmt: "",
  vatAmt: "",
  addedAmt: "",
  notiAmt: "",
  npyAmt: "",
  dmdAmt: "",
  dmdYm: "",
  payTyCd: "",
};

export const mvot02Slice = createSlice({
  name: "mvot02Info",
  initialState,
  reducers: {
    setMVOT_02: (state, action) => {
      state.delivDate = action.payload.delivDate;
      state.rolGb = action.payload.rolGb;
      state.qmNo = action.payload.qmNo;
      state.usedCpcty = action.payload.usedCpcty;
      state.baseAmt = action.payload.baseAmt;
      state.usedAmt = action.payload.usedAmt;
      state.vatAmt = action.payload.vatAmt;
      state.addedAmt = action.payload.addedAmt;
      state.notiAmt = action.payload.notiAmt;
      state.npyAmt = action.payload.npyAmt;
      state.dmdAmt = action.payload.dmdAmt;
      state.dmdYm = action.payload.dmdYm;
      state.payTyCd = action.payload.payTyCd;
    },
    setMVOT_02payTyCd: (state, action) => {
      state.payTyCd = action.payload;
    },
    setInitalMVOT_02: (state) => {
      state.delivDate = "";
      state.rolGb = "";
      state.qmNo = "";
      state.usedCpcty = "";
      state.baseAmt = "";
      state.usedAmt = "";
      state.vatAmt = "";
      state.addedAmt = "";
      state.notiAmt = "";
      state.npyAmt = "";
      state.dmdAmt = "";
      state.dmdYm = "";
      state.payTyCd = "";
    },
  },
});

export const { setMVOT_02, setMVOT_02payTyCd, setInitalMVOT_02 } =
  mvot02Slice.actions;

export const selecttMVOT_02 = (state) => ({
  delivDate: state.mvot02Info.delivDate,
  rolGb: state.mvot02Info.rolGb,
  qmNo: state.mvot02Info.qmNo,
  usedCpcty: state.mvot02Info.usedCpcty,
  baseAmt: state.mvot02Info.baseAmt,
  usedAmt: state.mvot02Info.usedAmt,
  vatAmt: state.mvot02Info.vatAmt,
  addedAmt: state.mvot02Info.addedAmt,
  notiAmt: state.mvot02Info.notiAmt,
  npyAmt: state.mvot02Info.npyAmt,
  dmdAmt: state.mvot02Info.dmdAmt,
  dmdYm: state.mvot02Info.dmdYm,
  payTyCd: state.mvot02Info.payTyCd,
});

export default mvot02Slice.reducer;
