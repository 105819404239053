import React, { useEffect, useState } from "react";
import { Header } from "@components/common/Header";
import { checkTime } from "@src/lib/util/Util";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { BASE_URL, METHOD, comfnApiSvcCall } from "../../../lib/common/comfn";
import { LoadingSpinner } from "@src/components/common/LoadingSpinner";
import { setNice } from "@src/store/modules/niceSlice";

const NiceButton = (props) => {
  const history = useHistory();
  const [resData, setResData] = useState("");
  const [encodeData, setEncodeData] = useState("");
  const [w, setW] = useState("");
  const [checkOpen, setCheckOpen] = useState("");
  const [loading, setLoading] = useState(false);
  const [decodedData, setDecodedData] = useState("");
  const dispatch = useDispatch();

  const convertDecode = (data) => {
    var str = data.replace(/%2B/g, "+");
    setDecodedData(str);
    return str;
  };

  window.setData = function (data) {
    setResData(data);
  };

  useEffect(() => {
    if (encodeData === "") {
      return;
    } else {
      setW(
        window.open(
          "",
          "popupChk",
          // "fullscreen=yes, menubar=no, toolbar=no, titlebar=yes, scrollbar=no",
          "width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, scrollbar=no, titlebar=yes, location=no"
        )
      );
    }
  }, [encodeData]);

  useEffect(() => {
    if (w !== "") {
      document.form_chk.target = "popupChk";
      document.form_chk.action =
        "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
      document.form_chk.submit();
    }
  }, [w]);

  useEffect(() => {
    if (resData === "") {
      return;
    } else {
      w.close();
      setCheckOpen("ok");
    }
  }, [resData]);

  useEffect(() => {
    if (checkOpen === "ok") {
      //console.log(resData);
      if (resData !== "") {
        if (resData.errorCode !== "") {
          // 본인인증 실패시
          alert("본인 인증을 실패했습니다. 다시 시도해 주세요.");
        } else {
          if (decodedData === "") {
            fnPg37({ ci: convertDecode(resData.ci) });
          } else {
            return;
          }
        }
      }
    }
  });

  const fnPg27 = () => {
    window.scrollTo(0, 0);
    const option = {
      serviceId: "fnPg27",
      baseUrl: BASE_URL.COMMON,
      url: "/nice/pg27",
      method: METHOD.POST,
      callback: fnCallback,
    };
    comfnApiSvcCall(option);
  };

  const fnPg37 = (data) => {
    const option = {
      serviceId: "fnPg37",
      baseUrl: BASE_URL.COMMON,
      url: "/mbr/pg37",
      method: METHOD.POST,
      data: data,
      callback: fnCallback,
    };
    setLoading(true);
    comfnApiSvcCall(option);
  };

  const fnCallback = (res) => {
    setLoading(false);
    //console.log(res);
    if (res.data.rtnCode !== "0") {
      //console.log("err");
      if (res.serviceId === "fnPg27") {
        setEncodeData(res.data.encData);
      }
    } else if (res.serviceId === "fnPg37") {
      if (props.name === "아이디 찾기") {
        if (res.data.usrId !== null) {
          // 아이디 찾기 성공
          // history.push({
          //   pathname: "/VrscMvot/MBR_10",
          //   state: { data: res.data },
          // });
          dispatch(
            setNice({
              createdDt: res.data.createdDt,
              usrId: res.data.usrId,
            })
          );
          history.push("/VrscMvot/MBR_10");
        } else {
          // 아이디 없는 경우
          history.push("/VrscMvot/MBR_22");
        }
      } else if (props.name === "다음") {
        if (res.data.usrId !== null) {
          // MBR_21페이지로 이동(이미 가입된 사용자)
          history.push("/VrscMvot/MBR_21");
        } else {
          // MBR_06으로 이동 (ci, 이름, 생년월일, 휴대폰번호 전달)
          history.push({
            pathname: "/VrscMvot/MBR_06",
            state: { data: resData },
          });
        }
      } else {
        if (res.data.usrId !== null) {
          // 본인인증 성공 후 가입된 아이디가 있는 경우
          // history.push({
          //   pathname: "/VrscMvot/MBR_12",
          //   state: { data: res.data },
          // });
          dispatch(setNice(res.data));
          history.push("/VrscMvot/MBR_12");
        } else {
          // 본인인증 성공했으나 해당 사용자가 없는 경우
          history.push("/VrscMvot/MBR_23");
        }
      }
    } else if (res.serviceId === "fnPg27") {
      setEncodeData(res.data.encData);
    }
  };

  return (
    <>
      <button
        type="button"
        className={props.cName}
        onClick={() => {
          if (props.agree) {
            //console.log(props.agree);
            alert("약관에 동의해 주세요.");
            return;
          }
          fnPg27();
        }}
        disabled={loading}
      >
        {loading ? <LoadingSpinner /> : props.name}
      </button>
      <form name="form_chk" method="post">
        <input type="hidden" name="m" value="checkplusSerivce" />
        <input type="hidden" name="recvMethodType" value="get"></input>
        <input type="hidden" name="EncodeData" value={encodeData} />
      </form>
    </>
  );
};

export default NiceButton;
