import React, { useEffect } from "react";
import { Header } from "@components/common/Header";
import { useHistory } from "react-router-dom";

const Error500 = () => {
  useEffect(() => {
    document.title = "삼천리 | 서버 오류 안내";
  }, []);

  const history = useHistory();
  return (
    <>
      <Header title="오류 안내" />

      <div id="container" className="container">
        <div className="section_error">
          <div className="icon_area2" />
          <p className="txt">서비스에 접속할 수 없습니다.</p>

          <p className="error_txt center">죄송합니다. 기술적인 문제로 </p>
          <p className="error_txt center">일시적으로 접속되지 않았습니다.</p>
          <p className="error_txt center">잠시 후 다시 이용 부탁 드리며</p>
          <p className="error_txt center">이용에 불편을 드려서 죄송합니다.</p>
        </div>
      </div>

      <button
        type="button"
        className="btn btn_blue btn_big btn_bottom"
        onClick={() => history.goBack()}
      >
        이전 페이지로 가기
      </button>
    </>
  );
};

export default Error500;
