import React, { useEffect } from "react";
import { Header } from "@components/common/Header";
import AppHeader from "@components/common/AppHeader/AppHeader";
import { useHistory } from "react-router-dom";
import { selectType } from "@store/modules/typeSlice";
import { useSelector } from "react-redux";
import { selectPayerNo } from "@src/store/modules/payerNoSlice";
import { checkTime } from "@src/lib/util/Util";

const MVOT_11 = () => {
  const history = useHistory();
  const type = useSelector(selectType);
  const vrsc = type.vrsc;
  const payerInfo = useSelector(selectPayerNo);

  useEffect(() => {
    document.title = "삼천리 | 전출 완료 실패 안내";
    // window.history.pushState(null, document.title, window.location.href);
    // window.addEventListener("popstate", () => {
    //   window.history.pushState(null, document.title, window.location.href);
    // });
    if (!checkTime()) {
      history.push("/ImpossiblePage");
      return;
    }
  }, []);

  return (
    <>
      {vrsc ? (
        <AppHeader title="전출 완료 실패 안내" />
      ) : (
        <Header title="전출 완료 실패 안내" />
      )}

      <div id="container" className="container">
        <div className="section_error">
          <div className="icon_area2" />
          <p className="txt">
            전출 완료 처리 중 오류가 발생했습니다.
            <br />
            죄송하지만, 고객센터로 연락 부탁 드립니다.
          </p>

          <p className="error_txt center" style={{ lineHeight: "normal" }}>
            고객센터: <a href="tel:1544-3002">1544-3002</a> (상담가능시간: 평일
            09:00~18:00)로 연락 부탁 드립니다.
          </p>
        </div>
        {type.kakao === "inapp" ? (
          <button
            type="submit"
            className="btn btn_blue btn_big"
            onClick={() => {
              sessionStorage.clear();
              if (navigator.userAgent.includes("iPhone")) {
                // 카카오 인앱 아이폰의 경우 닫기
                // window.location.href = "kakaoweb://closeBrowser";
                window.location.href = process.env.REACT_APP_KAKAO_SELFMOVE;
              } else {
                // 카카오 인앱에서 안드로이드로 접근했던 경우
                // window.location.href = "http://pf.kakao.com/_dDxmzu/chat";
                window.location.href = process.env.REACT_APP_KAKAO_SELFMOVE;
              }
            }}
          >
            확인
          </button>
        ) : vrsc ? (
          // 제 3자의 경우 메인페이지로 이동
          <button
            type="submit"
            className="btn btn_blue btn_big"
            onClick={() => {
              history.push("/VrscMvot/APP_CM_02");
            }}
          >
            확인
          </button>
        ) : // 그 외에 버튼 없음
        null}
      </div>
    </>
  );
};

export default MVOT_11;
