import React, { useState, useEffect } from "react";
import Header from "@components/common/Header/Header";
import AppHeader from "@components/common/AppHeader/AppHeader";
import { Modal } from "react-bootstrap";
import {
  selectPayerNo,
  setIsClickedBack,
  setIsReCal,
  setPayerNo,
  setPayerOrder,
} from "@store/modules/payerNoSlice";
import { selectType, setExpiration } from "@store/modules/typeSlice";
import { useDispatch, useSelector } from "react-redux";
import { setLastIndicator, selectMeterInfo } from "@store/modules/meterSlice";
import {
  todayDate,
  checkTime,
  checkJwt,
  parseJwt,
  checkRefreshCookie,
} from "@src/lib/util/Util";
import { BASE_URL, comfnApiSvcCall, METHOD } from "@src/lib/common/comfn";
import {
  selectUserInfo,
  setLoggedIn,
  setLoggedInApp,
  setLoggedOffApp,
} from "@store/modules/userSlice";
import { LoadingSpinner } from "@src/components/common/LoadingSpinner";
import { requestTokenByRefresh } from "@src/lib/util/AuthUtil";
import { setMVOT_02 } from "@src/store/modules/mvot02Slice";

const MVOT_01 = ({ history }) => {
  //({ history, location }) => {
  const [loading, setLoading] = useState(false);
  const type = useSelector(selectType);
  const vrsc = type.vrsc;
  const kakao = type.kakao;
  const [show, setShow] = useState(false);
  const payerInfo = useSelector(selectPayerNo);
  const meterInfo = useSelector(selectMeterInfo);
  const date = todayDate();
  const dispatch = useDispatch();
  const userInfo = useSelector(selectUserInfo);
  const [reason1, setReason1] = useState(false);
  const [reason3Message, setReason3Message] = useState("");
  const token = userInfo.accessToken;

  useEffect(() => {
    document.title = "삼천리 | 전출 내용 확인";
    // 현재시간이 22시~6시 사이면 이용불가 페이지
    if (!checkTime()) {
      history.push("/ImpossiblePage");
      return;
    }

    //토큰이 ""가 아닐 때
    if (token !== "") {
      //토큰이 유효하지 않을 때
      if (!checkJwt(userInfo.accessToken)) {
        //3자 전출일 때 token refresh
        if (type.type === "out" && vrsc) {
          if (checkRefreshCookie()) {
            requestTokenByRefresh(({ status, data }) => {
              if (status === 200) {
                //console.log(data);
                dispatch(setLoggedInApp(data));
              }
            });
          } else {
            dispatch(setExpiration(true));
            sessionStorage.clear();
            dispatch(setLoggedOffApp());
            history.push("/VrscMvot/APP_CM_02");
            return;
          }
        }
        //3자 전출이 아닐 때 accessTimeCheck
        else {
          history.push("/AccessTimeCheck");
          return;
        }
      }
      //토큰이 유효할 때
      else {
        //리덕스 값 체크 (없으면 404)
        if (
          payerInfo.iplNo === "" ||
          payerInfo.payerId === "" ||
          payerInfo.name === "" ||
          payerInfo.address === "" ||
          meterInfo.meterNum === "" ||
          meterInfo.outIndicator === "" ||
          userInfo.mobNo === "" ||
          payerInfo.iplNo === undefined ||
          payerInfo.payerId === undefined ||
          payerInfo.name === undefined ||
          payerInfo.address === undefined ||
          meterInfo.meterNum === undefined ||
          meterInfo.outIndicator === undefined ||
          userInfo.mobNo === undefined
        ) {
          history.push("/Error404");
          return;
        }
      }
    }
    //토큰이 ""일 때
    else {
      if (
        navigator.userAgent.indexOf("wv") > -1 ||
        (navigator.userAgent.indexOf("iPhone") > -1 &&
          navigator.userAgent.indexOf("Safari") === -1)
      ) {
        dispatch(setExpiration(true));
        sessionStorage.clear();
        dispatch(setLoggedOffApp());
        history.push("/VrscMvot/APP_CM_02");
        return;
      } else {
        history.push("/Error404");
        return;
      }
    }

    // if (location.state === undefined) {
    //   history.push("/ErrorRedirect");
    // }

    if (
      payerInfo.mvio_07_iplNo === undefined ||
      payerInfo.mvio_07_iplNo === ""
    ) {
      history.push("/ErrorRedirect");
      return;
    }

    const pop = () => {
      dispatch(setIsClickedBack(true));
      window.removeEventListener("popstate", pop);
      window.history.pushState(null, document.title, window.location.href);

      vrsc
        ? history.push("/VrscMvot/MVIO_04")
        : history.push("/SelfMvot/MVIO_04");
    };

    if (payerInfo.isReCal || payerInfo.isClickedBack) {
      dispatch(setIsReCal(false));
      onClickMvot();
      window.addEventListener("popstate", pop);
    }
  }, []);

  useEffect(() => {
    if (reason3Message !== "") {
      setShow(true);
    }
  }, [reason3Message]);

  const onClickMvot = () => {
    // vrsc
    //   ? history.push("/VrscMvot/MVOT_02")
    //   : history.push("/SelfMvot/MVOT_02");

    //전출 가능
    if (payerInfo.mvio_07_iplNo !== payerInfo.iplNo) {
      setReason1(true);
      setShow(true);
    } else {
      setLoading(true);
      fnCheckPossible();
    }
  };

  const fnCheckPossible = () => {
    setReason3Message("");
    let data = {
      reqTy: type.type === "out" ? "MO" : "MI",
      mtrNo: meterInfo.meterNum,
      msrNo: meterInfo.outIndicator,
      chkTy: "2",
    };
    const option = {
      serviceId: "pg15",
      baseUrl: BASE_URL.COMMON,
      url: "/mvio/pg15",
      header: "accessToken",
      method: METHOD.POST,
      data: data,
      callback: fnCallback,
    };
    setLoading(true);
    comfnApiSvcCall(option);
  };

  const fnCheckMvot = () => {
    setReason3Message("");
    let data;
    if (!vrsc) {
      data = {
        reqTy: "1",
        iplNo: payerInfo.iplNo,
        paypNo: payerInfo.payerId,
        msrNo: meterInfo.outIndicator,
        name: payerInfo.name,
        mobNo: userInfo.mobNo,
        addr: payerInfo.roadAddress,
        wayInTy: kakao === "inapp" ? "2" : "1",
      };
    } else {
      data = {
        reqTy: "2",
        iplNo: payerInfo.iplNo,
        paypNo: payerInfo.payerId,
        msrNo: meterInfo.outIndicator,
        name: payerInfo.name,
        mobNo: userInfo.mobNo,
        addr: payerInfo.roadAddress,
        thrdNm: parseJwt(userInfo.accessToken).usrNm,
        thrdMobNo: parseJwt(userInfo.accessToken).mobNo,
        thrdTyVal: parseJwt(userInfo.accessToken).tvVal,
        wayInTy: kakao === "inapp" ? "2" : "1",
      };
    }
    const option = {
      serviceId: "pg08",
      baseUrl: BASE_URL.COMMON,
      url: "/mvot/pg08",
      header: "accessToken",
      method: METHOD.POST,
      data: data,
      callback: fnCallback,
    };
    setLoading(true);
    comfnApiSvcCall(option);
  };

  //서버 연동 후 수정 필요
  const fnCallback = (res) => {
    setLoading(false);
    //console.log(res);

    if (res.data.hasOwnProperty("rtnCode")) {
      if (res.data.rtnCode === "-999") {
        return;
      }
    }

    if (res.errorCode < 0) {
      //console.log(res);
      if (res.data.rtnMsg.trim() !== "") {
        if (res.data.rtnMsg.indexOf("RFC") <= -1) {
          setReason3Message(res.data.rtnMsg);
        } else {
          alert(res.data.rtnMsg);
          return;
        }
      }

      // if (res.data.path === "/mvot/pg08") {
      //   const moveOutPriceTmp = {
      //     delivDate: "20210822",
      //     rolGb: "MO",
      //     qmNo: "000223456" + generateRandomCode(3),
      //     usedCpcty: "              0.0000",
      //     baseAmt: "     10",
      //     usedAmt: "        0",
      //     vatAmt: "      10",
      //     addedAmt: "        0",
      //     notiAmt: "     10",
      //     npyAmt: "20",
      //     dmdAmt: "        0",
      //     dmdYm: "2021/08",
      //   };

      //   dispatch(
      //     setPayerNo({
      //       ...payerInfo,
      //       delivDate: moveOutPriceTmp.delivDate,
      //       rolGb: moveOutPriceTmp.rolGb,
      //       qmNo: moveOutPriceTmp.qmNo,
      //       dmdAmt: moveOutPriceTmp.dmdAmt,
      //     })
      //   );
      //   vrsc
      //     ? history.push({
      //         pathname: "/VrscMvot/MVOT_02",
      //         state: {
      //           notiData: moveOutPriceTmp,
      //         },
      //       })
      //     : history.push({
      //         pathname: "/SelfMvot/MVOT_02",
      //         state: {
      //           notiData: moveOutPriceTmp,
      //         },
      //       });
      // }
    } else if (res.serviceId === "pg15") {
      if (res.data.rtnCode === "500") {
        setReason3Message(res.data.rtnMsg);
        setShow(true);
      } else {
        dispatch(setLastIndicator(res.data.lastMsrNo));
        fnCheckMvot();
      }

      // if (parseInt(meterInfo.outIndicator) < parseInt(res.data.lastMsrNo)) {
      //   setReason2(true);
      //   setShow(true);
      // } else if (
      //   payerInfo.iplNo === res.data.iplNo &&
      //   parseInt(meterInfo.outIndicator) >= parseInt(res.data.lastMsrNo)
      // ) {
      //   dispatch(setLastIndicator(res.data.lastMsrNo));
      //   fnCheckMvot();
      // }
    } else if (res.serviceId === "pg08") {
      // alert(
      //   "paypNo:",
      //   payerInfo.payerId,
      //   " rolGb:",
      //   res.data.rolGb,
      //   " delivDate:",
      //   res.data.delivDate,
      //   " qmNo:",
      //   res.data.qmNo
      // );
      if (res.data.rtnCode === "0") {
        //전출 가능
        //배정일자, 업무구분, 통지번호 고통변수에 저장하고 나머지 값들도 전달함

        // dispatch(
        //   setPayerNo({
        //     ...payerInfo,
        //     delivDate: res.data.delivDate,
        //     rolGb: res.data.rolGb,
        //     qmNo: res.data.qmNo,
        //     dmdAmt: res.data.dmdAmt,
        //   })
        // );

        dispatch(
          setPayerOrder({
            delivDate: res.data.delivDate,
            rolGb: res.data.rolGb,
            qmNo: res.data.qmNo,
            usedCpcty: res.data.usedCpcty,
            baseAmt: res.data.baseAmt,
            usedAmt: res.data.usedAmt,
            vatAmt: res.data.vatAmt,
            addedAmt: res.data.addedAmt,
            notiAmt: res.data.notiAmt,
            npyAmt: res.data.npyAmt,
            dmdAmt: res.data.dmdAmt,
            dmdYm: res.data.dmdYm,
          })
        );

        // vrsc
        //   ? history.push({
        //       pathname: "/VrscMvot/MVOT_02",
        //       state: {
        //         notiData: res.data,
        //       },
        //     })
        //   : history.push({
        //       pathname: "/SelfMvot/MVOT_02",
        //       state: {
        //         notiData: res.data,
        //       },
        //     });
        dispatch(setMVOT_02(res.data));
        vrsc
          ? history.push("/VrscMvot/MVOT_02")
          : history.push("/SelfMvot/MVOT_02");
      }
    }
  };

  return (
    <>
      {vrsc ? (
        <AppHeader title="전출 내용 확인" />
      ) : (
        <Header title="전출 내용 확인" />
      )}

      <div id="container" className="container">
        <h3
          style={{
            fontSize: "16px",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          전출 주소를 반드시 확인해 주세요.
        </h3>
        <hr />
        <table className="tbl_ver2">
          <tbody>
            <tr>
              <th>전출자</th>
              <td>{payerInfo.name}</td>
              {/* <td>홍길동</td> */}
            </tr>
          </tbody>
        </table>
        <hr />

        <table className="tbl_ver3">
          <tbody>
            <tr>
              <th>계량기 번호</th>
              <td>
                <span className="txt_blue">{meterInfo.meterNum}</span>
                {/* <span className="txt_blue">14431206287</span> */}
              </td>
            </tr>
            <tr>
              <th>주소</th>
              <td>{payerInfo.address}</td>
              {/* <td>경기도 용인시 기흥구 산양로 17길 11 304동 1203호</td> */}
            </tr>
            <tr>
              <th>전출 지침</th>
              <td>
                {parseInt(meterInfo.outIndicator)} m<sup>3</sup>
                {/* 00350 m<sup>3</sup> */}
              </td>
            </tr>
          </tbody>
        </table>

        <button
          type="button"
          className="btn btn_blue mt-3 mb-3"
          disabled={loading}
          onClick={onClickMvot}
        >
          {/* 해당 내용으로 전출하겠습니다 */}
          {loading ? <LoadingSpinner /> : "해당 내용으로 전출하겠습니다"}
        </button>

        <div
          className="btn link_txt"
          onClick={() =>
            vrsc
              ? history.push("/VrscMvot/MVIO_07")
              : history.push("/SelfMvot/MVIO_07")
          }
        >
          확인하신 전출지 주소와 전출 지침이 다른 경우,{""}
          <br />
          계량기 번호 및 전출 지침을 직접 입력해 주세요.
        </div>
      </div>

      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header>
          <Modal.Title>
            <h5 className="modal-title shot">
              조회 결과 셀프 전출이 불가하며, <br /> 사유는 아래와 같습니다.
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: 0 }}>
          <div className="info_box">
            <>
              {reason1 === true ? (
                <p className="info">
                  납부자 주소와 계량기 주소가 일치하지 않았습니다.
                </p>
              ) : (
                ""
              )}

              {reason3Message !== "" ? (
                <p className="info">{reason3Message}</p>
              ) : (
                ""
              )}
            </>
          </div>

          <div className="cut_line" />

          <div className="bottom_box">
            <p className="txt_bottom">
              고객센터 <a href="tel:1544-3002">1544-3002</a>
            </p>
            <p className="txt_bottom">(상담가능시간 : 평일 09:00~18:00) 로</p>
            <p className="txt_bottom">연락 부탁드립니다.</p>
          </div>
        </Modal.Body>
        <div className="row btn-choice">
          <div className="col-6 btn-mid">
            <button
              type="button"
              className="btn btn_ok2"
              onClick={() => {
                setShow(false);
              }}
            >
              확인
            </button>
          </div>
          {/* <div className="col-6 btn-lt">
            <button
              type="button"
              className="btn btn_cancel"
              onClick={() => setShow(false)}
            >
              취소
            </button>
          </div>
          <div className="col-6 btn-rt">
            <button
              type="button"
              className="btn btn_ok"
              onClick={() => setShow(false)}
            >
              확인
            </button>
          </div> */}
        </div>
      </Modal>
    </>
  );
};

export default MVOT_01;
