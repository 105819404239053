import React, { useEffect, useState } from "react";
import AppHeader from "@components/common/AppHeader/AppHeader";
import { checkTime } from "@src/lib/util/Util";
import { BASE_URL, comfnApiSvcCall, METHOD } from "@src/lib/common/comfn";
import { useDispatch } from "react-redux";
import {
  setInitalUser,
  selectUserInfo,
  setLoggedOffApp,
} from "@store/modules/userSlice";
import Cookies from "universal-cookie";
import { parseJwt } from "@src/lib/util/Util";
import { useSelector } from "react-redux";
import { setExpiration } from "@src/store/modules/typeSlice";

const MBR_17 = ({ history }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const appUserInfo = useSelector(selectUserInfo);
  const cookies = new Cookies();
  useEffect(() => {
    document.title = "삼천리 | 휴면계정 안내";
    // 현재시간이 22시~6시 사이면 이용불가 페이지
    if (!checkTime()) {
      history.push("/ImpossiblePage");
      return;
    }
  }, []);

  const onClickEndSleeping = () => {
    let data = {
      usrId: parseJwt(appUserInfo.accessToken).usrId,
    };

    const option = {
      serviceId: "pg32",
      baseUrl: BASE_URL.COMMON,
      url: "/mbr/pg32",
      header: "accessToken",
      method: METHOD.POST,
      data: data,
      callback: fnCallback,
    };
    setLoading(true);
    comfnApiSvcCall(option);
  };

  const fnCallback = (res) => {
    setLoading(false);
    if (res.data.hasOwnProperty("rtnCode")) {
      if (res.data.rtnCode === "-999") {
        return;
      }
    }
    if (res.serviceId === "pg32") {
      if (res.data.rtnCode === "0") {
        if (cookies) {
          sessionStorage.clear();
          cookies.remove("refresh_token");
          // cookies.set("refresh_token", "", {
          //   sameSite: "strict",
          // });
          alert("로그아웃 되었습니다.");
          dispatch(setExpiration(false));
          dispatch(setLoggedOffApp());
        }
        alert("휴면 해제 되었습니다.");
        dispatch(setInitalUser);
        history.replace({
          pathname: "/VrscMvot/MBR_08",
          state: { page: "" },
        });
      } else {
        alert(res.data.rtnMsg);
      }
    }
  };

  return (
    <>
      <AppHeader title="휴면계정 안내" />

      <div id="container" className="container info">
        <div className="agree_txt_box mb-5">
          <h2 className="head_txt">잠자고 있는</h2>
          <h2 className="head_txt">회원님의 아이디를</h2>
          <h2 className="head_txt">깨워주세요!</h2>
        </div>
        <p className="service_txt">
          회원님의 계정은 1년 이상 로그인되지 않아 휴면 계정으로
        </p>
        <p className="service_txt">전환되었습니다. 아래 버튼을 클릭하시면,</p>
        <p className="service_txt">지금 바로 서비스를 이용하실 수 있습니다.</p>
      </div>

      <button
        type="button"
        className="btn btn_blue btn_big btn_bottom"
        style={{ marginTop: 20 }}
        onClick={onClickEndSleeping}
      >
        휴면 해제
      </button>
    </>
  );
};

export default MBR_17;
