import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";

const NewAgreementModal = ({
  show,
  onHide,
  setModalCheckBox,
  allAgree,
  isExceptAgree6,
}) => {
  const checkBoxList = [
    "agreement1",
    "agreement2",
    "agreement3",
    "agreement4",
    "agreement5",
    "agreement6",
  ];
  const [checkBoxes, setCheckBoxes] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  const handleCheckBox = (e) => {
    const { id, checked } = e.target;
    if (checked) {
      setCheckBoxes([...checkBoxes, id]);
    } else {
      setCheckBoxes(checkBoxes.filter((inputId) => inputId !== id));
    }
  };

  const handleAllCheckbox = (e) => {
    const { checked } = e.target;
    setCheckBoxes(checked ? checkBoxList : []);
  };

  const onClickOk = () => {
    onHide(true);
    setModalCheckBox(checkBoxes);
  };

  const onClickHide = () => {
    onHide(true);
    setCheckBoxes([]);
  };

  useEffect(() => {
    if (checkBoxList.length === checkBoxes.length) {
      setCheckAll(true);
    } else {
      setCheckAll(false);
    }
  }, [checkBoxes]);

  useEffect(() => {
    if (!allAgree) {
      setCheckBoxes([]);
    } else {
      //전체동의
      if (!isExceptAgree6) {
        //6까지 포함
        setCheckBoxes(checkBoxList);
      } else {
        setCheckBoxes([
          "agreement1",
          "agreement2",
          "agreement3",
          "agreement4",
          "agreement5",
        ]);
      }
    }
  }, [allAgree]);

  useEffect(() => {
    if (show) {
      if (allAgree && !isExceptAgree6) {
        if (!checkBoxes.includes("agreement6")) {
          setCheckBoxes([...checkBoxes, "agreement6"]);
        }
      }
    }
  }, [show]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      contentClassName="agreement-modal-content"
      dialogClassName="agreement"
      scrollable={true}
    >
      <Modal.Body
        style={{
          backgroundColor: "rgb(245 245 245)",
          color: "#777",
        }}
      >
        <div
          className="custom-control custom-radio"
          style={{ paddingLeft: "30px" }}
        >
          <input
            type="checkbox"
            id="agreementAll"
            className="custom-control-input"
            onChange={handleAllCheckbox}
            checked={checkAll}
          />
          <label className="custom-control-label" htmlFor="agreementAll">
            전체 동의
          </label>
        </div>

        <div
          className="spanAgreement"
          style={{ width: "100%", padding: "10px 10px 5px" }}
        >
          <div
            className="section_radio outline_checkbox"
            style={{ marginBottom: "0px", padding: "6px 6px" }}
          >
            <div className="custom-control custom-radio">
              <input
                type="checkbox"
                id="agreement1"
                className="custom-control-input"
                checked={checkBoxes.includes("agreement1")}
                onChange={handleCheckBox}
              />
              <label className="custom-control-label" htmlFor="agreement1">
                개인정보 수집 및 위탁/활용 동의 (필수)
              </label>
            </div>
          </div>
          <div
            style={{
              height: "75px",
              padding: "15px 15px",
              background: "rgb(233 233 233)",
              overflow: "scroll",
              marginBottom: "10px",
              borderRadius: "6px",
            }}
          >
            개인정보 수집/이용 및 위탁/활용에 대한 동의
            <br />
            1. 수집 및 활용을 위한 필수 개인정보
            <br />
            • 성명, 생년월일, 성별, 자택주소, 사업자등록번호, 고객과의 관계(이상
            '식별정보')
            <br />
            • 주소, e-mail, 전화번호(이상 '연락 정보')
            <br />
            • 사용량, 사용금액, 결제 기록, 접속 기록, 공급중지 기록(이상
            '서비스이용 정보')
            <br />
            • 수집된 개인정보는 고객님의 도시가스 신청 및 공급과 관련된 제반
            사항을 제공 하기 위한 자료로 활용되며, 고객의 동의 없는 개인정보의
            제3자 제공 행위는 없음을 보증합니다.
            <br />
            • 필수 개인정보수집에 동의하지 않을 경우 공급에 제한될 수 있으며,
            이때 보증금, 연대보증인, 보증보험증권예치 등 담보를 제공 할 수 있을
            경우 공급 될 수 있음을 알려드립니다.
            <br />
            1. 회사가 도시가스 공급계약과 관련한 제반 업무의 대행 및 이용 고객의
            유지, 관리 등을 위하여 개인정보의 제공
            <br /> • 위탁업체는 회사의 사정으로 추가, 변경될 수 있으며, 상세
            내용은 삼천리 도시가스의 개인정보 취급방침에 게시됩니다.
          </div>

          <div
            className="section_radio outline_checkbox"
            style={{ marginBottom: "0px", padding: "6px 6px" }}
          >
            <div className="custom-control custom-radio">
              <input
                type="checkbox"
                id="agreement2"
                className="custom-control-input"
                checked={checkBoxes.includes("agreement2")}
                onChange={handleCheckBox}
              />
              <label className="custom-control-label" htmlFor="agreement2">
                도시가스 사용약관 동의 (필수)
              </label>
            </div>
          </div>
          <div
            style={{
              height: "75px",
              padding: "15px 15px",
              background: "rgb(233 233 233)",
              overflow: "scroll",
              marginBottom: "10px",
              borderRadius: "6px",
            }}
          >
            도시가스 공급규정(경기 제 7조, 인천 제 6조)에 의거하여 사용계약 체결
            안내
            <br />* 도시가스 공급규정(경기 제 7조 ①항, 인천 제 6조 ①항)
            <br />- 가스사용 개시 및 사용자 변경, 공급중지 후 재사용 시 당사가
            요구할 경우 사용자는 도시가스 사용계약을 별도로 체결해야 하며, 이를
            정당한 사유 없이 거부 할 경우 당사는 도시가스 공급을 거부 할 수
            있습니다.
            <br />
            <br />
            제1조(계약의 준수)
            <br />
            1.가스사용자는 다음 사항을 준수하여야 한다.
            <br />
            ㉠가스요금 및 그 밖에 이 규정에서 정한 제징수금을 기한내에
            납부하여야 한다.
            <br />
            ㉡가스공급 및 사용시설의 관리는 선량한 관리자로서의 주의의무를
            다하여야 하며, 가스공급 및 사용시설을 설치 또는 변경하고자 하는
            경우에는 사전에 도시 가스회사에 통지하여야 한다.
            <br />
            ㉢가스공급 및 사용시설이 속한 건축물의 가스사용자가 변경된 경우
            변경된 가스사용자는 문서(전자문서 포함) 또는 전화로 도시가스회사에
            가스사용자 명의 변경을 신청한다.
            <br />
            2.제1항 제㉠호의 규정에 의한 납부기한이 경과한 때에는 도시가스회사는
            독촉장을 발부하고, 미납한 가스요금에 대하여 월 2%의 가산금을 일수
            계산하여 연간 2회까지 미납원금에 부과한다.
            <br />
            <br />
            제2조(검침과 사용량 산정)
            <br />
            1.가스사용량 검침과 요금고지서 송달은 도시가스회사가 매월 또는 격월
            정례 일에 실시함을 원칙으로 하며, 가스계량기가 실내에 설치된 경우
            가스사용자의 편익을 위해 자가 검침을 시행할 수 있다.
            <br />
            2.사용량은 온도·압력보정장치(이하 "보정기")가 설치된 경우 매월
            보정기의 합산량으로 한다.
            <br />
            3.보정기 미설치 또는 고장의 경우 도시가스공급규정에 의거 사용압력에
            따라 산출된 보정계수를 적용하여 산정한다.
            <br />
            4.가스사용자의 부재로 검침을 할 수 없을 때에는 전년 동월 사용량 또는
            전월 사용량을, 가스계량기의 고장 등 그 밖의 사유로 사용량을 명확히
            계측할 수 없을 때에는 전년 동월포함 전후1개월(3개월)간의
            월평균사용량으로 산정하는 것을 원칙으로 한다.
            <br />
            <br />
            제3조(요금의 납기와 수납방법)
            <br />
            1.도시가스회사는 매월 또는 각 고지분을 소정 납기내까지 징수한다.
            다만, 공급중지, 폐지, 부도 또는 연 2회 이상의 습관적으로 미납된
            수요가는 월3회 이상 분할검침하여 징수할 수 있으며, 이 경우 공급을
            중지 또는 폐지하였을 때에는 수시로 이를 징수할 수 있다
            <br />
            2.도시가스회사는 가스사용자가 부도/파산 발생 사실을 인지하였을
            경우에는 인지일 전 사용요금에 대하여도 납기와 무관하게 즉시 요금을
            징수할 수 있다.
            <br />
            <br />
            제4조(공급중지)
            <br />
            당사는 다음 각 호의 사유가 있을 때에는 가스사용자에게 그 사유를
            통지하고 가스공급을 중지할 수 있다.
            <br />
            1.요금 및 그 밖의 이 규정에 의한 제징수금을 기한내 납부하지 아니하여
            2회 이상 독촉장을 받고도 이를 이행하지 아니한 때.
            <br />
            2.당사 또는 당사가 지정하는 자가 행하는 검침?점검?작업을 정당한 사유
            없이 거부 또는 방해한 때<br />
            3. 부정한 방법으로 가스를 사용한 때 또는 사용한다고 명확히 인정될 때
            <br />
            4. 계약의 준수사항을 위반하여 가스공급 및 사용시설을 임의 변경한 때
            <br />
            5. 가스사용자의 토지내에 있는 당사 소유의 공급시설을 고의로 손상
            또는 망실하여 중대한 손해를 입혔을 때<br />
            6. 가스사용자 등이 2개월 이상 소재가 불분명할 때<br />
            7. 법의 규정에 의한 안전관리규정에 따라 시설의 개선을 권고하고,
            가스사용자가 그 권고를 따르지 아니하거나 가스 사용상태가 위험하다고
            인정될 때<br />
            8. 그 밖의 안전관리와 관련한 당사의 정당한 권고사항을 이행 하지
            않았을 때<br />
            9. 보증금 예치 대상 가스사용자중 부도, 화의, 법정관리,
            경매가발생하여 보증금 예치를 2회 이상 요청하였으나, 이를 정당한
            이유없이 거부할 때<br />
            <br />
            제5조(기타)
            <br />
            가스의 공급 및 사용과 관련하여 본 계약에 명시되지 않은 사항은
            도시가스사업법, 도시가스공급규정, 도시가스안전관리규정 등 관련 규정
            및 일반적인 상관례를 따른다.또한, 본 계약내용과 관련된 법률,
            관련규정 등이 개정된 경우 개정 법률 및 해당 규정을 따른다.
          </div>

          <div
            className="section_radio outline_checkbox"
            style={{ marginBottom: "0px", padding: "6px 6px" }}
          >
            <div className="custom-control custom-radio">
              <input
                type="checkbox"
                id="agreement3"
                className="custom-control-input"
                checked={checkBoxes.includes("agreement3")}
                onChange={handleCheckBox}
              />
              <label className="custom-control-label" htmlFor="agreement3">
                개인(신용)정보 수집/이용 동의 (필수)
              </label>
            </div>
          </div>
          <div
            style={{
              height: "75px",
              padding: "15px 15px",
              background: "rgb(233 233 233)",
              overflow: "scroll",
              marginBottom: "10px",
              borderRadius: "6px",
            }}
          >
            .항목: 성명, 생년월일, 성별, 연락처, 주소 등<br />
            ·목적: 요금고지/수납/체납관리, 안점점검, AS, 계량기교체일정협의 등
            <br />
            ·기간: 수집/이용 목적 달성시 까지
            <br />
          </div>

          <div
            className="section_radio outline_checkbox"
            style={{ marginBottom: "0px", padding: "6px 6px" }}
          >
            <div className="custom-control custom-radio">
              <input
                type="checkbox"
                id="agreement4"
                className="custom-control-input"
                checked={checkBoxes.includes("agreement4")}
                onChange={handleCheckBox}
              />
              <label className="custom-control-label" htmlFor="agreement4">
                개인(신용)정보 제공 동의 (필수)
              </label>
            </div>
          </div>
          <div
            style={{
              height: "75px",
              padding: "15px 15px",
              background: "rgb(233 233 233)",
              overflow: "scroll",
              marginBottom: "10px",
              borderRadius: "6px",
            }}
          >
            .항목: 성명, 생년월일, 성별, 연락처, 연체정보 등<br />
            ·목적: 요금고지/수납/체납관리, 안점점검, AS, 계량기교체일정협의,
            신용정보법에 의한 업무 등<br />
            ·기간: 제공목적 달성시 까지
            <br />
            .제공: 업무위탁사, 금융결제원, 신용조회회사 (NICE평가정보 등)
            <br />
          </div>

          <div
            className="section_radio outline_checkbox"
            style={{ marginBottom: "0px", padding: "6px 6px" }}
          >
            <div className="custom-control custom-radio">
              <input
                type="checkbox"
                id="agreement5"
                className="custom-control-input"
                checked={checkBoxes.includes("agreement5")}
                onChange={handleCheckBox}
              />
              <label className="custom-control-label" htmlFor="agreement5">
                개인(신용)정보 조회 동의 (필수)
              </label>
            </div>
          </div>
          <div
            style={{
              height: "75px",
              padding: "15px 15px",
              background: "rgb(233 233 233)",
              overflow: "scroll",
              marginBottom: "10px",
              borderRadius: "6px",
            }}
          >
            .항목: 성명, 주소, 연락처, 신용개설정보 등<br />
            ·목적: 체납요금 관리 업무
            <br />
            ·기간: 조회목적 달성시 까지
            <br />
            .회사: 신용조회회사(NICE평가정보 등)
            <br />
          </div>

          <div
            className="section_radio outline_checkbox"
            style={{ marginBottom: "0px", padding: "6px 6px" }}
          >
            <div className="custom-control custom-radio">
              <input
                type="checkbox"
                id="agreement6"
                className="custom-control-input"
                checked={checkBoxes.includes("agreement6")}
                onChange={handleCheckBox}
              />
              <label className="custom-control-label" htmlFor="agreement6">
                요금정보 등의 제3자 제공에 관한 동의 (선택)
              </label>
            </div>
          </div>
          <div
            style={{
              height: "75px",
              padding: "15px 15px",
              background: "rgb(233 233 233)",
              overflow: "scroll",
              marginBottom: "10px",
              borderRadius: "6px",
            }}
          >
            1. 성명, 전화번호, 관계, 요금정보, 고객전용입금계좌, 전출입,
            안전점검 정보
            <br />
            2. 건물주의 임대차 관리를 위한 사용자 관리
            <br />
            3. 동의 철회 시 까지 활용
            <br />
          </div>
        </div>
      </Modal.Body>
      <div className="row btn-choice">
        <div className="col-6 btn-lt">
          <button
            type="button"
            className="btn btn_cancel"
            onClick={onClickHide}
          >
            취소
          </button>
        </div>
        <div className="col-6 btn-rt">
          <button type="button" className="btn btn_ok" onClick={onClickOk}>
            확인
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default NewAgreementModal;
