import React from "react";
import { Modal } from "react-bootstrap";

const AgreementModal = ({ show, onHide, title, content }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      contentClassName="agreement-modal-content"
      dialogClassName="agreement"
      scrollable={true}
    >
      <Modal.Header closeButton style={{ fontSize: "15px" }}>
        {title}
      </Modal.Header>
      <Modal.Body
        style={{
          backgroundColor: "#efefef",
          color: "#777",
        }}
      >
        {content.split("\n").map((line) => {
          //console.log(line);
          return (
            <div className="spanAgreement" style={{ width: "100%" }}>
              <span className="spanAgreement" style={{ maxHeight: "200px" }}>
                {line}
              </span>
            </div>
          );
        })}
        {/* {content} */}
      </Modal.Body>
    </Modal>
  );
};

export default AgreementModal;
