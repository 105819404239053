import React, { useEffect, useState } from "react";
import AppHeader from "@components/common/AppHeader/AppHeader";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useForm } from "react-hook-form";

const FirstComponent = () => {
  return (
    <div style={{ padding: "7px" }}>
      <p>
        보다 정확한 검색을 위해
        <br />
        다음 권장사항을 확인하여 주시기 바랍니다.
      </p>
      <br />
      <p style={{ marginBottom: 0 }}>시·도/시·군·구 + 도로명주소</p>
      <p style={{ color: "#4e89c8" }}>예) 종로구 사직로 161</p>
      <p style={{ marginBottom: 0 }}> 시·도/시·군·구/읍·면·동 + 지번</p>
      <p style={{ color: "#4e89c8" }}>예) 종로구 관훈동 198-1</p>
      <p style={{ marginBottom: 0 }}>시·도/시·군·구 + 건물명</p>
      <p style={{ color: "#4e89c8" }}>예) 역삼동 737</p>
    </div>
  );
};

const AddressDetailComponent = (props) => {
  //console.log(props);
  const { register, watch, setValue } = useForm();

  const watchSelectedRoadAddr = watch("selectedRoadAddr");
  const watchDetailAddr = watch("detailAddr");

  const onClickAddAddress = (e) => {
    //console.log(selectedRoadAddr, watchDetailAddr);
    if (watchDetailAddr === "") {
      alert("상세 주소를 입력해 주세요.");
      return;
    } else {
      const result = {
        // addr1: selectedRoadAddr,
        // postCd: "dsfsdfs",
        postCd: props.selectData.zipNo,
        baseAddr: props.selectData.roadAddr,
        dtlAddr: watchDetailAddr,
      };
      //console.log(result);
      window.opener.postMessage({ message: result }, "*");
      window.opener.postMessage({ message: { openPopup: true } }, "*");
      window.close();
    }
  };

  useEffect(() => {
    setValue("selectedRoadAddr", props.selectData.roadAddr);
    setValue("detailAddr", "");
  }, [props.selectData]);

  return (
    <div style={{ padding: "7px" }}>
      <b>상세주소 입력</b>
      <hr />
      <form>
        <label className="custom-control-labe">도로명 주소</label>
        <input
          className="form-control mb-2"
          id="selectedRoadAddr"
          readOnly
          {...register("selectedRoadAddr")}
        />
        <label className="custom-control-labe">상세주소 입력</label>
        <input
          className="form-control mb-2"
          id="detailAddr"
          {...register("detailAddr")}
        />

        <div>
          <button
            type="button"
            className="btn btn_blue"
            onClick={onClickAddAddress}
          >
            주소 입력
          </button>
        </div>
      </form>
    </div>
  );
};

const FindAddress = () => {
  const [keyword, setKeyword] = useState("");
  const [buttonState, setButtonState] = useState("1");
  const API_KEY = "devU01TX0FVVEgyMDIxMDkzMDE3MjgxNTExMTcwNTU=";
  const countPerPage = 10;
  const [addrs, setAddrs] = useState([
    {
      roadAddr: "",
      jibunAddr: "",
      zipNo: "",
    },
  ]);
  const [selectedAllAddr, setSelectedAllAddr] = useState({
    jibunAddr: "",
    roadAddr: "",
    zipNo: "",
  });
  const [totalAddrs, setTotalAddrs] = useState();
  const [totalPageNum, setTotalPageNum] = useState();
  const [selectedRoadAddr, setSelectedRoadAddr] = useState("");
  const [nowPage, setNowPage] = useState(1);
  const AddressResultComponent = () => {
    return (
      <div style={{ padding: "7px" }}>
        <table style={{ borderCollapse: "collapse", width: "100%" }}>
          <tbody>
            <tr>
              <td colSpan="2">
                <b>
                  도로명 주소 검색 결과{" "}
                  <b style={{ color: "#4e89c8" }}>({totalAddrs})</b>건
                </b>
              </td>
            </tr>

            {addrs.length !== 0 &&
              addrs.map((row, index) => (
                <tr key={index}>
                  <td
                    style={{
                      padding: "15px",
                      borderWidth: "1px 0px 1px 1px",
                      borderStyle: "solid",
                      borderColor: "#c1c1c1",
                      fontSize: "14px",
                    }}
                    data-item={row.roadAddr}
                  >
                    지번 :
                    <b style={{ cursor: "pointer" }} onClick={onClickAddress}>
                      {row.jibunAddr}
                    </b>
                    <br />
                    도로명:
                    <b style={{ cursor: "pointer" }} onClick={onClickAddress}>
                      {row.roadAddr}
                    </b>
                  </td>
                  <td
                    style={{
                      padding: "15px",
                      borderWidth: "1px 1px 1px 0px",
                      borderStyle: "solid",
                      borderColor: "#c1c1c1",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                    onClick={onClickAddress}
                  >
                    {row.zipNo}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>

        <>
          {/* <Pagination
            pageSize="10"
            itemsCount={totalAddrs}
            currentPage={nowPage}
          /> */}
        </>
        <div>
          {nowPage < totalPageNum ? (
            <button
              type="button"
              className="btn btn_blue"
              onClick={seeMoreAddress}
            >
              더보기
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  };

  const seeMoreAddress = () => {
    setNowPage(nowPage + 1);
    search(nowPage + 1);
  };

  // const getUrl = (curPage) =>
  //   `https://www.juso.go.kr/addrlink/addrLinkApi.do?confmKey=${API_KEY}&currentPage=${curPage}&countPerPage=${countPerPage}&keyword=${keyword}&resultType=json`;

  const getArray = (num) => {
    let arr = [];
    for (let i = 1; i <= num; i++) {
      arr.push(i);
    }
    return arr;
  };

  const search = (curPage) => {
    const url = `https://www.juso.go.kr/addrlink/addrLinkApi.do?confmKey=${API_KEY}&currentPage=${curPage}&countPerPage=${countPerPage}&keyword=${keyword}&resultType=json`;
    axios({
      method: "get",
      url: url,
    }).then((res) => {
      //console.log(res.data);
      if (res.data.results.common.errorMessage !== "정상") {
        alert(res.data.results.common.errorMessage);
        setTotalPageNum(0);
        setTotalAddrs(0);
      } else {
        setAddrs(addrs.concat(res.data.results.juso));
        setButtonState("2");
        setTotalAddrs(res.data.results.common.totalCount);
        if (parseInt(res.data.results.common.totalCount) % 10 === 0) {
          setTotalPageNum(parseInt(res.data.results.common.totalCount) / 10);
        } else {
          setTotalPageNum(
            parseInt(parseInt(res.data.results.common.totalCount) / 10) + 1
          );
        }
      }
    });
    // .catch((err) => {
    //   console.log(err);
    // });
  };

  // useEffect(() => {
  //   window.opener.postMessage({ message: "mvot10" }, "*");
  // }, []);

  useEffect(() => {
    document.title = "삼천리 | 주소 검색";
  }, []);

  useEffect(() => {}, [buttonState, selectedRoadAddr]);
  useEffect(() => {
    if (addrs.length === 0) {
      search(1);
    }
  }, [addrs]);

  const onClickFindAddress = () => {
    if (keyword === "") {
      alert("주소를 입력해주세요.");
    } else {
      const tmpArr = [];
      setAddrs(tmpArr);
      setNowPage(1);
      // search(1);
    }
  };

  // const onClickAddress = (e) => {
  //   const addr = e.target.innerHTML + "";
  //   // console.log(e.target);
  //   // setValue("selectedRoadAddr", addr);    // setValue("detailAddr", "");
  //   setSelectedRoadAddr(addr);
  //   setButtonState("3");
  // };

  const onClickAddress = (e) => {
    //console.log(e.target.innerText);
    let text = e.target.innerText;
    //console.log(text);

    const pattern1 = /[0-9]/;
    if (!pattern1.test(text[0])) {
      for (let i = 0; i < addrs.length; i++) {
        if (
          addrs[i].jibunAddr.trim() === text.trim() ||
          addrs[i].roadAddr.trim() === text.trim()
        ) {
          //console.log("같음");
          setSelectedAllAddr({
            jibunAddr: addrs[i].jibunAddr,
            roadAddr: addrs[i].roadAddr,
            zipNo: addrs[i].zipNo,
          });

          setSelectedRoadAddr(addrs[i].roadAddr);
          setButtonState("3");
        }
      }
    } else {
      for (let i = 0; i < addrs.length; i++) {
        if (addrs[i].zipNo === text) {
          //console.log("같음");
          setSelectedAllAddr({
            jibunAddr: addrs[i].jibunAddr,
            roadAddr: addrs[i].roadAddr,
            zipNo: addrs[i].zipNo,
          });

          setSelectedRoadAddr(addrs[i].roadAddr);
          setButtonState("3");
        }
      }
    }
  };

  return (
    <div style={{ padding: "7px" }}>
      <AppHeader title="주소 검색" />
      <div>
        <div className="form-group">
          <div className="zipcode_area row">
            <div className="col-8 zip">
              <input
                className="form-control mb-2"
                id="keyword"
                placeholder="주소 입력"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
              />
            </div>
            <div className="col-4 btn_add">
              <button
                type="button"
                className="btn btn_white"
                onClick={onClickFindAddress}
              >
                검색
              </button>
            </div>
          </div>
          <div
            id="container"
            className="container info"
            style={{ paddingTop: "0" }}
          ></div>
        </div>
        {buttonState === "1" && <FirstComponent />}
        {buttonState === "2" && <AddressResultComponent />}
        {buttonState === "3" && (
          <AddressDetailComponent
            selectData={selectedAllAddr}
            postCd={"12345678"}
          />
        )}
      </div>
    </div>
  );
};

export default FindAddress;
