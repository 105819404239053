import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { analyticsFunction } from "@src/lib/util/FirebaseUtil";
import "firebase/analytics";

const AnalyticsComponent = () => {
  const history = useHistory();
  useEffect(() => {
    analyticsFunction();
    history.listen(() => {
      analyticsFunction();
    });
  }, [history]);
  return <></>;
};

export default AnalyticsComponent;
