import React, { useEffect } from "react";
import { Header } from "@components/common/Header";
import AppHeader from "@components/common/AppHeader/AppHeader";
import { useHistory } from "react-router-dom";
import { selectType } from "@store/modules/typeSlice";
import { selectPayerNo } from "@store/modules/payerNoSlice";
import { useSelector } from "react-redux";

const ErrorRedirect = () => {
  useEffect(() => {
    document.title = "삼천리 | 첫 페이지로 이동";
  }, []);

  const allType = useSelector(selectType);
  const type = allType.type;
  const vrsc = allType.vrsc;
  const history = useHistory();
  const payerInfo = useSelector(selectPayerNo);
  const payerId = payerInfo.payerId;

  const onClickButton = () => {
    // 납부자 번호가 있다면 전입/전출 안내페이지
    if (payerId !== "" && payerId !== undefined && payerId !== null) {
      //셀프 전출일 때
      if (type === "out" && !vrsc) {
        history.push("/SelfMvot/self_mvot_02");
      }
      //3자 전출일 때
      else if (type === "out" && vrsc) {
        history.push("/VrscMvot/VRSC_MVOT_02");
      }
      //셀프 전입일 때
      else if (type === "in" && !vrsc) {
        history.push("/SelfMvin/self_mvin_02");
      }
    }
    // 납부자 번호가 없다면 초기 본인인증 페이지
    else {
      //셀프 전출일 때
      if (type === "out" && !vrsc) {
        history.push("/SelfMvot");
      }
      //3자 전출일 때
      else if (type === "out" && vrsc) {
        history.push("/VrscMvot/vrsc_mvot_01");
      }
      //셀프 전입일 때
      else if (type === "in" && !vrsc) {
        history.push("/SelfMvin");
      }
    }
  };
  return (
    <>
      {vrsc ? (
        <AppHeader title="첫 페이지로 이동" />
      ) : (
        <Header title="첫 페이지로 이동" />
      )}

      <div id="container" className="container">
        <div className="section_error">
          <div className="icon_area" />
          <p className="txt">화면 내용을 정상적으로 표시할 수 없습니다.</p>

          <p className="error_txt center">첫 페이지로 이동 후, 다시 시도</p>
          <p className="error_txt center">부탁드립니다.</p>
        </div>
      </div>

      {(type === "out" && vrsc) ||
      (type === "out" && !vrsc) ||
      (type === "in" && !vrsc) ? (
        <button
          type="button"
          className="btn btn_blue btn_big btn_bottom mt-5"
          onClick={onClickButton}
        >
          확인
        </button>
      ) : (
        ""
      )}
    </>
  );
};

export default ErrorRedirect;
