import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  payTyCd: "", //자동이체 유형
  bankCd: "", //은행 코드
  accNo: "", //계좌 번호
  accHolderName: "", //예금주 명
  cardCmpCd: "", //카드사 코드
  cardNo: "", //카드 번호
  cardExpMonth: "", //카드 유효기간 년
  cardExpYear: "", // 카드 유효기간 월
  payBrthYm: "", // 카드 소유주 생년월일
};

export const selfMvin07 = createSlice({
  name: "selfMvin07Info",
  initialState,
  reducers: {
    setSelfMvin07: (state, action) => {
      state.payTyCd = action.payload.payTyCd;
      state.bankCd = action.payload.bankCd;
      state.accNo = action.payload.accNo;
      state.accHolderName = action.payload.accHolderName;
      state.cardCmpCd = action.payload.cardCmpCd;
      state.cardNo = action.payload.cardNo;
      state.cardExpMonth = action.payload.cardExpMonth;
      state.cardExpYear = action.payload.cardExpYear;
      state.payBrthYm = action.payload.payBrthYm;
    },
  },
});

export const { setSelfMvin07 } = selfMvin07.actions;

export const selectSelfMvin07Info = (state) => ({
  payTyCd: state.selfMvin07Info.payTyCd,
  bankCd: state.selfMvin07Info.bankCd,
  accNo: state.selfMvin07Info.accNo,
  accHolderName: state.selfMvin07Info.accHolderName,
  cardCmpCd: state.selfMvin07Info.cardCmpCd,
  cardNo: state.selfMvin07Info.cardNo,
  cardExpMonth: state.selfMvin07Info.cardExpMonth,
  cardExpYear: state.selfMvin07Info.cardExpYear,
  payBrthYm: state.selfMvin07Info.payBrthYm,
});

export default selfMvin07.reducer;
