import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  payerId: "", //납부자 번호
  custNo: "", //고객 번호
  iplNo: "", //소비처 번호
  name: "", //이름
  address: "", //지번 주소
  roadAddress: "", //도로명 주소
  custSign: "", //서명
  delivDate: "", // 배정일자
  rolGb: "", //업무구분
  qmNo: "", //통지 번호
  discntYn: "N", // 경감 할인 여부
  lockYn: "", // 계량기 잠금 장치 체결 여부
  warningYn: "N", // 특이세대(개조심)
  eNotiTyCd: "", // 선택된 전자고지 유형
  dmdAmt: "", // 결제 금액
  payTyCd: "", //결제타입
  usedCpcty: "", //사용열량
  baseAmt: "", //기본요금
  usedAmt: "", //사용요금
  vatAmt: "", //부가세
  addedAmt: "", //가산금
  notiAmt: "", //고지금액
  npyAmt: "", //미납금액
  dmdYm: "", //청구년월
  ordNo: "", //주문번호
  mvio_07_iplNo: "", // 직접입력 => 전출확인
  self_mvin_03_list: [],
  self_mvot_03_list: [],
  isReCal: false,
  isClickedBack: false,
};

export const payerNoSlice = createSlice({
  name: "payerNoInfo",
  initialState,
  reducers: {
    setPayerNo: (state, action) => {
      state.payerId = action.payload.payerId;
      state.custNo = action.payload.custNo;
      state.iplNo = action.payload.iplNo;
      state.name = action.payload.name;
      state.address = action.payload.address;
      state.roadAddress = action.payload.roadAddress;
      state.custSign = action.payload.custSign;
      state.delivDate = action.payload.delivDate;
      state.rolGb = action.payload.rolGb;
      state.qmNo = action.payload.qmNo;
      state.dmdAmt = action.payload.dmdAmt;
      state.payTyCd = action.payload.payTyCd;
    },
    setInitalPayer: (state) => {
      state.payerId = "";
      state.custNo = "";
      state.iplNo = "";
      state.name = "";
      state.address = "";
      state.roadAddress = "";
      state.custSign = "";
      state.delivDate = "";
      state.rolGb = "";
      state.qmNo = "";
      state.payTyCd = "";
      state.discntYn = "N"; // 경감 할인 여부
      state.warningYn = "N"; // 특이세대(개조심)
      state.eNotiTyCd = ""; // 선택된 전자고지 유형
      state.dmdAmt = ""; // 결제 금액
      state.payTyCd = ""; //결제타입
      state.usedCpcty = ""; //사용열량
      state.baseAmt = ""; //기본요금
      state.usedAmt = ""; //사용요금
      state.vatAmt = ""; //부가세
      state.addedAmt = ""; //가산금
      state.notiAmt = ""; //고지금액
      state.npyAmt = ""; //미납금액
      state.dmdYm = ""; //청구년월
      state.ordNo = ""; //주문번호
      state.self_mvin_03_list = [];
      state.self_mvot_03_list = [];
      state.isReCal = false;
      state.isClickedBack = false;
    },
    setIplNo: (state, action) => {
      state.iplNo = action.payload;
    },
    setMvio_07IplNo: (state, action) => {
      state.mvio_07_iplNo = action.payload;
    },
    setCheckMvin: (state, action) => {
      state.delivDate = action.payload.delivDate;
      state.rolGb = action.payload.rolGb;
      state.qmNo = action.payload.qmNo;
    },
    setAgree: (state, action) => {
      state.warningYn = action.payload.warningYn;
      state.discntYn = action.payload.discntYn;
    },
    setAddress: (state, action) => {
      state.address = action.payload.addr1;
      state.roadAddress = action.payload.addr2;
    },
    setENotiTyCd: (state, action) => {
      state.eNotiTyCd = action.payload;
    },
    setPayTyCd: (state, action) => {
      state.payTyCd = action.payload;
    },
    setPayerOrder: (state, action) => {
      state.delivDate = action.payload.delivDate;
      state.rolGb = action.payload.rolGb;
      state.qmNo = action.payload.qmNo;
      state.usedCpcty = action.payload.usedCpcty;
      state.baseAmt = action.payload.baseAmt;
      state.usedAmt = action.payload.usedAmt;
      state.vatAmt = action.payload.vatAmt;
      state.addedAmt = action.payload.addedAmt;
      state.notiAmt = action.payload.notiAmt;
      state.npyAmt = action.payload.npyAmt;
      state.dmdAmt = action.payload.dmdAmt;
      state.dmdYm = action.payload.dmdYm;
    },
    setOrdNo: (state, action) => {
      state.ordNo = action.payload;
    },
    setSELF_MVIN_03List: (state, action) => {
      state.self_mvin_03_list = action.payload;
    },
    setSELF_MVOT_03List: (state, action) => {
      state.self_mvot_03_list = action.payload;
    },
    setLockYn: (state, action) => {
      state.lockYn = action.payload;
    },
    setIsReCal: (state, action) => {
      state.isReCal = action.payload;
    },
    setIsClickedBack: (state, action) => {
      state.isClickedBack = action.payload;
    },
  },
});

export const {
  setPayerNo,
  setInitalPayer,
  setIplNo,
  setMvio_07IplNo,
  setCheckMvin,
  setAgree,
  setAddress,
  setENotiTyCd,
  setPayTyCd,
  setPayerOrder,
  setOrdNo,
  setSELF_MVIN_03List,
  setSELF_MVOT_03List,
  setLockYn,
  setIsReCal,
  setIsClickedBack,
} = payerNoSlice.actions;

export const selectPayerNo = (state) => ({
  payerId: state.payerNoInfo.payerId,
  custNo: state.payerNoInfo.custNo,
  iplNo: state.payerNoInfo.iplNo,
  name: state.payerNoInfo.name,
  address: state.payerNoInfo.address,
  roadAddress: state.payerNoInfo.roadAddress,
  custSign: state.payerNoInfo.custSign,
  delivDate: state.payerNoInfo.delivDate,
  rolGb: state.payerNoInfo.rolGb,
  qmNo: state.payerNoInfo.qmNo,
  warningYn: state.payerNoInfo.warningYn,
  discntYn: state.payerNoInfo.discntYn,
  eNotiTyCd: state.payerNoInfo.eNotiTyCd,
  dmdAmt: state.payerNoInfo.dmdAmt,
  payTyCd: state.payerNoInfo.payTyCd,
  usedCpcty: state.payerNoInfo.usedCpcty,
  baseAmt: state.payerNoInfo.baseAmt,
  usedAmt: state.payerNoInfo.usedAmt,
  vatAmt: state.payerNoInfo.vatAmt,
  addedAmt: state.payerNoInfo.addedAmt,
  notiAmt: state.payerNoInfo.notiAmt,
  npyAmt: state.payerNoInfo.npyAmt,
  dmdYm: state.payerNoInfo.dmdYm,
  ordNo: state.payerNoInfo.ordNo,
  mvio_07_iplNo: state.payerNoInfo.mvio_07_iplNo,
  self_mvin_03_list: state.payerNoInfo.self_mvin_03_list,
  self_mvot_03_list: state.payerNoInfo.self_mvot_03_list,
  lockYn: state.payerNoInfo.lockYn,
  isReCal: state.payerNoInfo.isReCal,
  isClickedBack: state.payerNoInfo.isClickedBack,
});

export default payerNoSlice.reducer;
