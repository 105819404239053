import React, { useState, useEffect } from "react";
import AppHeader from "@components/common/AppHeader/AppHeader";
import { useForm } from "react-hook-form";
import { requestToken } from "../../lib/util/AuthUtil";
import { setLoggedInApp } from "@store/modules/userSlice";
import { useDispatch } from "react-redux";
import Cookies from "universal-cookie";
import { checkTime } from "@src/lib/util/Util";
import { BASE_URL, METHOD, comfnApiSvcCall } from "../../lib/common/comfn";
import { LoadingSpinner } from "@src/components/common/LoadingSpinner";
import { setNice } from "@src/store/modules/niceSlice";

const MBR_08 = ({ history, location }) => {
  useEffect(() => {
    // 현재시간이 22시~6시 사이면 이용불가 페이지
    if (!checkTime()) {
      history.push("/ImpossiblePage");
      return;
    }
    if (location.state === undefined) {
      history.push("/ErrorRedirect");
      return;
    }
    // }
  }, []);
  const beforePage =
    location.state === undefined
      ? null
      : location.state.page === ""
      ? ""
      : location.state.page;
  const dispatch = useDispatch();
  const cookies = new Cookies();
  // const [idRemember, setIdRemember] = useState(false);
  // const [inputId, setInputId] = useState("");
  // const userId = cookies.get("userId");

  const [resData, setResData] = useState("");
  const [encodeData, setEncodeData] = useState("");
  const [w, setW] = useState("");
  const [checkOpen, setCheckOpen] = useState("");
  const [idPw, setIdPw] = useState("");
  const [loading, setLoading] = useState(false);

  const convertDecode = (data) => {
    var str = data.replace(/%2B/g, "+");
    return str;
  };

  window.setData = function (data) {
    setResData(data);
  };

  useEffect(() => {
    if (encodeData === "") {
      return;
    } else {
      setW(
        window.open(
          "",
          "popupChk",
          "fullscreen=yes, menubar=no, toolbar=no, titlebar=yes, scrollbar=no"
        )
      );
    }
  }, [encodeData]);

  useEffect(() => {
    if (w !== "") {
      document.form_chk.target = "popupChk";
      document.form_chk.action =
        "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
      document.form_chk.submit();
    }
  }, [w]);

  useEffect(() => {
    if (resData === "") {
      return;
    } else {
      w.close();
      setCheckOpen("ok");
    }
  }, [resData]);

  useEffect(() => {
    if (checkOpen === "ok") {
      //console.log(resData);
      if (resData !== "") {
        if (resData.errorCode !== "") {
          // 본인인증 실패시
          alert("본인 인증을 실패했습니다. 다시 시도해 주세요.");
        } else {
          fnPg37({ ci: convertDecode(resData.ci) });
        }
      }
    }
  });
  const fnPg27 = () => {
    window.scrollTo(0, 0);
    const option = {
      serviceId: "fnPg27",
      baseUrl: BASE_URL.COMMON,
      url: "/nice/pg27",
      method: METHOD.POST,
      callback: fnCallback,
    };
    comfnApiSvcCall(option);
  };

  const fnPg37 = (data) => {
    const option = {
      serviceId: "fnPg37",
      baseUrl: BASE_URL.COMMON,
      url: "/mbr/pg37",
      method: METHOD.POST,
      data: data,
      callback: fnCallback,
    };
    setLoading(true);
    comfnApiSvcCall(option);
  };

  useEffect(() => {
    document.title = "삼천리 | 로그인";
    // if (userId !== "") {
    //   setIdRemember(true);
    //   setInputId(cookies.get("userId"));
    //   //console.log(inputId);
    // }
  }, []);
  //console.log(inputId);
  //console.log(idRemember);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [errorMessage, setErrorMessage] = useState("");

  const onSubmit = ({ id, password, rememberMe, saveId }) => {
    // console.log(
    //   `form data -> id : ${id}, password : ${password}, rememberMe : ${rememberMe}, saveId : ${saveId}`
    // );

    const prop = {
      id: id,
      password: password,
      rememberMe: rememberMe,
      // saveId: saveId,
      callback: callbackLogin,
    };
    //console.log(prop);
    setLoading(true);

    requestToken(prop);
  };

  const callbackLogin = ({ status, data, message }) => {
    setLoading(false);
    if (status === 200) {
      // 스토어에 토큰 저장
      // dispatch(함수명(data));
      dispatch(setLoggedInApp(data));
      if (data.dormYn === "N") {
        // history.push('/로그인 이후 페이지');
        beforePage === ""
          ? history.push("/VrscMvot/APP_CM_02")
          : beforePage.toLowerCase().indexOf("mbr_14") > -1
          ? history.push({
              pathname: "/VrscMvot/" + location.state.page,
              state: data,
            })
          : history.push("/VrscMvot/" + location.state.page);
      } else {
        history.push("/VrscMvot/MBR_17");
      }
      //console.log(data);
    } else {
      alert("아이디 또는 비밀번호가 일치하지 않습니다. 다시 확인해 주세요");
    }
  };

  const fnCallback = (res) => {
    setLoading(false);
    if (res.data.rtnCode === "-999") {
      return;
    }
    //console.log(res);
    if (res.data.rtnCode !== "0") {
      //console.log("err");
      if (res.serviceId === "fnPg27") {
        setEncodeData(res.data.encData);
      }
    } else if (res.serviceId === "fnPg37") {
      if (idPw === "id") {
        if (res.data.usrId !== null) {
          // 아이디 찾기 성공
          // history.push({
          //   pathname: "/VrscMvot/MBR_10",
          //   state: { data: res.data },
          // });
          dispatch(
            setNice({
              createdDt: res.data.createdDt,
              usrId: res.data.usrId,
            })
          );
          history.push("/VrscMvot/MBR_10");
        } else {
          // 아이디 없는 경우
          history.push("/VrscMvot/MBR_22");
        }
      } else {
        if (res.data.usrId !== null) {
          // 본인인증 성공 후 가입된 아이디가 있는 경우
          // history.push({
          //   pathname: "/VrscMvot/MBR_12",
          //   state: { data: res.data },
          // });
          dispatch(setNice(res.data));
          history.push("/VrscMvot/MBR_12");
        } else {
          // 본인인증 성공했으나 해당 사용자가 없는 경우
          history.push("/VrscMvot/MBR_23");
        }
      }
    } else if (res.serviceId === "fnPg27") {
      setEncodeData(res.data.encData);
    }
  };

  return (
    <>
      <AppHeader title="로그인" />

      <div id="container" className="container info">
        <div className="agree_txt_box">
          <h2 className="head_txt">간편하게!</h2>
          <h2 className="head_txt">직접</h2>
          <h2 className="head_txt">셀프 전출하세요.</h2>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            className={`form-group mtmb${errors.id ? " discord" : ""}`} // discord 클래스를 갖고있으면 불일치 표시됨
          >
            <input
              type="text"
              className="form-control h_big"
              id="id"
              placeholder="아이디를 입력해 주세요"
              // defaultValue={userId}
              {...register("id", { required: true })}
            />
          </div>
          {errors.id && (
            <small className="form-text text_alert bold mb-3">
              아이디를 확인해 주세요.
            </small>
          )}
          <div
            className={`form-group mb-3${errors.password ? " discord" : ""}`}
          >
            <input
              type="password"
              className="form-control h_big"
              id="password"
              placeholder="비밀번호를 입력해 주세요"
              autoComplete="off"
              {...register("password", { required: true })}
            />
          </div>
          {errors.password && (
            <small className="form-text text_alert bold mb-3">
              비밀번호를 확인해 주세요.
            </small>
          )}
          {errorMessage && (
            <small className="form-text text_alert bold mb-3">
              아이디 또는 비밀번호가 일치하지 않습니다.
            </small>
          )}
          <div className="horizon_checkbox_area">
            <div className="custom-control custom-radio left">
              <input
                type="checkbox"
                id="rememberMe"
                className="custom-control-input"
                {...register("rememberMe")}
              />
              <label className="custom-control-label" htmlFor="rememberMe">
                <div className="info01 login">자동로그인</div>
              </label>
            </div>
            {/* <div className="custom-control custom-radio right">
              <input
                type="checkbox"
                id="saveId"
                className="custom-control-input"
                defaultChecked={idRemember}
                onChange={() => {
                  setIdRemember(!idRemember);
                }}
                {...register("saveId")}
              />
              <label className="custom-control-label" htmlFor="saveId">
                <div className="info01 login">아이디 저장</div>
              </label>
            </div> */}
          </div>
          <button
            type="submit"
            className="btn btn_blue btn_big"
            disabled={loading}
          >
            {loading ? <LoadingSpinner /> : "로그인"}
          </button>
        </form>
        <div className="find_box">
          <span>
            계정을 잊으셨나요?{" "}
            <span
              className="find_id find"
              onClick={() => {
                fnPg27();
                setIdPw("id");
              }}
            >
              ID찾기{" "}
            </span>
            또는{" "}
            <span
              className="find_password find"
              onClick={() => fnPg27()}
              disabled={loading}
            >
              비밀번호 찾기
            </span>
          </span>
        </div>
        <div className="signup_box">
          <span>
            아직 회원이 아닌가요?{" "}
            <span
              className="signup"
              onClick={() => history.push("/VrscMvot/MBR_01")}
              disabled={loading}
            >
              회원가입
            </span>{" "}
          </span>
        </div>
        <form name="form_chk" method="post">
          <input type="hidden" name="m" value="checkplusSerivce" />
          <input type="hidden" name="recvMethodType" value="get"></input>
          <input type="hidden" name="EncodeData" value={encodeData} />
        </form>
      </div>
    </>
  );
};

export default MBR_08;
