import React, { useEffect, useState } from "react";
import { Header } from "@components/common/Header";
import AppHeader from "@components/common/AppHeader/AppHeader";
import { useHistory } from "react-router-dom";
import { selectType, setInitalType } from "@store/modules/typeSlice";
import { selectUserInfo, setInitalUser } from "@store/modules/userSlice";
import { setInitalMeter } from "@store/modules/meterSlice";
import { setInitalPayer } from "@store/modules/payerNoSlice";
import { useSelector, useDispatch } from "react-redux";

const Error404 = () => {
  useEffect(() => {
    document.title = "삼천리 | 오류 안내";
  }, []);

  //console.log("404");
  const allType = useSelector(selectType);
  const type = allType.type;
  const vrsc = allType.vrsc;
  const history = useHistory();
  const userInfo = useSelector(selectUserInfo);
  const token = userInfo.accessToken;
  const [realVrsc, setRealVrsc] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (vrsc) setRealVrsc(true);
    else if (navigator.userAgent.indexOf("wv") > -1) {
      setRealVrsc(true);
    } else if (
      navigator.userAgent.indexOf("iPhone") > -1 &&
      navigator.userAgent.indexOf("Safari") === -1
    ) {
      setRealVrsc(true);
    }
  }, []);

  const reset = () => {
    dispatch(setInitalPayer());
    dispatch(setInitalMeter());
    dispatch(setInitalUser());
    dispatch(setInitalType());
  };

  const onClickButton = () => {
    if (token !== undefined && token !== "" && token !== null) {
      token && history.goBack();
    } else {
      //셀프 전출일 때
      if (type === "out" && !vrsc) {
        history.push("/SelfMvot");
      }
      //3자 전출일 때
      else if (type === "out" && vrsc) {
        history.push("/VrscMvot");
      }
      //셀프 전입일 때
      else if (type === "in" && !vrsc) {
        history.push("/SelfMvin");
      }
    }
  };
  return (
    <>
      {realVrsc ? (
        <AppHeader title="오류 안내" />
      ) : (
        <Header title="오류 안내" />
      )}

      <div id="container" className="container">
        <div className="section_error">
          <div className="icon_area" />
          <p className="txt">페이지를 찾을 수 없습니다.</p>

          <p className="error_txt center">요청하신 페이지가 제거 되었거나,</p>
          <p className="error_txt center">이름이 변경되었거나,</p>
          <p className="error_txt center">일시적으로 사용이 중단되었습니다.</p>
        </div>
      </div>

      {(type === "out" && vrsc) ||
      (type === "out" && !vrsc) ||
      (type === "in" && !vrsc) ? (
        <button
          type="button"
          className="btn btn_blue btn_big btn_bottom mt-5"
          onClick={onClickButton}
        >
          이전 페이지로 가기
        </button>
      ) : (
        ""
      )}
    </>
  );
};

export default Error404;
