import React, { useEffect } from "react";
import AppHeader from "@components/common/AppHeader/AppHeader";
import { checkTime } from "@src/lib/util/Util";
import NiceButton from "@components/common/NiceButton/NiceButton";
import { useSelector } from "react-redux";
import { selectNiceInfo } from "@src/store/modules/niceSlice";

const MBR_10 = ({ history }) => {
  const niceInfo = useSelector(selectNiceInfo);
  useEffect(() => {
    document.title = "삼천리 | 아이디 찾기 결과";
    // 현재시간이 22시~6시 사이면 이용불가 페이지
    if (!checkTime()) {
      history.push("/ImpossiblePage");
      return;
    }
    if (niceInfo.usrId === "" || niceInfo.createdDt === "") {
      history.push({
        pathname: "/VrscMvot/MBR_08",
        state: { page: "VRSC_MVOT_01" },
      });
      return;
    }
  }, []);
  return (
    <>
      <AppHeader title="아이디 찾기" />

      <div id="container" className="container">
        <p className="findid_txt">고객님의 아이디를 찾았습니다.</p>
        <form>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              id="findid"
              value={niceInfo.usrId === undefined ? null : niceInfo.usrId}
              disabled
            />
          </div>
        </form>
      </div>

      <div className="row btn-area find btn_bottom mt-4">
        <div className="col-6 btn-lt">
          {/* <button
            type="button"
            className="btn btn_white"
            onClick={() => history.push("/VrscMvot/MBR_11")}
          >
            비밀번호 찾기
          </button> */}
          <NiceButton
            type="button"
            cName="btn btn_white"
            name="비밀번호 찾기"
            agree={false}
          />
        </div>
        <div className="col-6 btn-rt">
          <button
            type="button"
            className="btn btn_blue"
            onClick={() =>
              history.push({
                pathname: "/VrscMvot/MBR_08",
                state: { page: "" },
              })
            }
          >
            로그인
          </button>
        </div>
      </div>
    </>
  );
};

export default MBR_10;
