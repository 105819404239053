import jwt_decode from "jwt-decode";
import { requestTokenByRefresh } from "@src/lib/util/AuthUtil";
import Cookies from "universal-cookie";

export function isNull(val) {
  if (!val) return TextTrackCueList;
  else if (val === null) return true;
  else if (val === undefined) return true;
  else if (val.replace(/(\s*)/g, "") === "") return true;
  else return false;
}

export function inputHyphenMobNum(obj) {
  let phone = "";
  phone += obj.substr(0, 3);
  phone += "-";
  phone += obj.substr(3, 4);
  phone += "-";
  phone += obj.substr(7);
  return phone;
}

export function todayDate() {
  let today = new Date();
  const year = today.getFullYear();
  const month =
    today.getMonth() + 1 < 10
      ? "0" + (today.getMonth() + 1)
      : today.getMonth() + 1;
  const day = today.getDate() < 10 ? "0" + today.getDate() : today.getDate();
  const result = year + "." + month + "." + day;
  return result;
}

export function checkRefreshCookie() {
  const cookies = new Cookies();
  let token = cookies.get("refresh_token");
  if (token === "" || token === undefined) {
    return false;
  }
  // token이 있으면 유효성 체크
  var decodedToken = jwt_decode(token);
  const now = new Date();
  if (decodedToken.client_id !== "samchully") {
    return false;
  }
  if (decodedToken.exp * 1000 < now.getTime()) {
    return false;
  } else {
    return true;
  }
}

export function checkJwt(token) {
  // token이 없으면 false 반환
  if (token === "" || token === undefined || token === null) {
    return false;
  }
  // token이 있으면 유효성 체크
  var decodedToken = jwt_decode(token);
  // console.log(decodedToken);
  const now = new Date();
  // console.log(now.getTime());
  // console.log(decodedToken.client_id);
  if (decodedToken.client_id !== "samchully") {
    return false;
  }
  if (decodedToken.exp * 1000 < now.getTime()) {
    //console.log("Token expired");
    return false;
  } else {
    //console.log("아직 유효합니다.");
    return true;
  }
}

export function checkTime() {
  const now = new Date();
  // 22시에서 6시 사이라면 false 반환
  if (now.getHours() >= 22 || now.getHours() < 6) {
    return false;
  } else {
    return true;
  }
}

export const maxLengthCheck = (e) => {
  // console.log(e);
  // console.log(e.target.value.length, e.target.maxLength);
  if (e.target.value.length > e.target.maxLength) {
    e.target.value = e.target.value.slice(0, e.target.maxLength);
  }
};

export const isBirthdayValid = (dateStr) => {
  const year = Number(dateStr.substr(0, 2));
  const month = Number(dateStr.substr(2, 2));
  const day = Number(dateStr.substr(4, 2));
  // console.log(year, month, day);

  if (month < 1 || month > 12) {
    return false;
  } else if (day < 1 || day > 31) {
    return false;
  } else if (
    (month === 4 || month === 6 || month === 9 || month === 11) &&
    day === 31
  ) {
    return false;
  } else if (month === 2) {
    var isleap = year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);
    if (day > 29 || (day === 29 && !isleap)) {
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
};

export const parseJwt = (token) => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const checkApp = () => {
  if (navigator.userAgent.indexOf("wv") <= -1) {
    return false;
  } else {
    return true;
  }
};
