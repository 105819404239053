import React, { useEffect, useState } from "react";
import {
  selectType,
  setType,
  setKakao,
  setTypeInapp,
} from "@store/modules/typeSlice";
import {
  selectPayerNo,
  setInitalPayer,
  setPayerNo,
} from "@store/modules/payerNoSlice";
import {
  selectUserInfo,
  setLoggedIn,
  setAccessToken,
  setInitalUser,
} from "@store/modules/userSlice";
import {
  selectMeterInfo,
  setInitalMeter,
  setOutMeter,
} from "@store/modules/meterSlice";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL, comfnApiSvcCall, METHOD } from "@src/lib/common/comfn";
import { requestWebToken } from "../../lib/util/AuthUtil";
import { checkTime } from "@src/lib/util/Util";
import { LoadingSpinner } from "@src/components/common/LoadingSpinner";
import { setInitalMVOT_02 } from "@src/store/modules/mvot02Slice";

const SELF_MVOT_01 = ({ history, location }) => {
  useEffect(() => {
    document.title = "삼천리 | 셀프전출";
    dispatch(setInitalPayer());
    dispatch(setInitalMeter());
    dispatch(setInitalUser());
    dispatch(setInitalMVOT_02());
    // 현재시간이 22시~6시 사이면 이용불가 페이지
    if (!checkTime()) {
      history.push("/ImpossiblePage");
      return;
    }
    // window.history.pushState(null, document.title, window.location.href);
    // window.addEventListener("popstate", () => {
    //   window.history.pushState(null, document.title, window.location.href);
    // });
  }, []);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  //토큰 생성 요청
  const fnGetToken = (out) => {
    let data = {
      req_ty: "MO",
      payp_no: out.paypNo,
      mob_no: out.mobNo,
      callback: callbackToken,
    };
    //console.log(data);
    requestWebToken(data);
  };

  //pg01 호출
  const fnGetInfo = (paypNo) => {
    let data = { reqTy: "MO", paypNo: paypNo };
    //console.log(data);

    const option = {
      serviceId: "pg01",
      baseUrl: BASE_URL.COMMON,
      url: "/mvio/pg01",
      method: METHOD.POST,
      data: data,
      callback: fnCallback,
    };
    setLoading(true);
    comfnApiSvcCall(option);
  };

  const fnCallback = (res) => {
    setLoading(false);
    if (res.data.hasOwnProperty("rtnCode")) {
      if (res.data.rtnCode === "-999") {
        return;
      }
    }
    if (res.errorCode < 0) {
      if (res.data.rtnCode === 404) history.push("/Error404");
      else history.push("/Error500");
    } else if (res.serviceId === "pg01") {
      //console.log("result");
      //console.log(res);
      if (res.data.list.length === 1) {
        const result = res.data.list[0];
        dispatch(
          //생년월일, 휴대폰 번호
          //납부자 번호, 고객번호, 이름, 생년월일, 휴대폰 번호, 소비처번호, 전입지 주소, 전입지주소 도로명
          setPayerNo({
            payerId: result.paypNo, //납부자 번호
            custNo: result.custno, //납부자 명
            iplNo: result.iplNo, //소비처 번호
            name: result.name, //이름
            address: result.addr1, //지번
            roadAddress: result.addr2, //도로명
          })
        );

        dispatch(
          setLoggedIn({
            birth: result.brthYm,
            mobNo: result.mobNo,
          })
        );
        fnGetToken(result); //토큰 생성, 서버 연동시 주석 해제
      } else {
        alert("사용자를 찾을 수 없습니다.");
        if (
          navigator.userAgent.match(
            /inapp|NAVER|KAKAOTALK|Snapchat|Line|WirtschaftsWoche|Thunderbird|Instagram|everytimeApp|WhatsApp|Electron|wadiz|AliApp|zumapp|iPhone(.*)Whale|Android(.*)Whale|kakaostory|band|twitter|DaumApps|DaumDevice\/mobile|FB_IAB|FB4A|FBAN|FBIOS|FBSS|SamsungBrowser\/[^1]/i
          )
        ) {
          if (navigator.userAgent.includes("iPhone")) {
            // 카카오 인앱 아이폰의 경우 닫기
            window.location.href = "kakaoweb://closeBrowser";
          }
        } else {
          window.open("about:blank", "_self").close();
        }
      }
    }
  };

  const callbackToken = ({ status, data, message }) => {
    if (status === 200) {
      dispatch(setAccessToken(data.access_token));
      history.push("/SelfMvot/SELF_MVOT_02");
      //console.log(data.access_token);
      //console.log("callback");
    } else {
      alert("실패하였습니다.");
    }
  };

  useEffect(() => {
    if (
      navigator.userAgent.match(
        /inapp|NAVER|KAKAOTALK|Snapchat|Line|WirtschaftsWoche|Thunderbird|Instagram|everytimeApp|WhatsApp|Electron|wadiz|AliApp|zumapp|iPhone(.*)Whale|Android(.*)Whale|kakaostory|band|twitter|DaumApps|DaumDevice\/mobile|FB_IAB|FB4A|FBAN|FBIOS|FBSS|SamsungBrowser\/[^1]/i
      )
    ) {
      if (navigator.userAgent.match(/iPhone|iPad/i)) {
        const query = location.search.split("=")[0];
        const payerNo = location.search.split("=")[1];
        dispatch(setTypeInapp({ type: "out", vrsc: false, kakao: "inapp" }));
        //납부자 번호가 있는 경우
        if (query === "?paypNo") {
          fnGetInfo(payerNo);
        }
        //납부자 번호가 없는 경우
        else {
          history.push("/SelfMvot/MVIO_01");
        }
      } else {
        if (location.search.split("=")[0] === "?paypNo") {
          window.location.href = "kakaotalk://inappbrowser/close";
          window.location.href =
            "intent://" +
            window.location.href.substr(8) +
            "&agent=kakao" +
            "#Intent;scheme=https;package=com.android.chrome;end";
        } else {
          window.location.href = "kakaotalk://inappbrowser/close";
          window.location.href =
            "intent://" +
            window.location.href.substr(8) +
            "?agent=kakao" +
            "#Intent;scheme=https;package=com.android.chrome;end";
        }
      }
    } else {
      const query = location.search.split("=")[0];
      const payerNo = location.search.split("=")[1]
        ? location.search.split("=")[1].split("&")[0]
        : location.search.split("=")[1];
      //mvType out으로 set
      if (window.location.href.includes("agent")) {
        dispatch(setTypeInapp({ type: "out", vrsc: false, kakao: "inapp" }));
      } else {
        dispatch(setType({ type: "out", vrsc: false }));
      }

      //납부자 번호가 있는 경우
      if (query === "?paypNo") {
        fnGetInfo(payerNo);
      }
      //납부자 번호가 없는 경우
      else {
        history.push("/SelfMvot/MVIO_01");
      }
    }
  }, []);
  return <div></div>;
};

export default SELF_MVOT_01;
