import React, { useEffect } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "./css/victor.css";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
  BrowserRouter,
} from "react-router-dom";
import Error404 from "./containers/pages/Error404";
import Error500 from "./containers/pages/Error500";
import ErrorRedirect from "./containers/pages/ErrorRedirect";
import AccessError from "./containers/pages/AccessError";
import ImpossiblePage from "./containers/pages/ImpossiblePage";
import AccessTimeCheck from "./containers/pages/AccessTimeCheck";

import SELF_MVOT_03 from "./containers/SelfMvot/SELF_MVOT_03";
import MVOT_07 from "./containers/Common/MVOT_07";
import MVIO_04 from "./containers/Common/MVIO_04";
import MVIO_06 from "./containers/Common/MVIO_06";
import MVIO_01 from "./containers/Common/MVIO_01";
import MVIO_07 from "./containers/Common/MVIO_07";
import SELF_MVOT_01 from "./containers/SelfMvot/SELF_MVOT_01";
import SELF_MVOT_02 from "./containers/SelfMvot/SELF_MVOT_02";
import MVOT_01 from "./containers/SelfMvot/MVOT_01";
import MVOT_02 from "./containers/SelfMvot/MVOT_02";
import MVOT_04 from "./containers/SelfMvot/MVOT_04";

import MBR_18 from "./containers/VrscMvot/MBR_18";
import APP_CM_02 from "./containers/VrscMvot/APP_CM_02";
import MBR_01 from "./containers/VrscMvot/MBR_01";
import MBR_06 from "./containers/VrscMvot/MBR_06";
import MBR_07 from "./containers/VrscMvot/MBR_07";
import MBR_08 from "./containers/VrscMvot/MBR_08";
import VRSC_MVOT_01 from "./containers/VrscMvot/VRSC_MVOT_01";
import MBR_17 from "./containers/VrscMvot/MBR_17";
import MBR_10 from "./containers/VrscMvot/MBR_10";
import MBR_12 from "./containers/VrscMvot/MBR_12";
import MBR_14 from "./containers/VrscMvot/MBR_14";
import MBR_19 from "./containers/VrscMvot/MBR_19";
import MBR_20 from "./containers/VrscMvot/MBR_20";
import MBR_21 from "./containers/VrscMvot/MBR_21";
import MBR_22 from "./containers/VrscMvot/MBR_22";
import MBR_23 from "./containers/VrscMvot/MBR_23";

import SELF_MVIN_02 from "./containers/SelfMvin/SELF_MVIN_02";
import SELF_MVIN_03 from "./containers/SelfMvin/SELF_MVIN_03";
import SELF_MVIN_04 from "./containers/SelfMvin/SELF_MVIN_04";
import SELF_MVIN_05 from "./containers/SelfMvin/SELF_MVIN_05";
import SELF_MVIN_07 from "./containers/SelfMvin/SELF_MVIN_07";
import SELF_MVIN_08 from "./containers/SelfMvin/SELF_MVIN_08";
import SELF_MVIN_09 from "./containers/SelfMvin/SELF_MVIN_09";
import AnalyticsComponent from "./components/common/AnalyticsComponent/AnalyticsComponent";
import MVOT_10 from "./containers/SelfMvot/MVOT_10";
import { ScrollToTop } from "@components/common/ScrollToTop";
import MVOT_03 from "./containers/SelfMvot/MVOT_03";
import MVOT_11 from "./containers/SelfMvot/MVOT_11";
import MVOT_12 from "./containers/SelfMvot/MVOT_12";
import FindAddress from "./containers/VrscMvot/FindAddress";
import { useDispatch } from "react-redux";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    console.log("-");
    //모바일 웹 / 웹 구분
    const filter = "win16|win32|win64|mac";
    if (navigator.platform) {
      if (filter.indexOf(navigator.platform.toLowerCase()) >= 0) {
        if (navigator.userAgent.indexOf("wv") <= -1) {
          if (
            window.location.href !==
            process.env.REACT_APP_API_COMMON_URL + "/AccessError"
          ) {
            window.location.href =
              process.env.REACT_APP_API_COMMON_URL + "/AccessError";
          }
        }
      }
    }

    //console.log(window.location.href);
    if (
      window.location.href.toLowerCase() ===
      process.env.REACT_APP_API_COMMON_URL + "/selfmvin"
    ) {
      if (
        navigator.userAgent.match(
          /inapp|NAVER|KAKAOTALK|Snapchat|Line|WirtschaftsWoche|Thunderbird|Instagram|everytimeApp|WhatsApp|Electron|wadiz|AliApp|zumapp|iPhone(.*)Whale|Android(.*)Whale|kakaostory|band|twitter|DaumApps|DaumDevice\/mobile|FB_IAB|FB4A|FBAN|FBIOS|FBSS|SamsungBrowser\/[^1]/i
        )
      ) {
        if (navigator.userAgent.includes("iPhone")) {
          //console.log("iphone in kakao");
        } else {
          window.location.href = "kakaotalk://inappbrowser/close";
          window.location.href =
            "intent://" +
            window.location.href.substr(8) +
            "?agent=kakao" +
            "#Intent;scheme=https;package=com.android.chrome;end";
        }
      }
    }
    // window.onload = function () {
    //   if (
    //     navigator.userAgent.match(
    //       /inapp|NAVER|KAKAOTALK|Snapchat|Line|WirtschaftsWoche|Thunderbird|Instagram|everytimeApp|WhatsApp|Electron|wadiz|AliApp|zumapp|iPhone(.*)Whale|Android(.*)Whale|kakaostory|band|twitter|DaumApps|DaumDevice\/mobile|FB_IAB|FB4A|FBAN|FBIOS|FBSS|SamsungBrowser\/[^1]/i
    //     )
    //   ) {
    //     if (navigator.userAgent.match(/iPhone|iPad/i)) {
    //       console.log("iphone in kakao");
    //       dispatch(setKakao());
    //     } else {
    //       window.location.href = "kakaotalk://inappbrowser/close";
    //       window.location.href =
    //         "intent://" +
    //         window.location.href.substr(8) +
    //         "?agent=kakao" +
    //         "#Intent;scheme=https;package=com.android.chrome;end";
    //     }
    //   }
    // };
  }, []);
  return (
    <BrowserRouter>
      <ScrollToTop />
      <AnalyticsComponent />
      <Switch>
        {/* <Route exact path="*" component={ImpossiblePage} /> */}
        <Route exact path="/Error500" component={Error500} />
        <Route exact path="/AccessError" component={AccessError} />
        <Route exact path="/AccessTimeCheck" component={AccessTimeCheck} />
        <Route exact path="/ErrorRedirect" component={ErrorRedirect} />
        <Route exact path="/ImpossiblePage" component={ImpossiblePage} />
        {/* @@@@@@@@@@@@@@@@@ 셀프 전입 @@@@@@@@@@@@@@@@@*/}
        {/* 본인 인증 */}
        <Route
          exact
          path={["/SelfMvin", "/SelfMvin/MVIO_01"]}
          component={MVIO_01}
        />
        {/* 기존 이력 확인*/}
        <Route exact path="/SelfMvin/SELF_MVIN_03" component={SELF_MVIN_03} />
        {/* 셀프 전입 안내*/}
        <Route exact path="/SelfMvin/SELF_MVIN_02" component={SELF_MVIN_02} />
        <Route exact path="/SelfMvin/MVOT_07" component={MVOT_07} />
        <Route exact path="/SelfMvin/MVIO_04" component={MVIO_04} />
        <Route exact path="/SelfMvin/MVIO_06" component={MVIO_06} />
        <Route exact path="/SelfMvin/MVIO_07" component={MVIO_07} />
        <Route exact path="/SelfMvin/SELF_MVIN_04" component={SELF_MVIN_04} />
        <Route exact path="/SelfMvin/SELF_MVIN_05" component={SELF_MVIN_05} />
        <Route exact path="/SelfMvin/SELF_MVIN_08" component={SELF_MVIN_08} />
        <Route exact path="/SelfMvin/SELF_MVIN_09" component={SELF_MVIN_09} />
        {/* @@@@@@@@@@@@@@@@@ 셀프 전출 @@@@@@@@@@@@@@@@@*/}
        <Route exact path="/SelfMvot/SELF_MVOT_03" component={SELF_MVOT_03} />
        <Route exact path="/SelfMvot/MVOT_07" component={MVOT_07} />
        <Route exact path="/SelfMvot/MVIO_04" component={MVIO_04} />
        <Route exact path="/SelfMvot/MVIO_06" component={MVIO_06} />
        <Route exact path="/SelfMvot/MVIO_07" component={MVIO_07} />
        <Route exact path="/SelfMvot/MVIO_01" component={MVIO_01} />
        <Route exact path="/SelfMvot" component={SELF_MVOT_01} />
        <Route exact path="/SelfMvot/SELF_MVOT_02" component={SELF_MVOT_02} />
        <Route exact path="/SelfMvot/MVOT_02" component={MVOT_02} />
        <Route exact path="/SelfMvot/MVOT_01" component={MVOT_01} />
        <Route exact path="/SelfMvot/MVOT_03" component={MVOT_03} />
        <Route exact path="/SelfMvot/MVOT_04" component={MVOT_04} />
        <Route exact path="/selfmvot/MVOT_10" component={MVOT_10} />
        <Route exact path="/selfmvot/MVOT_11" component={MVOT_11} />
        <Route exact path="/selfMvot/MVOT_12" component={MVOT_12} />
        {/* @@@@@@@@@@@@@@@@@ 제3자 전출 @@@@@@@@@@@@@@@@@*/}
        <Route
          exact
          path={["/VrscMvot", "/VrscMvot/APP_CM_02"]}
          component={APP_CM_02}
        />
        <Route exact path="/VrscMvot/MBR_18" component={MBR_18} />
        <Route exact path="/VrscMvot/MBR_19" component={MBR_19} />
        <Route exact path="/VrscMvot/MBR_20" component={MBR_20} />
        <Route exact path="/VrscMvot/MBR_21" component={MBR_21} />
        <Route exact path="/VrscMvot/MBR_22" component={MBR_22} />
        <Route exact path="/VrscMvot/MBR_23" component={MBR_23} />
        <Route exact path="/VrscMvot/MBR_01" component={MBR_01} />
        <Route exact path="/VrscMvot/MBR_06" component={MBR_06} />
        <Route exact path="/VrscMvot/MBR_07" component={MBR_07} />
        <Route exact path="/VrscMvot/MBR_08" component={MBR_08} />
        <Route exact path="/VrscMvot/VRSC_MVOT_01" component={VRSC_MVOT_01} />
        <Route exact path="/VrscMvot/VRSC_MVOT_03" component={SELF_MVOT_03} />
        <Route exact path="/VrscMvot/VRSC_MVOT_02" component={SELF_MVOT_02} />
        <Route exact path="/VrscMvot/MVOT_07" component={MVOT_07} />
        <Route exact path="/VrscMvot/MVIO_04" component={MVIO_04} />
        <Route exact path="/VrscMvot/MVIO_06" component={MVIO_06} />
        <Route exact path="/VrscMvot/MVOT_01" component={MVOT_01} />
        <Route exact path="/VrscMvot/MVOT_02" component={MVOT_02} />
        <Route exact path="/VrscMvot/MVIO_07" component={MVIO_07} />
        <Route exact path="/VrscMvot/MVOT_04" component={MVOT_04} />
        <Route exact path="/VrscMvot/MBR_17" component={MBR_17} />
        <Route exact path="/VrscMvot/MBR_10" component={MBR_10} />
        <Route exact path="/VrscMvot/MBR_12" component={MBR_12} />
        <Route exact path="/VrscMvot/MBR_14" component={MBR_14} />
        <Route exact path="/VrscMvot/MVOT_11" component={MVOT_11} />
        <Route exact path="/VrscMvot/MVOT_12" component={MVOT_12} />
        <Route exact path="/VrscMvot/FindAddress" component={FindAddress} />
        <Route component={Error404} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
