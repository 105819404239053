import React, { useState, useCallback, useEffect } from "react";
import examShot01 from "../../images/exam_shot_01.png";
import Cropper from "react-easy-crop";
import getCroppedImg from "@components/camera/cropImage";
import { selectType } from "@store/modules/typeSlice";
import { setMeterPicture } from "@store/modules/meterSlice";
import { useSelector, useDispatch } from "react-redux";
import {
  selectPayerNo,
  setIplNo,
  setMvio_07IplNo,
  setAddress,
  setIsClickedBack,
  setIsReCal,
} from "@store/modules/payerNoSlice";
import {
  BASE_URL,
  METHOD,
  comfnApiSvcMetImgCall,
  comfnApiSvcCall,
} from "../../lib/common/comfn";
import { selectUserInfo } from "@store/modules/userSlice";
import { setAiResult } from "@store/modules/aiResultSlice";
import { selectMeterInfo } from "@store/modules/meterSlice";
import {
  selectMvio04,
  setCamImg,
  setMeterImg,
} from "@store/modules/mvio04Slice";
import { checkTime, checkJwt, todayDate } from "@src/lib/util/Util";
import { Modal } from "react-bootstrap";
import { LoadingSpinner } from "@src/components/common/LoadingSpinner";

const MVIO_06 = ({ history }) => {
  //({ location, history }) => {
  const userInfo = useSelector(selectUserInfo);
  const token = userInfo.accessToken;
  const mvio04Info = useSelector(selectMvio04);

  useEffect(() => {
    document.title = "삼천리 | 촬영 사진 확인 및 보정";
    // 현재시간이 22시~6시 사이면 이용불가 페이지
    if (!checkTime()) {
      history.push("/ImpossiblePage");
      return;
    }

    //토큰이 있을 때
    if (token !== "") {
      //토큰이 유효하지 않을 때
      if (!checkJwt(token)) {
        history.push("/AccessTimeCheck");
        return;
      }
      //토큰이 유효할 때
      else {
        //리덕스 값 체크 (없으면 404)
        if (payerInfo.payerId === "" || payerInfo.payerId === undefined) {
          history.push("/Error404");
          return;
        }
      }
    } else {
      history.push("/Error404");
      return;
    }

    if (mvio04Info.img === undefined || mvio04Info.meterImg === undefined) {
      // 전 페이지에서 받아오는 정보가 없으면 ErrorRedirect
      history.push("/ErrorRedirect");
      return;
    }
    // window.history.pushState(null, document.title, window.location.href);
    // window.addEventListener("popstate", () => {
    //   window.history.pushState(null, document.title, window.location.href);
    // });
  }, []);

  const [img, setImg] = useState(
    mvio04Info.img
    // location.state === undefined ? null : location.state.img
  );
  const [loading, setLoading] = useState(false);
  const type = useSelector(selectType);
  const vrsc = type.vrsc;
  const [imgBase64, setImgBase64] = useState("");
  const [capImage, setCapImage] = useState("");
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const dispatch = useDispatch();
  const payerInfo = useSelector(selectPayerNo);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const meterInfo = useSelector(selectMeterInfo);

  useEffect(() => {
    if (capImage === "") {
      return;
    }
    setImg(capImage);
  }, [capImage]);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const checkmeter = (data) => {
    setLoading(true);
    fnPg15(data);
  };

  // const base64ToBlob = function (base64Data, contentType, sliceSize) {
  //   contentType = contentType || "";
  //   sliceSize = sliceSize || 512;

  //   var byteCharacters = atob(base64Data);
  //   var byteArrays = [];

  //   for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
  //     var slice = byteCharacters.slice(offset, offset + sliceSize);

  //     var byteNumbers = new Array(slice.length);
  //     for (var i = 0; i < slice.length; i++) {
  //       byteNumbers[i] = slice.charCodeAt(i);
  //     }

  //     var byteArray = new Uint8Array(byteNumbers);
  //     byteArrays.push(byteArray);
  //   }

  //   var blob = new Blob(byteArrays, { type: contentType });

  //   return blob;
  // };

  const fnPg15 = (data) => {
    // 기물번호 유효성 조회
    const option = {
      serviceId: "fnPg15",
      baseUrl: BASE_URL.COMMON,
      url: "/mvio/pg15",
      header: "accessToken",
      method: METHOD.POST,
      data: data,
      callback: fnCallback,
    };
    setLoading(true);
    comfnApiSvcCall(option);
  };

  const fnSendMeterImg = (formData) => {
    const option = {
      serviceId: "fnSendMeterImg",
      baseUrl: BASE_URL.COMMON,
      url: "/img/pg26",
      header: "accessToken",
      method: METHOD.POST,
      data: formData,
      callback: fnCallback,
    };
    setLoading(true);
    comfnApiSvcMetImgCall(option);
  };

  const fnSendImagePath = (pathData) => {
    //console.log(pathData);
    const option = {
      serviceId: "fnSendImagePath",
      baseUrl: BASE_URL.COMMON,
      url: "/mvio/pg07",
      header: "accessToken",
      method: METHOD.POST,
      data: pathData,
      callback: fnCallback,
    };
    setLoading(true);
    comfnApiSvcCall(option);
  };

  const fnCallback = (res) => {
    setLoading(false);
    if (res.data.hasOwnProperty("rtnCode")) {
      if (res.data.rtnCode === "-999") {
        return;
      }
    }
    //console.log(res);
    if (res.errorCode < 0) {
      if (res.serviceId === "fnPg15") {
        setShow2(true);
      }
    } else if (res.serviceId === "fnSendMeterImg") {
      if (res.data.returnMsg === "FAIL") {
        setShow(true);
      }
      if (res.data.returnMsg === "SUCCESS") {
        //console.log(res);
        checkmeter({
          reqTy: type.type === "out" ? "MO" : "MI",
          mtrNo: res.data.wareNum,
          msrNo: res.data.meterageNum,
          chkTy: "1",
        });
      }
      const resData = {
        meterNum: res.data.wareNum,
        Indicator: res.data.meterageNum,
        filePathMet: res.data.path,
      };
      dispatch(setMeterPicture(resData));
      dispatch(setAiResult(res.data));
      fnSendImagePath({
        reqTy: type.type === "out" ? "MO" : "MI",
        paypNo: payerInfo.payerId,
        msrNo: resData.Indicator,
        regionGroup: "",
        notiNo: "",
        addr1: type.type === "out" ? payerInfo.address : null,
        mtrNo: resData.meterNum,
        imgInfo: {
          numEditYn: "N",
          filePathNameGas: meterInfo.filePathGas,
          filePathName: res.data.path,
          success: res.data.returnMsg === "SUCCESS" ? "S" : "F",
          readMsrNo: res.data.meterageNum,
          readMtrNo: res.data.wareNum,
          tidnrLtX: res.data.wareNumX0,
          tidnrRtX: res.data.wareNumX1,
          tidnrLbX: res.data.wareNumX3,
          tidnrRbX: res.data.wareNumX2,
          valLtX: res.data.meterageNumX0,
          valRtX: res.data.meterageNumX1,
          valLbX: res.data.meterageNumX3,
          valRbX: res.data.meterageNumX2,
          tidnrLtY: res.data.wareNumY0,
          tidnrRtY: res.data.wareNumY1,
          tidnrLbY: res.data.wareNumY3,
          tidnrRbY: res.data.wareNumY2,
          valLtY: res.data.meterageNumY0,
          valRtY: res.data.meterageNumY1,
          valLbY: res.data.meterageNumY3,
          valRbY: res.data.meterageNumY2,
        },
      });
    } else if (res.serviceId === "fnPg15") {
      //로직 작성
      if (type.type === "in") {
        dispatch(setIplNo(res.data.iplNo));
      } else if (type.type === "out") {
        dispatch(setMvio_07IplNo(res.data.iplNo));
      }
      dispatch(setAddress({ addr1: res.data.addr1, addr2: res.data.addr2 }));
      dispatch(setIsClickedBack(false));
      dispatch(setIsReCal(false));
      //console.log(res.data);

      vrsc
        ? history.push("/VrscMvot/MVOT_01")
        : // history.push({
        //     pathname: "/VrscMvot/MVOT_01",
        //     state: { iplNo: res.data.iplNo },
        //   })
        type.type === "out"
        ? history.push("/SelfMvot/MVOT_01")
        : // history.push({
          //     pathname: "/SelfMvot/MVOT_01",
          //     state: { iplNo: res.data.iplNo },
          //   })
          history.push("/SelfMvin/SELF_MVIN_04");
      // history.push({
      //     pathname: "/SelfMvin/SELF_MVIN_04",
      //     state: { iplNo: res.data.iplNo },
      //   });
    }
  };

  const sendMeter = () => {
    //console.log("기본");
    const formData = new FormData();
    const date = todayDate();
    formData.append("upFile", mvio04Info.meterImg);
    // formData.append("upFile", location.state.meterImg);
    formData.append("userId", "CUSTOM");
    formData.append("acckey", "000000");
    formData.append(
      "path",
      "imgphoto/AS/AS06/CUSTOM/" +
        date.substr(0, 4) +
        date.substr(5, 2) +
        "/" +
        date.substr(8, 2) +
        "/"
    );
    const MIMO = type.type === "out" ? "MO" : "MI";
    formData.append(
      "imageFilename",
      "AS06_" +
        MIMO +
        "_" +
        date.substr(0, 4) +
        date.substr(5, 2) +
        date.substr(8, 2) +
        "_" +
        payerInfo.payerId +
        ".jpg"
    );
    fnSendMeterImg(formData);
  };

  // function blobToFile(blob, fileName) {
  //   blob.lastModifiedDate = new Date();
  //   blob.name = fileName;
  //   return blob;
  // }

  const useCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        img,
        croppedAreaPixels,
        rotation
      );
      const formData = new FormData();

      // 이미지 URL로부터 이미지 데이터 부분과 컨텐츠 유형 부분을 분리한다.
      // - block[0] = 'data:image/jpeg'
      // - block[1] = 'base64,/gj.........Tf/5z0L/2vs1lb4eGcnUco//Z'
      // var block = croppedImage.split(";");
      // 이미지의 컨텐츠 유형을 얻는다.
      // var contentType = block[0].split(":")[1];
      // 이미지의 순수 데이터를 얻는다.
      // var realData = block[1].split(",")[1];
      // 이미지의 순수 데이터를 Blob 유형으로 변환한다.
      // var blob = base64ToBlob(realData, contentType);
      //console.log(croppedImage);
      var file = new File([croppedImage], "meter");
      //console.log(file);

      const date = todayDate();
      formData.append("upFile", file);
      formData.append("userId", "CUSTOM");
      formData.append("acckey", "000000");
      formData.append(
        "path",
        "imgphoto/AS/AS06/CUSTOM/" +
          date.substr(0, 4) +
          date.substr(5, 2) +
          "/" +
          date.substr(8, 2) +
          "/"
      );
      const MIMO = type.type === "out" ? "MO" : "MI";
      formData.append(
        "imageFilename",
        "AS06_" +
          MIMO +
          "_" +
          date.substr(0, 4) +
          date.substr(5, 2) +
          date.substr(8, 2) +
          "_" +
          payerInfo.payerId +
          ".jpg"
      );
      fnSendMeterImg(formData);
      // saveFile(croppedImage);
    } catch (e) {
      //console.log(e);
    }
  }, [croppedAreaPixels, rotation]);

  // const saveFile = async (blob) => {
  //   const a = document.createElement("a");
  //   a.download = "hihi";
  //   a.href = blob;
  //   a.addEventListener("click", (e) => {
  //     setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
  //   });
  //   a.click();
  // };

  // const dataURLtoFile = (dataurl) => {
  //   const reqTy = type.type;
  //   const paryerId = payerInfo.paryerId;
  //   const date = todayDate();
  //   var arr = dataurl.split(","),
  //     mime = arr[0].match(/:(.*?);/)[1],
  //     bstr = atob(arr[1]),
  //     n = bstr.length,
  //     u8arr = new Uint8Array(n);
  //   const fileName = "AS06_" + reqTy + "_" + date + "_" + paryerId + ".jpg";
  //   while (n--) {
  //     u8arr[n] = bstr.charCodeAt(n);
  //   }

  //   return new File([u8arr], fileName, { type: mime });
  // };

  // function dataURItoBlob(dataURI) {
  //   // convert base64/URLEncoded data component to raw binary data held in a string
  //   var byteString;
  //   if (dataURI.split(",")[0].indexOf("base64") >= 0)
  //     byteString = atob(dataURI.split(",")[1]);
  //   else byteString = unescape(dataURI.split(",")[1]);

  //   // separate out the mime component
  //   var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  //   // write the bytes of the string to a typed array
  //   var ia = new Uint8Array(byteString.length);
  //   for (var i = 0; i < byteString.length; i++) {
  //     ia[i] = byteString.charCodeAt(i);
  //   }

  //   return new Blob([ia], { type: mimeString });
  // }

  function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setCapImage(reader.result);
      dispatch(setCamImg(reader.result));
    };
    reader.onerror = function (error) {
      //console.log("Error: ", error);
    };
  }

  const handleCamera = (e) => {
    show ? setShow(false) : setShow(false);
    show2 ? setShow2(false) : setShow2(false);
    setZoom(1);
    setCrop({ x: 0, y: 0 });
    let reader = new FileReader();
    dispatch(setMeterImg(e.target.files[0]));

    reader.onload = () => {
      const base64 = reader.result;
      if (base64) {
        setImgBase64(base64.toString());
      }
    };
    if (e.target.files[0]) {
      reader.readAsDataURL(e.target.files[0]);
      getBase64(e.target.files[0]);
    }
  };

  return (
    <>
      <div id="header">
        <div className="wrap_header app_main_toparea pb-0">
          <div className="blank" />
          <h1>촬영 사진 확인 및 보정</h1>
          <div
            className="go_close"
            onClick={() => {
              history.goBack();
            }}
          >
            <span>닫기</span>
          </div>
        </div>
      </div>

      <div id="container" className="container shot">
        <div className="wrap_body shot">
          <div className="photo_zone">
            <Cropper
              image={img}
              crop={crop}
              zoom={zoom}
              aspect={4 / 3}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              objectFit="horizontal-cover"
              showGrid={false}
            />
            <div className="guide_circle">
              <div className="l_angle" />
              <div className="r_angle" />
            </div>
          </div>
          <div className="txt_area">
            <p>계량기 번호와 지침이 잘 보이도록</p>
            <p>
              <span>위치 변경</span> 및 <span>확대&#47;축소</span>해서
              맞춰주세요.
            </p>
          </div>
          <div className="shot_exam">
            <img src={examShot01} alt="" />
            <p className="agree_txt">촬영한 사진을 사용하시겠습니까?</p>
            <div className="row btn-area">
              <div className="col-6 btn-lt">
                <label className="btn btn_blue 6" for="cameraFileInput">
                  재 촬영
                  <input
                    id="cameraFileInput"
                    type="file"
                    accept="image/*"
                    capture="environment"
                    onChange={handleCamera}
                    style={{ display: "none" }}
                  />
                </label>
              </div>
              <div className="col-6 btn-rt">
                {/* {crop.x === 0 && crop.y === 0 ? (
                  <button
                    onClick={sendMeter}
                    type="button"
                    className="btn btn_blue"
                    data-dismiss="modal"
                    disabled={loading}
                  >
                    {loading ? <LoadingSpinner /> : "사진 사용"}
                  </button>
                ) : ( */}
                <button
                  onClick={useCroppedImage}
                  type="button"
                  className="btn btn_blue"
                  data-dismiss="modal"
                  disabled={loading}
                >
                  {/* 사진 사용 */}
                  {loading ? <LoadingSpinner /> : "사진 사용"}
                </button>
                {/* )} */}
              </div>
            </div>
          </div>
        </div>

        <div className="guide_circle">
          <div className="l_angle" />
          <div className="r_angle" />
        </div>
      </div>

      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header>
          <Modal.Title>
            <h5 className="modal-title shot1">
              이미지 인식 실패 <br />
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: 0 }}>
          <div className="info_box">
            {/* 셀프 전입, 전입 조회 결과에 따른 error 를 표시해주면 됨 */}
            <>
              <p className="info">죄송합니다.</p>
              <p className="info">이미지 인식을 하지 못했습니다.</p>
            </>
          </div>
        </Modal.Body>
        <div className="row btn-choice">
          <div className="col-6 btn-lt">
            <label
              className="btn btn_cancel"
              style={{ marginBottom: "-1px" }}
              for="cameraFileInput"
            >
              <input
                id="cameraFileInput"
                type="file"
                accept="image/*"
                capture="environment"
                onChange={handleCamera}
                style={{ display: "none" }}
              />
              재 촬영
            </label>
          </div>
          <div className="col-6 btn-rt">
            <button
              type="button"
              className="btn btn_ok"
              onClick={() => {
                type.type === "in"
                  ? history.push("/SelfMvin/MVIO_07")
                  : vrsc
                  ? history.push("/VrscMvot/MVIO_07")
                  : history.push("/SelfMvot/MVIO_07");
              }}
            >
              직접입력
            </button>
          </div>
        </div>
      </Modal>
      <Modal show={show2} onHide={() => setShow2(false)} centered>
        <Modal.Header>
          <Modal.Title>
            <h5 className="modal-title shot1">
              존재하지 않는 계량기 번호 <br />
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: 0 }}>
          <div className="info_box">
            {/* 셀프 전입, 전입 조회 결과에 따른 error 를 표시해주면 됨 */}
            <>
              <p className="info">존재하지 않는 계량기로 인식됩니다.</p>
            </>
          </div>
        </Modal.Body>
        <div className="row btn-choice">
          <div className="col-6 btn-lt">
            <label
              className="btn btn_cancel"
              style={{ marginBottom: "-1px" }}
              for="cameraFileInput"
            >
              <input
                id="cameraFileInput"
                type="file"
                accept="image/*"
                capture="environment"
                onChange={handleCamera}
                style={{ display: "none" }}
              />
              재 촬영
            </label>
          </div>
          <div className="col-6 btn-rt">
            <button
              type="button"
              className="btn btn_ok"
              onClick={() => {
                type.type === "in"
                  ? history.push("/SelfMvin/MVIO_07")
                  : vrsc
                  ? history.push("/VrscMvot/MVIO_07")
                  : history.push("/SelfMvot/MVIO_07");
              }}
            >
              직접입력
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default MVIO_06;
